import {
  FETCH_GLOBAL_CONTENT_FAIL,
  FETCH_GLOBAL_CONTENT_SUCCESS,
  FETCH_GLOBAL_HEADER_FAIL,
  FETCH_GLOBAL_HEADER_SUCCESS,
} from './actions';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case FETCH_GLOBAL_CONTENT_SUCCESS:
      const { slug, fields } = action.result;

      return {
        ...state,
        [slug]: fields,
      };
    case FETCH_GLOBAL_HEADER_SUCCESS:
      return {
        ...state,
        headerPromoCategories: action.result.fields.tabs,
      };
    case FETCH_GLOBAL_HEADER_FAIL:
    case FETCH_GLOBAL_CONTENT_FAIL:
    default:
      return state;
  }
}
