import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  Click,
  TRACK,
  TrackPageView,
  useAnalyticsContext,
  View,
} from 'analytics';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import { selectIsSMSLoginEnabled } from 'featureFlags';
import { selectAlgoliaSearchParams } from 'store/modules/location';

import {
  DISPLAY_TEXT,
  EmailForm,
  MagicLinkSuccess,
  SMSVerificationForm,
} from '../components';
import { LOGIN_STEPS, loginPropTypes, useLogin } from '../Login';

import styles from './GTLogin.module.scss';

const GTLogin = ({
  magicLinkParams,
  onLoginSuccess,
  subTitleText = `Enter your email address to log in. If you don't have an account already, we'll create one for you.`,
  ctaText = 'Log In',
  dispatch,
  isSMSLoginEnabled,
}) => {
  const analytics = useAnalyticsContext();

  const {
    state,
    clearErrors,
    handleGenerateMagicLink,
    handleGenerateSMSCode,
    handleSMSCodeLogin,
    handleEmailLogin,
  } = useLogin({
    dispatch,
    magicLinkParams,
    sourcePageTypeCallback: Click.SOURCE_PAGE_TYPES.LOGIN,
    analytics,
    onLoginSuccess,
    isSMSLoginEnabled,
  });

  switch (state.step) {
    case LOGIN_STEPS.MAGIC_LINK_SUCCESS: {
      return (
        <div className={styles['magic-link-success']}>
          <MagicLinkSuccess email={state.email} />
        </div>
      );
    }
    case LOGIN_STEPS.SMS_CODE: {
      return (
        <>
          <ThemedCard
            title={`${DISPLAY_TEXT.SMS_CODE.title}${state.phone}.`}
            desc={DISPLAY_TEXT.SMS_CODE.desc}
            styleClass={styles['sms-container']}
          >
            <SMSVerificationForm
              email={state.email}
              hasInvalidCodeError={state.hasInvalidCodeError}
              isSubmitting={state.isSubmitting}
              clearErrors={clearErrors}
              handleGenerateSMSCode={handleGenerateSMSCode}
              handleSMSCodeLogin={handleSMSCodeLogin}
              handleGenerateMagicLink={handleGenerateMagicLink}
              nextAllowedTokenRequestTime={state.nextAllowedTokenRequestTime}
            />
          </ThemedCard>
        </>
      );
    }
    case LOGIN_STEPS.USER_EMAIL:
    default: {
      return (
        <>
          <span className={styles['login-title']}>Log In 👋</span>
          <div className={styles['login-subtitle']}>{subTitleText}</div>
          {state.hasError && (
            <p className={styles['error-message']}>
              {DISPLAY_TEXT.ERROR.title}
            </p>
          )}
          <EmailForm
            ctaText={ctaText}
            pageTypeCallback={View.PAGE_TYPES.LOGIN}
            isSubmitting={state.isSubmitting}
            handleEmailLogin={handleEmailLogin}
          />
        </>
      );
    }
  }
};
GTLogin.propTypes = {
  subTitleText: PropTypes.string,
  ctaText: PropTypes.string,
  onLoginSuccess: PropTypes.func,
  magicLinkParams: loginPropTypes.magicLinkParams,
  dispatch: PropTypes.func.isRequired,
  isSMSLoginEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const location = props.location;
  const searchParams = selectAlgoliaSearchParams(location.query);
  return {
    isSMSLoginEnabled: selectIsSMSLoginEnabled(state),
    magicLinkParams: {
      pathname: props.customPathname || location.pathname,
      search: Object.fromEntries(searchParams),
    },
  };
};

const getEventState = () => ({
  [TRACK.PAGE_TYPE]: View.PAGE_TYPES.LOGIN(),
});

export default withRouter(
  connect(mapStateToProps)(TrackPageView(getEventState)(GTLogin))
);
