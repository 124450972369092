import React from 'react';

import { CaretDownIcon } from 'icons';
import colorsConstants from 'styles/colors.constants';

import styles from './TicketsBlock.module.scss';

type Props = {
  seatRange?: string;
  seatCount: number;
  ticketQuantities: number[];
  handleSeatsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isGeneralAdmission: boolean;
};

/**
 * Until the CSS `field-sizing` property is more widely supported, we have very
 * limited options for styling the width of select elements based on text
 * content, since the current browser spec is to make the select input as wide
 * as the widest option label. Since the text shown for the selected option is
 * not a DOM element on its own, but is an intrinsic part of how the Browser
 * renders a select element, it cannot be targeted for measurement. This
 * function creates an HTML canvas element, measures the width of the selected
 * option text, and sets a CSS variable on the select element to adjust its
 * width accordingly.
 *
 * It's ugly, but it works.
 *
 * see: https://caniuse.com/?search=field-sizing
 */
function setSelectWidthFromSelectedOption(
  selectElement: HTMLSelectElement | null
) {
  if (!selectElement) return;

  const selectedOption = Object.values(selectElement.options).find(
    (o) => o.value === selectElement.value
  );

  const context = document.createElement('canvas').getContext('2d');

  if (selectedOption && context) {
    context.font = getComputedStyle(selectElement).font;
    const width = context.measureText(selectedOption.text).width;
    selectElement.style.setProperty('--select-width', width + 'px');
  }
}

export default function TicketsBlock({
  seatRange,
  seatCount,
  ticketQuantities,
  handleSeatsChange,
  isGeneralAdmission,
}: Props) {
  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectWidthFromSelectedOption(e.target);
    handleSeatsChange(e);
  }

  return (
    <div className={styles.tickets}>
      <div className={styles['select-group']}>
        <select
          ref={setSelectWidthFromSelectedOption}
          className={styles['select-input']}
          value={seatCount}
          onChange={handleChange}
        >
          {ticketQuantities.length > 0 &&
            ticketQuantities.map((quantity) => (
              <option value={quantity} key={quantity}>
                {quantity} {isGeneralAdmission ? 'Ticket' : 'Seat'}
                {quantity === 1 ? '' : 's Together'}
              </option>
            ))}
        </select>

        <div className={styles['select-input-arrow']}>
          <CaretDownIcon
            fill={colorsConstants.gametimeGreen}
            height="20"
            width="20"
          />
        </div>
      </div>
      {seatRange && (
        <span className={styles['seating-range']}>{seatRange}</span>
      )}
    </div>
  );
}
