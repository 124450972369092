import { ABAssignmentSelector } from 'store/modules/user/user.selectors';

import { experiments } from './experiments';

const {
  INSURANCE_WEB_PILOT,
  GT_TICKET_COVERAGE,
  PERCENTAGE_VS_DOLLARS,
  FLASH_ZONE_HARMONY_WEB,
  INCLUDES_FEES_COPY,
  GALLERY_VIEW_V2,
  PERFORMER_INLINE_PRICING_MWEB,
  NEW_MLB_INFO_COLLECTION,
  WEB_EXCLUSIVES_V1,
  MLB_MATCHUP_LOGOS,
  LOCALIZE,
  APP_REVIEWS,
  NO_NFL_AUTOZOOM_DESKTOP,
  MARKET_SHARE_COLLECTION_V2,
  BILLING_WEB_MVP,
  WEB_DETAILS_STACKED,
  MLB_TEAM_LOGOS_PERFORMER,
  WEB_EXCLUSIVES_V3,
} = experiments;

export const selectIsLocalizeExperiment = (state) =>
  ABAssignmentSelector(state, LOCALIZE.name) === LOCALIZE.variant;

export const selectIsInsuranceWebPilotExperiment = (state) =>
  ABAssignmentSelector(state, INSURANCE_WEB_PILOT.name) ===
  INSURANCE_WEB_PILOT.variant;

export const selectTicketCoverageForEveryFanExperiment = (state) => {
  return (
    ABAssignmentSelector(state, GT_TICKET_COVERAGE.name) ===
    GT_TICKET_COVERAGE.variant1
  );
};

export const selectTicketCoverageVerifiedTicketsExperiment = (state) => {
  return (
    ABAssignmentSelector(state, GT_TICKET_COVERAGE.name) ===
    GT_TICKET_COVERAGE.variant2
  );
};

export const selectTicketCoverageExperiment = (state) => {
  const isVariant1 = selectTicketCoverageForEveryFanExperiment(state);
  const isVariant2 = selectTicketCoverageVerifiedTicketsExperiment(state);

  if (isVariant1) {
    return GT_TICKET_COVERAGE.variant1;
  } else if (isVariant2) {
    return GT_TICKET_COVERAGE.variant2;
  }
  return GT_TICKET_COVERAGE.default_variant;
};

export const selectIsPercentageVsDollarsExperiment = (state) =>
  ABAssignmentSelector(state, PERCENTAGE_VS_DOLLARS.name) ===
  PERCENTAGE_VS_DOLLARS.variant;

/**
 * Experiment Details:
 * - Name: FLASH_ZONE_HARMONY_WEB
 * - Selector Name: selectIsDealsHarmonyExperiment
 * - Flag Name: isDealsHarmony
 *
 * Redux State:
 * - displayGroupCarouselHarmony: Contains the listings of the harmony carousel.
 *
 * Selectors:
 * - getCarouselHarmony: returns {listings, dealType}
 *
 * Listing Model:
 * - isDealLockDisabled: Disables the lock state for the carousel.
 *
 * Fixtures:
 * - listingsCarousel.fixture.ts
 */
export const selectIsDealsHarmonyExperiment = (state) => {
  return (
    ABAssignmentSelector(state, FLASH_ZONE_HARMONY_WEB.name) ===
    FLASH_ZONE_HARMONY_WEB.variant
  );
};

/**
 * Used in OmnibarControls and ListingCard
 */
export const selectIsIncludesFeesCopyExperiment = (state) => {
  return (
    ABAssignmentSelector(state, INCLUDES_FEES_COPY.name) ===
    INCLUDES_FEES_COPY.variant
  );
};

export const selectIsGalleryViewV2Experiment = (state) => {
  return (
    ABAssignmentSelector(state, GALLERY_VIEW_V2.name) ===
    GALLERY_VIEW_V2.variant
  );
};

export const selectIsPerformerInlinePricingMobileExperiment = (state) => {
  return (
    ABAssignmentSelector(state, PERFORMER_INLINE_PRICING_MWEB.name) ===
    PERFORMER_INLINE_PRICING_MWEB.variant
  );
};

export const selectIsNewMLBInfoCollectionExperiment = (state) => {
  return (
    ABAssignmentSelector(state, NEW_MLB_INFO_COLLECTION.name) ===
    NEW_MLB_INFO_COLLECTION.variant
  );
};

export const selectIsWebExclusivesV1Experiment = (state) => {
  return (
    ABAssignmentSelector(state, WEB_EXCLUSIVES_V1.name) ===
    WEB_EXCLUSIVES_V1.variant
  );
};

export const selectIsMLBMatchupLogosExperiment = (state) => {
  return (
    ABAssignmentSelector(state, MLB_MATCHUP_LOGOS.name) ===
    MLB_MATCHUP_LOGOS.variant
  );
};

export const selectIsAppReviewsExperiment = (state) => {
  return ABAssignmentSelector(state, APP_REVIEWS.name) === APP_REVIEWS.variant;
};

export const selectIsNoNFLAutozoomDesktopExperiment = (state) => {
  return (
    ABAssignmentSelector(state, NO_NFL_AUTOZOOM_DESKTOP.name) ===
    NO_NFL_AUTOZOOM_DESKTOP.variant
  );
};

export const selectIsMarketShareCollectionV2Experiment = (state) => {
  return (
    ABAssignmentSelector(state, MARKET_SHARE_COLLECTION_V2.name) ===
    MARKET_SHARE_COLLECTION_V2.variant
  );
};

export const selectIsBillingWebMVPExperiment = (state) => {
  return (
    ABAssignmentSelector(state, BILLING_WEB_MVP.name) ===
    BILLING_WEB_MVP.variant
  );
};

export const selectIsWebDetailsStackedExperiment = (state) => {
  return (
    ABAssignmentSelector(state, WEB_DETAILS_STACKED.name) ===
    WEB_DETAILS_STACKED.variant
  );
};

export const selectIsMLBTeamLogosPerformerExperiment = (state) => {
  return (
    ABAssignmentSelector(state, MLB_TEAM_LOGOS_PERFORMER.name) ===
    MLB_TEAM_LOGOS_PERFORMER.variant
  );
};

export const selectIsWebExclusivesV3Experiment = (state) => {
  return (
    ABAssignmentSelector(state, WEB_EXCLUSIVES_V3.name) ===
    WEB_EXCLUSIVES_V3.variant
  );
};
