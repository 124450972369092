import React from 'react';

import SeatDescription from 'components/SeatDescription/SeatDescription';
import { Disclosure, FullEvent } from 'models';
import Listing from 'models/Listing';
import { getDetailsDate } from 'pages/Listing/utils';

import Disclosures from './Disclosures';

import styles from './DetailsBlock.module.scss';

type Props = {
  fullEvent: FullEvent;
  listing: Listing;
  seatCount: number;
  showSeats: boolean;
  allDisclosures: { [key: string]: Disclosure };
};

export default function DetailsBlock({
  fullEvent,
  listing,
  seatCount,
  showSeats,
  allDisclosures,
}: Props) {
  const date = getDetailsDate(fullEvent);
  const time = fullEvent.getTime();

  return (
    <div className={styles['event-details']}>
      <div className={styles['datetime-seating']}>
        <span className={styles.datetime} data-cy="ticket-date-container">
          {`${date.replace(' ', ' ⋅ ')} ⋅ ${time}`}
        </span>
        <div className={styles.seating} data-cy="pricing-summary-container">
          <span className={styles['seat-detail']}>
            {`Sec ${listing.section}`}
          </span>
          <span className={styles['seat-detail']}>{`Row ${listing.row}`}</span>
          {showSeats && (
            <SeatDescription
              seatCount={seatCount}
              isGeneralAdmission={listing.isGeneralAdmission}
            />
          )}
        </div>
      </div>
      <Disclosures
        allDisclosures={allDisclosures}
        disclosures={listing.disclosures}
      />
    </div>
  );
}
