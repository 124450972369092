import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withDataLoader } from 'contexts/LoaderContext';
import PropTypes from 'prop-types';

import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import GTLogin from 'components/Login/GTLogin';
import { LogoColor } from 'logos';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import { selectUserDetails } from 'store/modules/user/user.selectors';

import styles from './Login.module.scss';

class Login extends Component {
  static propTypes = {
    customPathname: PropTypes.string,
    user: PropTypes.object,
    router: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.user && this.props.user) {
      this.props.router.replace(this.props.customPathname || '/my-tickets');
    }
  }

  render() {
    return (
      <ContainerTemplate
        header={<MinimalHeader search showCategories showHamburger />}
      >
        <HeadTitle title="Sign In" />
        <div className={styles['login-page']}>
          <div className={styles['gt-billboard']}>
            <LogoColor className={styles.logo} />
          </div>
          <div className={styles['login-container']}>
            <div className={styles['login-wrapper']}>
              <GTLogin customPathname={this.props.customPathname} />
            </div>
          </div>
        </div>
      </ContainerTemplate>
    );
  }
}

export default withRouter(
  withDataLoader(
    connect((state, { location: { query } }) => {
      return {
        user: selectUserDetails(state),
        customPathname: query && query.redirect,
      };
    })(Login),
    {
      promise: async ({
        store: { getState, dispatch },
        asyncRedirect,
        location: { query },
      }) => {
        const user = selectUserDetails(getState());
        if (!user) {
          return await dispatch(fetchMetros());
        }
        const urlRedirect =
          query && query.redirect ? query.redirect : '/my-tickets';
        asyncRedirect(urlRedirect);

        return Promise.reject(new Error('User already logged in'));
      },
    }
  )
);
