import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import TextInput from 'components/Inputs/TextInput';
import config from 'config/config';
import HomeFillIcon from 'icons/HomeFillIcon';
import colors from 'styles/colors.constants';

import './AddressAutocompleteInput.module.scss';

const AddressAutocompleteInput = ({
  name,
  onChange,
  onBlur,
  disabled,
  error,
  value,
}) => {
  const inputRef = useRef();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      // fields restricts what's returned in JSON from the API, types restricts the results
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: ['us', 'ca'] },
          fields: ['address_component', 'formatted_address'],
        }
      );

      /**
       * Fires when a user selects one of the suggestions from the autocomplete
       * dropdown, which then sets the value in the <TextInput /> if the result is valid.
       * If not, the value in the component stays the same.
       */
      autocomplete.addListener('place_changed', async () => {
        const place = await autocomplete.getPlace();

        if (place) {
          setFieldValue('address', place.formatted_address);
        }
      });
    });
  }, [setFieldValue]);

  return (
    <TextInput
      ref={inputRef}
      id="user-address"
      name={name}
      label="Address"
      placeholder="Address"
      startIcon={<HomeFillIcon fill={colors.gray200} />}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      error={error}
      value={value}
      required
    ></TextInput>
  );
};

AddressAutocompleteInput.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    hasError: PropTypes.bool,
    message: PropTypes.string,
  }),
  value: PropTypes.string,
};

export default AddressAutocompleteInput;
