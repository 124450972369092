import { RefObject, useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import config from 'config/config';
import { BraintreeBillingAddress } from 'helpers/Braintree';

export type AutocompleteAddressFields = Pick<
  BraintreeBillingAddress,
  'streetAddress' | 'locality' | 'region' | 'postalCode'
>;

/**
 * Converts the address components returned by Google Maps Places API into a
 * more usable object.
 */
export function getAddressFields(
  addressComponents?: google.maps.GeocoderAddressComponent[]
): AutocompleteAddressFields {
  const address: AutocompleteAddressFields = {
    streetAddress: '',
    locality: '',
    region: '',
    postalCode: '',
  };

  addressComponents?.forEach((component) => {
    const componentType = component.types[0];

    // cases as suggested by Google Maps API docs, address components are
    // returned in the order of how they should be assembled into a full
    // address.
    // see: https://github.com/googlemaps/js-samples/blob/86856fd4ff7bd37221296a570cbd85faf15c341a/samples/places-autocomplete-addressform/index.ts#L42-L102
    switch (componentType) {
      case 'street_number': {
        address.streetAddress = `${component.long_name} ${address.streetAddress}`;
        break;
      }
      case 'route': {
        address.streetAddress += component.short_name;
        break;
      }
      case 'locality': {
        address.locality = component.long_name;
        break;
      }
      case 'administrative_area_level_1': {
        address.region = component.long_name;
        break;
      }
      case 'postal_code': {
        address.postalCode = `${component.long_name}${address.postalCode}`;
        break;
      }
      case 'postal_code_suffix': {
        address.postalCode = `${address.postalCode}-${component.long_name}`;
        break;
      }
      default: {
        break;
      }
    }
  });

  return address;
}

/**
 * Custom hook to handle address autocomplete using Google Maps Places API.
 * This hook returns a ref for to the address input element, and will call the
 * provided callback function with the address object when user selects
 * an address from the autocomplete dropdown.
 *
 * This hook is specifically designed to work with a multi-part address form,
 * and the hook can be used directly with the `ref` prop of the streetAddress
 * input element.
 */
export function useAddressAutocomplete(
  onAddressChange: (address: AutocompleteAddressFields) => void
): RefObject<HTMLInputElement> {
  // using a ref here to avoid triggering the useEffect, and making the latest
  // version of the callback function available in the closure.
  const onAddressChangeRef = useRef(onAddressChange);
  onAddressChangeRef.current = onAddressChange;

  // input ref for attaching the autocomplete functionality
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const input = inputRef.current;

    if (!input) {
      return;
    }

    const loader = new Loader({
      apiKey: config.GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.importLibrary('places').then((places) => {
      const autocomplete = new places.Autocomplete(input, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_component'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const address = getAddressFields(place.address_components);
        onAddressChangeRef.current(address);
      });
    });
  }, []);

  return inputRef;
}
