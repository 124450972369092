import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  FullEventClickTracker,
  Hover,
  HoverTracker,
  INTERACTION,
  ListingClickTracker,
  LOCKED_DEAL,
  NoListingsFound,
  PAYLOAD,
  PerformerClickTracker,
  PinsRendered,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
  ZoomEvent,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { UnlockDealEvent } from 'analytics/events/UnlockDeal';
import { isListingZoneDeal, setSBModalSeen } from 'components/Deals/helpers';
import EnsurePath from 'components/EnsurePath/EnsurePath';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import { SUGGESTED_SEAT_COUNT_NOTIFICATION } from 'components/Notifications/constants';
import { showNotification } from 'components/Notifications/Notifications';
import {
  selectIsDealsHarmonyExperiment,
  selectIsGalleryViewV2Experiment,
  selectIsNoNFLAutozoomDesktopExperiment,
  selectIsWebExclusivesV1Experiment,
} from 'experiments';
import { mqSize } from 'hooks/useMediaQuery';
import { FullEvent } from 'models';
import Listing, { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import { GalleryViewCard } from 'pages/Event/components/GalleryView';
import ListingCard from 'pages/Event/components/ListingCard/ListingCard';
import { eventType as mapEventType } from 'pages/Event/components/ListingsMapView/SeatMapInteraction';
import OmnibarOptions from 'pages/Event/components/OmnibarOptions/OmnibarOptions';
import NotFound from 'pages/NotFound/NotFound';
import { PURCHASE_PATH } from 'pages/Purchase/constants';
import { REDIRECT_PERMANENT_STATUS } from 'server/redirects/constants';
import {
  currentLocationSelector,
  setServerRedirectPath,
  showAppSpinner,
  updateCurrentLocation,
} from 'store/modules/app/app';
import {
  isAllInPriceSelector,
  lastZoomLevelSelector,
  setAllInPrice,
  toggleAllInPrice,
  updateLastZoomLevel as updateLastZoomLevelDispatch,
} from 'store/modules/app/app.ui';
import { CATEGORIES } from 'store/modules/categories/category.helpers';
import {
  eventsPageDataSelector,
  updateEventsPageData,
} from 'store/modules/data/eventsPageData';
import {
  fetchFullEventById,
  fetchFullEventsByPrimaryPerformerId as fetchFullEventsByPrimaryPerformerIdDispatch,
} from 'store/modules/data/FullEvents/actions';
import {
  selectFullEventById,
  selectFullEventsByPrimaryPerformerId,
} from 'store/modules/data/FullEvents/selectors';
import {
  fetchListings,
  setHoveredListingId,
  setLockedCarouselHover,
  setPriceQuartile as setPriceQuartileDispatch,
  updateMapHarmony as updateMapHarmonyDispatch,
  updateUnlockedSponsoredDeals,
} from 'store/modules/data/Listings/actions';
import {
  getCarouselHarmony,
  getCarouselListings,
  getListingById,
  getListingsBySeatCount,
  getListingsDataSelector,
  hoveredListingIdSelector,
  isLockedCarouselHoveredSelector,
  isZoneUnlockedSelector,
  listMapHarmonyToggleSelector,
  priceQuartileSelector,
  selectIsVenueAllInPrice,
} from 'store/modules/data/Listings/selectors';
import { getSuggestedSeatCount } from 'store/modules/data/Listings/utils';
import { makeGetSelectPerformersByCategory } from 'store/modules/data/Performers/selectors';
import { setNewViewedEvent } from 'store/modules/data/Search/actions';
import { selectSearchTestData } from 'store/modules/data/Search/selectors';
import {
  isBuyRoute,
  isCheckoutPage,
  isEventPage,
  isEventPagePreListings,
  isEventSubRoute,
  isListingDetailsPage,
  isListingPage,
  isPerformerPage,
  shouldShowListingDetailsOverlay,
} from 'store/modules/history/history';
import { MODALS, showModal } from 'store/modules/modals/modals';
import { seatCountSelector } from 'store/modules/purchase/purchase.selectors';
import { DEFAULT_SEAT_COUNT } from 'store/modules/resources/constants';
import {
  fetchDeals,
  fetchDisclosures,
  fetchMetros,
} from 'store/modules/resources/resource.actions';
import { getPerformerPath } from 'store/modules/resources/resource.paths';
import {
  allDisclosuresSelector,
  selectAllDeals,
} from 'store/modules/resources/resource.selectors';
import { updateSeatMap } from 'store/modules/seatmap/seatmap';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import {
  userPreferenceShowAllInPriceSelector,
  userPreferredSortIdSelector,
} from 'store/modules/userPreference/user.preference.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { MOBILE_VIEW } from 'types/event';
import { formatDate, isPastDate } from 'utils/datetime';
import { addQuery } from 'utils/url';

import { EventProvider } from '../../contexts/EventContext';

import EventBar from './components/EventBar';
import EventHeader from './components/EventHeader';
import EventMeta from './components/EventMeta';
import InvalidEvent from './components/InvalidEvent';
import ListingDetails from './components/ListingDetailContainer/ListingDetails';
import ListingsMapView from './components/ListingsMapView/ListingsMapView';
import MapListSwitch from './components/MapListSwitch';
import NoInventory from './components/NoInventory';
import { EVENTBAR_VIEWS } from './Event.constants';
import {
  getExtremePriceListings,
  getLastRoute,
  getPageViewType,
  isCanadianProvince,
} from './helpers';

import styles from './Event.module.scss';

const isPurchaseRoute = (pathname = '') => pathname.includes(PURCHASE_PATH);

const isListingRoute = (props) => {
  const currentPath = props.location.pathname;
  return currentPath.includes('listings');
};

const propTypes = {
  children: PropTypes.node,
  fullEvent: PropTypes.instanceOf(FullEvent),
  listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  listing: PropTypes.instanceOf(Listing),
  schedule: PropTypes.array,
  user: PropTypes.object,
  seatCount: PropTypes.number,
  showFullHeader: PropTypes.bool,
  sortId: PropTypes.string,
  showSuggestedSeatCount: PropTypes.bool,
  updateUserPreference: PropTypes.func,
  availableSeatCounts: PropTypes.array,
  location: PropTypes.object.isRequired,
  performersByCategory: PropTypes.array,
  noInventory: PropTypes.bool,
  query: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  lastZoomLevel: PropTypes.number,
  getListings: PropTypes.func.isRequired,
  updateLastZoomLevel: PropTypes.func.isRequired,
  toggleAllInPrice: PropTypes.func.isRequired,
  setAllInPrice: PropTypes.func.isRequired,
  isAllInPriceActive: PropTypes.bool,
  showAllInPrice: PropTypes.bool,
  carouselDealType: PropTypes.string,
  carouselListings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
  carouselHarmony: PropTypes.shape({
    listings: PropTypes.arrayOf(PropTypes.instanceOf(Listing)),
    dealType: PropTypes.string,
    isDealsHarmony: PropTypes.bool.isRequired,
  }),
  setLockedCarouselHover: PropTypes.func,
  priceQuartile: PropTypes.number,
  setPriceQuartile: PropTypes.func,
  showSuperBowlModal: PropTypes.bool.isRequired,
  isLockedCarouselHovered: PropTypes.bool,
  fetchFullEventsByPrimaryPerformerId: PropTypes.func,
  updateMapHarmony: PropTypes.func,
  isListMapHarmonyToggleIsOn: PropTypes.bool,
  updateUnlockedSponsoredDeals: PropTypes.func,
  setHoveredListingId: PropTypes.func,
  hoveredListingId: PropTypes.string,
  dealsTracking: PropTypes.shape({
    hasFlashDealsInEvent: PropTypes.bool.isRequired,
    hasZoneDealsInEvent: PropTypes.bool.isRequired,
    hasFeaturedDealsInEvent: PropTypes.bool.isRequired,
    zoneListingsIds: PropTypes.string,
    zoneListingMode: PropTypes.oneOf([LOCKED_DEAL.LIST, LOCKED_DEAL.CAROUSEL]),
  }).isRequired,
  eventPageData: PropTypes.shape({
    eventPageRequestsStartTime: PropTypes.number,
    eventPageRequestsFinishTime: PropTypes.number,
  }),
  appContext: PropTypes.shape({
    state: PropTypes.shape({
      isMobile: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  isGalleryViewMobileVariant: PropTypes.bool.isRequired,
  isAllInPricingWithFlag: PropTypes.bool.isRequired,
  analyticsContext: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  searchTestData: PropTypes.shape({
    testId: PropTypes.string,
    variantId: PropTypes.string,
  }),
  router: PropTypes.object.isRequired,
  lastRouteLocation: PropTypes.object,
  isExclusivesV1: PropTypes.bool.isRequired,
  setServerRedirectPath: PropTypes.func.isRequired,
};

const mapPropsToPathname = (props) => {
  const {
    fullEvent,
    location: { pathname },
  } = props;

  if (!fullEvent || !fullEvent.isValid()) {
    return null;
  }

  if (isListingRoute(props)) {
    return pathname;
  }

  return fullEvent.getPath();
};

@EnsurePath(mapPropsToPathname)
@TrackPageView(
  ({
    fullEvent,
    listing,
    seatCount,
    sortId,
    query,
    isListMapHarmonyToggleIsOn,
    algoliaFields,
    dealsTracking,
    searchTestData,
    ...props
  }) => {
    const { isMobile } = props.appContext.state;
    const mode = getPageViewType(query, isMobile);

    return View.PAGE_TYPES.EVENT({
      fullEvent,
      listing,
      quantity: seatCount,
      sort: sortId,
      isListingRoute: isListingRoute(props),
      mode,
      dealsTracking,
      payload: {
        harmony_enabled: isListMapHarmonyToggleIsOn,
        [PAYLOAD.QUERY_ID]: algoliaFields?.queryId,
        [PAYLOAD.RESULT_POSITION]: algoliaFields?.resultPosition,
        [PAYLOAD.SEARCH_INDEX]: algoliaFields?.searchIndex,
        [PAYLOAD.SEARCH_SESSION_ID]: algoliaFields?.searchSessionId,
        [PAYLOAD.SEARCH_TEST_ID]: searchTestData?.testId,
        [PAYLOAD.SEARCH_VARIANT_ID]: searchTestData?.variantId,
      },
    });
  }
)
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.EVENT(),
}))
@withAnalyticsContext
class EventPage extends Component {
  isFirstUpdate = true;
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    const { fullEvent } = props;
    this.goBackEventRoutes = this.goBackEventRoutes.bind(this);
    this.goToPerformer = this.goToPerformer.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleListingSelection = this.handleListingSelection.bind(this);
    this.handlePinHover = this.handlePinHover.bind(this);
    this.handleListingHover = this.handleListingHover.bind(this);
    this.handleSeatsChange = this.handleSeatsChange.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleOmnibarOptionsClose = this.handleOmnibarOptionsClose.bind(this);
    this.openSuperBowlModal = this.openSuperBowlModal.bind(this);
    this.handleOmnibarControls = this.handleOmnibarControls.bind(this);
    this.handleListingsTouchStart = this.handleListingsTouchStart.bind(this);
    this.handleListingsTouchMove = this.handleListingsTouchMove.bind(this);
    this.handleZoomLevelChange = this.handleZoomLevelChange.bind(this);
    this.handleEventUpdate = this.handleEventUpdate.bind(this);
    this.debouncePusherFetch = _debounce(this.handleEventUpdate, 10000);
    this.handleDealUnlock = this.handleDealUnlock.bind(this);
    this.handleMobileViewChange = this.handleMobileViewChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.initiateCheckoutFlow = this.initiateCheckoutFlow.bind(this);
    this.navigateToNextPath = this.navigateToNextPath.bind(this);
    this.handleMapInteraction = this.handleMapInteraction.bind(this);
    this.handleListMapHarmonyToggleTracking =
      this.handleListMapHarmonyToggleTracking.bind(this);
    this.handleListingClose = this.handleListingClose.bind(this);
    this.handleClearGalleryViewMobile =
      this.handleClearGalleryViewMobile.bind(this);
    this.handleTouchInteractionStart =
      this.handleTouchInteractionStart.bind(this);

    let sidebarView = isListingRoute(props)
      ? EVENTBAR_VIEWS.LISTING_PURCHASE
      : EVENTBAR_VIEWS.LISTINGS;

    if (fullEvent && !fullEvent.isValid()) {
      sidebarView = EVENTBAR_VIEWS.EXPIRED;
    }

    this.state = {
      showOmnibarModal: false,
      sidebarView,
      displayKey: null,
      isScrollingToTop: true,
      galleryViewListing: null,
      isMobileMapAnimating: false,
    };
  }

  shouldSetViewedEvent() {
    const id = this.props.fullEvent?.event?.id || null;
    if (id) {
      setNewViewedEvent(id);
    }
  }

  componentDidMount() {
    const {
      showSuggestedSeatCount,
      noInventory,
      fullEvent,
      listings,
      lastRouteLocation,
      location,
      isAllInPriceActive,
      showAllInPrice,
      lastZoomLevel,
      showSuperBowlModal,
      fetchFullEventsByPrimaryPerformerId,
      setHoveredListingId,
      appContext: {
        state: { isMobile },
      },
      isAllInPricingWithFlag,
      seatCount,
    } = this.props;

    const hoveredListing = this.getHighlightedListing(listings);
    const isCanadianVenueState = isCanadianProvince(fullEvent?.venueState);

    const showRegulatoryAllInPricing =
      isCanadianVenueState || isAllInPricingWithFlag;

    if (noInventory) {
      this.props.getListings({
        eventId: fullEvent?.id,
        zoomLevel: lastZoomLevel,
        isMobile,
        location,
        lastRoute: lastRouteLocation?.pathname,
        quantity: seatCount,
      });
    }

    if (
      showSuggestedSeatCount &&
      isEventPage(location.pathname) &&
      !noInventory &&
      !fullEvent?.isParkingEvent()
    ) {
      showNotification(SUGGESTED_SEAT_COUNT_NOTIFICATION);
    }

    if (hoveredListing) {
      setHoveredListingId(hoveredListing.id);
    }

    this.shouldSetViewedEvent();
    this.prevListingsTouchPageY = null;
    if (typeof window !== 'undefined' && fullEvent) {
      const channel = window.pusher?.subscribe(fullEvent.id);
      channel?.bind('event_update', this.debouncePusherFetch);

      window.addEventListener('focus', this.handleFocus);
      window.addEventListener('blur', this.handleBlur);
    }
    const lastRoute = getLastRoute(lastRouteLocation);

    if (
      isEventPage(location.pathname) &&
      lastRoute &&
      typeof window !== 'undefined'
    ) {
      window.sessionStorage.setItem('GTRootRoute', JSON.stringify(lastRoute));
    }

    if (
      (showRegulatoryAllInPricing && !isAllInPriceActive) ||
      (!showRegulatoryAllInPricing && isAllInPriceActive !== showAllInPrice)
    ) {
      this.props.toggleAllInPrice();
    }

    if (typeof window !== 'undefined' && showSuperBowlModal) {
      // requires localstorage
      setSBModalSeen(fullEvent?.id);
      this.props.showModal(MODALS.SUPER_BOWL);
    }

    if (fullEvent) {
      fetchFullEventsByPrimaryPerformerId(fullEvent.getPrimaryPerformer().id);
    }
  }

  // Attempts to get user's root route due to issues w/ deep linking and router implementation
  getRootRoute() {
    if (typeof window === 'undefined') return '/';
    const stored = window.sessionStorage.getItem('GTRootRoute');
    if (!stored) {
      return '/';
    }
    return JSON.parse(stored);
  }

  handleEventUpdate() {
    const {
      fullEvent,
      location: { pathname },
      lastZoomLevel,
      getListings,
      priceQuartile,
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
      seatCount,
    } = this.props;

    if (isEventPage(pathname)) {
      getListings({
        eventId: fullEvent.id,
        zoomLevel: lastZoomLevel,
        priceBucket: priceQuartile,
        isMobile,
        location,
        lastRoute: lastRouteLocation?.pathname,
        quantity: seatCount,
      });
    }
  }

  componentWillUnmount() {
    const { setPriceQuartile } = this.props;

    if (typeof window !== 'undefined' && window.pusher) {
      window.pusher.allChannels().forEach((channel) => {
        window.pusher.unsubscribe(channel.name);
      });
    }

    this.debouncePusherFetch.cancel();

    setPriceQuartile(-1);

    if (typeof window !== 'undefined') {
      window.removeEventListener('focus', this.handleFocus);
      window.removeEventListener('blur', this.handleBlur);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isAllInPriceActive,
      noInventory,
      listings,
      sortId,
      fullEvent,
      appContext: {
        state: { isMobile },
      },
      seatCount,
      hoveredListingId,
      fetchFullEventsByPrimaryPerformerId,
      showSuggestedSeatCount,
      isGalleryViewMobileVariant,
      isAllInPricingWithFlag,
      setAllInPrice,
      analyticsContext,
      location,
      listing,
    } = this.props;
    const {
      location: { pathname },
    } = prevProps;
    const highlightedListing = this.getHighlightedListing(listings);
    if (!fullEvent || !isEventSubRoute(location.pathname)) return;

    const isDifferentEventId =
      prevProps.fullEvent && fullEvent.id !== prevProps.fullEvent.id;

    // Handles all-in price when switching between events only.
    if (isDifferentEventId) {
      const previousEventState = prevProps.fullEvent?.venueState;
      const currentEventState = fullEvent.venueState;

      if (isAllInPriceActive) {
        if (
          /* Canadian province to a non-Canadian province
             and is not an all-in pricing venue.
          */
          (isCanadianProvince(previousEventState) &&
            !isCanadianProvince(currentEventState) &&
            !isAllInPricingWithFlag) ||
          /* Previous event was an all-in pricing venue and
             current event is not and is not a Canadian province.
          */
          (prevProps.isAllInPricingWithFlag &&
            !isAllInPricingWithFlag &&
            !isCanadianProvince(currentEventState))
        ) {
          setAllInPrice(false);
        }
      } else {
        /* If all-in pricing is off and the current event
           is a Canadian province or an all-in pricing venue
        */
        if (isCanadianProvince(currentEventState) || isAllInPricingWithFlag) {
          setAllInPrice(true);
        }
      }
      // End all-in price handling
    }

    if (
      prevProps.fullEvent?.getPrimaryPerformer().id !==
      fullEvent.getPrimaryPerformer().id
    ) {
      fetchFullEventsByPrimaryPerformerId(fullEvent.getPrimaryPerformer().id);
    }

    if (
      showSuggestedSeatCount &&
      isEventPage(pathname) &&
      !noInventory &&
      !fullEvent.isParkingEvent() &&
      isDifferentEventId
    ) {
      showNotification(SUGGESTED_SEAT_COUNT_NOTIFICATION);
    }

    if (isDifferentEventId && typeof window !== 'undefined' && window.pusher) {
      window.pusher.unsubscribe(prevProps.fullEvent.id);
      const channel = window.pusher.subscribe(fullEvent.id);
      channel?.bind('event_update', this.debouncePusherFetch);
    }

    const isListing = isListingRoute(this.props);
    const isInValid = !fullEvent.isValid();

    let sidebarView = isListing
      ? EVENTBAR_VIEWS.LISTING_PURCHASE
      : EVENTBAR_VIEWS.LISTINGS;

    if (isInValid) {
      sidebarView = EVENTBAR_VIEWS.EXPIRED;
    }

    if (
      sidebarView !== this.state.sidebarView &&
      (sidebarView !== EVENTBAR_VIEWS.LISTING_PURCHASE ||
        (sidebarView === EVENTBAR_VIEWS.LISTING_PURCHASE && listing))
    ) {
      this.setState({ sidebarView });
    }

    if (
      isEventPage(pathname) &&
      noInventory &&
      (this.isFirstUpdate || isDifferentEventId)
    ) {
      const event = new Date(fullEvent.event.datetimeLocal);

      if (!isPastDate(event)) {
        const formattedDate = formatDate(event, 'M/d/yy');
        analyticsContext.track(
          new NoListingsFound({
            performer_name: fullEvent.getPrimaryPerformer().name,
            event_date: formattedDate,
          })
        );
      }
    }

    if (sortId !== prevProps.sortId || isDifferentEventId) {
      const bestDealListing = listings.find(
        (item) => item.getLotDealForQuantity(seatCount) === 'best'
      );
      if (bestDealListing) {
        setHoveredListingId(bestDealListing.id);
      } else if (listings.length) {
        const { lowPriceListing } = getExtremePriceListings(listings, sortId);
        setHoveredListingId(lowPriceListing.id);
      }

      this.shouldSetViewedEvent();
    } else if (
      isMobile &&
      highlightedListing &&
      hoveredListingId &&
      hoveredListingId !== highlightedListing.id
    ) {
      // Set the highlighted listing to the newly unlocked zone deal
      setHoveredListingId(highlightedListing.id);
    }

    if (isGalleryViewMobileVariant) {
      if (isDifferentEventId) {
        this.handleClearGalleryViewMobile();
      }
    }

    if (this.isFirstUpdate) {
      this.isFirstUpdate = false;
    }
  }

  /**
   * Takes an object of listings to search and check if there's a zone deal in the listings.
   * If there is a zone deal, it will then check to see if the deal is active. If the deal is
   * inactive, it will then check to highlight the next available listing so that there's always
   * a highlighted listing on the seat map. In the case that there are no zone deals, it will
   * return the first listing in the array to highlight as a default.
   *
   * @param {Object} listings the listings currently shown on the listing for the event
   * @returns the listing that should be highlighted on the seat map
   */
  getHighlightedListing(listings) {
    if (!listings) return null;

    const { sortId, seatCount } = this.props;
    let highlightedListing;
    const bestDealListing = listings.find(
      (listing) => listing.getLotDealForQuantity(seatCount) === 'best'
    );

    if (bestDealListing) {
      highlightedListing = bestDealListing;
    } else if (listings.length) {
      const { lowPriceListing } = getExtremePriceListings(listings, sortId);
      highlightedListing = lowPriceListing;
    }

    return highlightedListing;
  }

  getBackClickTracker() {
    const { fullEvent, query } = this.props;

    if (query && query.selector) {
      return;
    }

    if (isListingRoute(this.props)) {
      return new FullEventClickTracker(fullEvent);
    }

    return new PerformerClickTracker(fullEvent.getPrimaryPerformer());
  }

  openSuperBowlModal() {
    const {
      location: { pathname },
      listing,
      listings,
    } = this.props;
    let showZoneTicketInfo = false;

    if (
      (isListingRoute(this.props) &&
        listing.disclosures.includes(ZONE_TICKET_DISCLOSURE)) ||
      (isEventPage(pathname) &&
        listings &&
        listings.some((l) => l.disclosures.includes(ZONE_TICKET_DISCLOSURE)))
    ) {
      showZoneTicketInfo = true;
    }

    this.props.showModal(MODALS.SUPER_BOWL, {
      showZoneTicketInfo,
    });
  }

  goToListingDetails(listing) {
    const { fullEvent, lastZoomLevel, location } = this.props;

    const searchParams = new URLSearchParams(location.search);

    searchParams.set('zoom', lastZoomLevel);
    if (isListingPage(location.pathname)) {
      this.props.router.replace({
        pathname: listing.getPath(fullEvent),
        query: Object.fromEntries(searchParams),
      });
    } else {
      this.props.router.push({
        pathname: listing.getPath(fullEvent),
        query: Object.fromEntries(searchParams),
      });
    }
  }

  goToPerformer() {
    const { fullEvent, lastRouteLocation } = this.props;
    const lastRoute = getLastRoute(lastRouteLocation);
    const canGoBack = isPerformerPage(lastRoute);
    if (canGoBack) {
      this.props.router.goBack();
    } else {
      this.props.router.push(fullEvent.getPrimaryPerformer().getPath());
    }
  }

  handleMapHarmony() {
    const {
      updateMapHarmony,
      isListMapHarmonyToggleIsOn,
      appContext: {
        state: { isMobile },
      },
    } = this.props;
    const isDeviceLargeUp =
      typeof window !== 'undefined' && window.innerWidth >= mqSize.lg;

    if (!isMobile && isListMapHarmonyToggleIsOn && isDeviceLargeUp) {
      updateMapHarmony();
    }
  }

  goBackEventRoutes(steps = 1) {
    const { analyticsContext } = this.props;
    const clickTracker = this.getBackClickTracker();

    analyticsContext.track(new Click(clickTracker.json()));

    this.props.router.go(-steps);
    setTimeout(() => this.handleMapHarmony(), 500);
  }

  handleListingSelectionTracking(listing, tracking) {
    const { lastZoomLevel, analyticsContext } = this.props;
    const { isAllInPriceActive, price, listingIndex, isListingOverlay } =
      tracking;
    const listingSelectionTracker = new ListingClickTracker(
      listing,
      isAllInPriceActive,
      price,
      isListingOverlay
    )
      .interaction(Click.INTERACTIONS.SEAT_MAP_PIN())
      .payload({
        item_index: listingIndex,
        zoom_level: lastZoomLevel,
        [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
        [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
        [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
        [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
      });

    analyticsContext.track(new Click(listingSelectionTracker.json()));
  }

  handleUnlockZoneDealTracking(seatMapPin) {
    const { fullEvent, dealsTracking, analyticsContext } = this.props;
    analyticsContext.track(
      new UnlockDealEvent(
        View.PAGE_TYPES.LISTING({
          [PAYLOAD.FULLEVENT]: fullEvent,
          [PAYLOAD.LISTING]: seatMapPin.listing,
          payload: {
            [PAYLOAD.MODE]: dealsTracking.zoneListingMode,
            [PAYLOAD.DEAL]: seatMapPin.deal.dealType,
            [PAYLOAD.LISTINGS_IDS]: dealsTracking.zoneListingsIds,
          },
          [PAYLOAD.INTERACTION]: INTERACTION.SEAT_MAP_PIN,
          [PAYLOAD.PAGE_TYPE]: undefined,
        })
      )
    );
  }

  handleListingSelection(seatMapPin) {
    const { setHoveredListingId, isGalleryViewMobileVariant } = this.props;
    const { listing, deal, tracking } = seatMapPin;
    this.handleListingSelectionTracking(listing, tracking);
    const isGalleryListingFirstClick =
      this.state.galleryViewListing?.id !== listing.id;

    if (deal.isLockedDeal) {
      this.handleDealUnlock(deal.dealType);
      this.handleUnlockZoneDealTracking(seatMapPin);

      if (deal.singleClickUnlock) {
        setHoveredListingId(listing.id);
        this.goToListingDetails(listing);
      }

      if (isGalleryViewMobileVariant && isGalleryListingFirstClick) {
        this.setState({
          galleryViewListing: listing,
          isMobileMapAnimating: false,
        });
      }

      // don't go to listing details if not singleClickUnlock
      return;
    }

    if (isGalleryViewMobileVariant) {
      if (isGalleryListingFirstClick) {
        this.setState({
          galleryViewListing: listing,
          isMobileMapAnimating: false,
        });
        return;
      }
      this.setState({
        isMobileMapAnimating: false,
      });
    }

    setHoveredListingId(listing.id);
    this.goToListingDetails(listing);
  }

  handleBack() {
    const {
      query,
      location: { pathname },
      isGalleryViewMobileVariant,
      lastRouteLocation,
      analyticsContext,
    } = this.props;
    const lastRoute = getLastRoute(lastRouteLocation);

    const { view = MOBILE_VIEW.LIST } = query;

    const clickTracker = this.getBackClickTracker();

    analyticsContext.track(new Click(clickTracker.json()));

    if (isGalleryViewMobileVariant) {
      this.handleClearGalleryViewMobile();
    }

    if (isEventPage(pathname) && isListingPage(lastRoute)) {
      return this.props.router.push(this.getRootRoute());
    }

    if (query && query.selector) {
      return this.props.router.push({
        pathname,
      });
    }

    if (view === MOBILE_VIEW.MAP) {
      return this.props.router.replace(pathname);
    }

    return this.props.router.goBack();
  }

  handleSeatsChange(seatCount) {
    const {
      isGalleryViewMobileVariant,
      updateUserPreference,
      fullEvent,
      lastZoomLevel,
      priceQuartile,
      location,
      appContext: {
        state: { isMobile },
      },
      lastRouteLocation,
    } = this.props;

    updateUserPreference({ seatCount });
    this.props.getListings({
      eventId: fullEvent.id,
      zoomLevel: lastZoomLevel,
      priceBucket: priceQuartile,
      isMobile,
      quantity: seatCount,
      location,
      lastRoute: lastRouteLocation?.pathname,
    });

    this.handleOmnibarOptionsClose();
    this.handleMapHarmony();

    if (isGalleryViewMobileVariant) {
      setTimeout(() => this.validateGalleryViewListing(), 500);
    }
  }

  handleSortChange(sortId) {
    const {
      fullEvent,
      lastZoomLevel,
      priceQuartile,
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
      seatCount,
    } = this.props;
    this.props.updateUserPreference({ sortId });
    this.props.getListings({
      eventId: fullEvent.id,
      zoomLevel: lastZoomLevel,
      priceBucket: priceQuartile,
      isMobile,
      location,
      lastRoute: lastRouteLocation?.pathname,
      quantity: seatCount,
    });
    this.handleOmnibarOptionsClose();
  }

  handleAllInPrice() {
    const {
      isAllInPriceActive,
      fullEvent,
      lastZoomLevel,
      priceQuartile,
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
      seatCount,
    } = this.props;
    this.props.updateUserPreference({ showAllInPrice: !isAllInPriceActive });
    this.props.getListings({
      eventId: fullEvent.id,
      zoomLevel: lastZoomLevel,
      priceBucket: priceQuartile,
      isMobile,
      location,
      lastRoute: lastRouteLocation?.pathname,
      quantity: seatCount,
    });
    this.props.toggleAllInPrice();
  }

  validateGalleryViewListing() {
    const { listings, carouselListings, carouselHarmony } = this.props;
    const { galleryViewListing } = this.state;
    if (!galleryViewListing) return;

    const isListingInArray = (array) =>
      array.some((listing) => listing.id === galleryViewListing.id);

    if (
      !isListingInArray(listings) &&
      !isListingInArray(carouselListings) &&
      !isListingInArray(carouselHarmony.listings)
    ) {
      this.handleClearGalleryViewMobile();
    }
  }

  handleRecuration(quartileId) {
    const {
      fullEvent,
      lastZoomLevel,
      setPriceQuartile,
      appContext: {
        state: { isMobile },
      },
      isGalleryViewMobileVariant,
      location,
      lastRouteLocation,
      seatCount,
    } = this.props;
    setPriceQuartile(quartileId);

    this.props
      .getListings({
        eventId: fullEvent.id,
        zoomLevel: lastZoomLevel,
        priceBucket: quartileId,
        isMobile,
        location,
        lastRoute: lastRouteLocation?.pathname,
        quantity: seatCount,
      })
      .then(() => {
        if (isGalleryViewMobileVariant) {
          this.validateGalleryViewListing();
        }
      });
  }

  handleOmnibarControls(id, params) {
    const {
      query,
      appContext: {
        state: { isMobile },
      },
      fullEvent,
      analyticsContext,
    } = this.props;
    const { displayKey } = this.state;
    const isActive = displayKey !== id;
    const pageViewType = getPageViewType(query, isMobile);

    if (id === 'price_bucket') {
      const priceId = this.props.priceQuartile === params.id ? -1 : params.id;
      const recurationTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.SETTINGS_TOGGLE(), {
          event_id: fullEvent.id,
          setting: 'price_bucket',
          action: priceId === -1 ? 'disable' : 'enable',
          price_bucket: params?.label,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(recurationTracker.json()));

      return this.handleRecuration(priceId);
    }

    if (id === 'allInPricing') {
      const allInTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.ALL_IN_FILTER(), {
          event_id: fullEvent.id,
          action: params.isAllInPriceActive ? 'enable' : 'disable',
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(allInTracker.json()));

      return this.handleAllInPrice(id);
    }

    if (isActive) {
      const omniTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.OMNIBAR(false), {
          event_id: fullEvent.id,
          quantity: this.props.seatCount,
          sort: this.props.sortId,
        })
        .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType))
        .targetPageType(Click.TARGET_PAGE_TYPES.OMNIBAR_PAGES(id));
      analyticsContext.track(new Click(omniTracker.json()));
    }

    this.setState({
      showOmnibarModal: isActive,
      displayKey: isActive ? id : null,
    });
  }

  handleOmnibarOptionsClose(isCancel = false) {
    const { displayKey } = this.state;
    const { fullEvent, analyticsContext } = this.props;

    if (isCancel) {
      const omniTracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.CANCEL())
        .sourcePageType(Click.SOURCE_PAGE_TYPES.OMNIBAR_PAGES(displayKey))
        .targetPageType(Click.TARGET_PAGE_TYPES.EVENT(fullEvent.id));

      analyticsContext.track(new Click(omniTracker.json()));
    }

    this.setState({ showOmnibarModal: false, displayKey: null });
  }

  handlePinHover({ listing, tracking }) {
    const {
      query,
      fullEvent,
      isAllInPriceActive,
      lastZoomLevel,
      analyticsContext,
    } = this.props;
    const pageViewType = getPageViewType(query);
    const tracker = new HoverTracker()
      .interaction(
        Hover.INTERACTIONS.HOVER_CARDS({
          eventId: fullEvent.id,
          listingId: listing.id,
          allInPricing: isAllInPriceActive,
          displayedPrice: tracking.price,
        })
      )
      .payload({
        [PAYLOAD.ITEM_INDEX]: tracking.listingIndex,
        [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
        [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
        [PAYLOAD.ZOOM_LEVEL]: lastZoomLevel,
      })
      .sourcePageType(
        Hover.SOURCE_PAGE_TYPES.EVENT({
          pageViewType,
          isListingDetails: tracking.isListingDetails,
        })
      );
    analyticsContext.track(new Hover(tracker.json()));
  }

  handleListingHover(hoveredListing) {
    const {
      appContext: {
        state: { isMobile },
      },
      isLockedCarouselHovered,
      setHoveredListingId,
      hoveredListingId,
    } = this.props;

    if (!isMobile && isLockedCarouselHovered) {
      this.props.setLockedCarouselHover(false);
    }

    // Don't update the state if the user hightlights over the same listing
    if (hoveredListingId === hoveredListing.id) {
      return;
    }

    if (!isMobile) {
      setHoveredListingId(hoveredListing?.id);
    }
  }

  handleListingsTouchStart(e) {
    this.prevListingsTouchPageY = e.touches[0].pageY;
  }

  handleListingsTouchMove(e) {
    const { isScrollingToTop: prevIsScrollingToTop } = this.state;
    const nextIsScrollingToTop =
      this.prevListingsTouchPageY < e.touches[0].pageY;
    if (prevIsScrollingToTop !== nextIsScrollingToTop) {
      this.setState({
        isScrollingToTop: nextIsScrollingToTop,
      });
    }
    this.prevListingsTouchPageY = e.touches[0].pageY;
  }

  isCheckout(pathname) {
    return pathname.endsWith('checkout') || pathname.endsWith('checkout/');
  }

  getMobileEventView() {
    const view = this.props.query?.view?.toLowerCase() ?? MOBILE_VIEW.LIST;
    return {
      type: view,
      isList: view === MOBILE_VIEW.LIST,
      isMap: view === MOBILE_VIEW.MAP,
    };
  }

  updateMobileEventView({ view, withoutHistory = false }) {
    const { fullEvent, location } = this.props;
    const newRoute = addQuery(fullEvent.getPath(), location.search, {
      view,
    });
    if (withoutHistory) {
      this.props.router.replace(newRoute);
    } else {
      this.props.router.push(newRoute);
    }
  }

  handleMobileViewChange() {
    const mobileEventView = this.getMobileEventView();
    if (mobileEventView.isList) {
      this.handleZoomLevelChange(5);
      if (
        this.props.isGalleryViewMobileVariant &&
        this.state.galleryViewListing
      ) {
        this.handleClearGalleryViewMobile();
      }
      this.updateMobileEventView({
        view: MOBILE_VIEW.MAP,
        withoutHistory: true,
      });
    } else {
      this.updateMobileEventView({
        view: MOBILE_VIEW.LIST,
        withoutHistory: true,
      });
    }
  }

  handleTrackingClearGalleryViewMobile() {
    const {
      query,
      appContext: {
        state: { isMobile },
      },
      fullEvent,
      analyticsContext,
    } = this.props;

    const pageViewType = getPageViewType(query, isMobile);
    const tracker = new ClickTracker()
      .interaction(Click.INTERACTIONS.FOCUSED_CARD_CANCEL(), {
        event_id: fullEvent.id,
      })
      .payload({
        listing_id: this.state.galleryViewListing?.id,
      })
      .sourcePageType(Click.SOURCE_PAGE_TYPES.EVENT(pageViewType));
    analyticsContext.track(new Click(tracker.json()));
  }

  handleClearGalleryViewMobile(track = false) {
    if (track) {
      this.handleTrackingClearGalleryViewMobile();
    }
    this.setState({ galleryViewListing: null, isMobileMapAnimating: false });
  }

  shouldPersistListings() {
    const {
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
    } = this.props;
    if (isMobile) return false;

    return (
      (isEventPagePreListings(location.pathname) ||
        isListingDetailsPage(location.pathname)) &&
      shouldShowListingDetailsOverlay(location, lastRouteLocation)
    );
  }

  renderInlineChildren() {
    const {
      children,
      listing,
      fullEvent,
      location,
      user,
      lastRouteLocation,
      seatCount,
    } = this.props;

    const childProps = {
      event: fullEvent,
    };

    if (!isListingRoute(this.props) || !listing) {
      return null;
    }

    const isDeviceLargeUp =
      typeof window !== 'undefined' && window.innerWidth >= mqSize.lg;

    return (
      <ListingDetails
        shouldAnimate={isDeviceLargeUp}
        handleTracking={this.getBackClickTracker()}
        onBack={this.goBackEventRoutes}
        listing={listing}
        user={user}
        fullEvent={fullEvent}
        childProps={childProps}
        isZoneDeal={isListingZoneDeal(listing, seatCount)}
        isPurchaseRoute={isPurchaseRoute(location.pathname)}
        isCheckout={this.isCheckout(location.pathname)}
        isHarmonyPlusOverlay={
          isDeviceLargeUp &&
          shouldShowListingDetailsOverlay(location, lastRouteLocation)
        }
      >
        {children}
      </ListingDetails>
    );
  }

  renderSidebar(isCheckout) {
    const {
      fullEvent,
      listings,
      schedule,
      sortId,
      noInventory,
      performersByCategory,
      allDisclosures,
      allDeals,
      seatCount,
      appContext: {
        state: { isMobile },
      },
      lastZoomLevel,
      carouselListings,
      carouselDealType,
      carouselHarmony,
      location,
      lastRouteLocation,
      isAllInPriceActive,
      isExclusivesV1,
    } = this.props;
    const { sidebarView } = this.state;
    const primaryPerformer = fullEvent.getPrimaryPerformer();

    if (noInventory && sidebarView === EVENTBAR_VIEWS.LISTINGS) {
      return <NoInventory primaryPerformer={primaryPerformer} />;
    }

    return (
      <EventBar
        key={fullEvent.id}
        onListingHover={this.handleListingHover}
        onListingsTouchStart={
          isMobile ? this.handleListingsTouchStart : () => {}
        }
        onListingsTouchMove={isMobile ? this.handleListingsTouchMove : () => {}}
        isCheckout={isCheckout}
        schedule={schedule}
        relatedPerformers={performersByCategory.slice(0, 10)}
        sortId={sortId}
        fullEvent={fullEvent}
        listings={listings}
        sidebarView={sidebarView}
        allDisclosures={allDisclosures}
        allDeals={allDeals}
        seatCount={seatCount}
        zoomLevel={lastZoomLevel}
        handleDealUnlock={this.handleDealUnlock}
        carouselListings={carouselListings}
        carouselDealType={carouselDealType}
        carouselHarmony={carouselHarmony}
        isHarmonyPlusOverlay={
          !isMobile &&
          shouldShowListingDetailsOverlay(location, lastRouteLocation)
        }
        isAllInPriceActive={isAllInPriceActive}
        isExclusivesV1={isExclusivesV1}
      >
        {this.renderInlineChildren()}
      </EventBar>
    );
  }

  handleZoomLevelChange(nextZoomLevel) {
    const {
      fullEvent,
      lastZoomLevel,
      updateLastZoomLevel,
      getListings,
      priceQuartile,
      isGalleryViewMobileVariant,
      appContext: {
        state: { isMobile },
      },
      location,
      lastRouteLocation,
      seatCount,
    } = this.props;

    if (lastZoomLevel !== nextZoomLevel) {
      // prevent refetching of listings when recuration experiment is on and a price bucket has been selected
      const skipRefetching = priceQuartile > 0;
      if (!skipRefetching) {
        getListings({
          eventId: fullEvent.id,
          zoomLevel: nextZoomLevel,
          isMobile,
          location,
          lastRoute: lastRouteLocation?.pathname,
          quantity: seatCount,
        }).then(() => {
          if (isGalleryViewMobileVariant) {
            this.validateGalleryViewListing();
          }
        });
      }
      updateLastZoomLevel(nextZoomLevel);
    }
  }

  handleDealUnlock() {
    const { updateUnlockedSponsoredDeals, fullEvent } = this.props;
    updateUnlockedSponsoredDeals(fullEvent.id);
  }

  mapTracking(seatMap) {
    const {
      fullEvent,
      isAllInPriceActive,
      seatCount,
      listings = [],
      isListMapHarmonyToggleIsOn,
      eventPageData = {
        eventPageRequestsFinishTime: 0,
        eventPageRequestsStartTime: 0,
      },
      analyticsContext,
    } = this.props;

    // Exit early if there are no listings
    if (listings.length < 1) return;

    // Exit early if the first listing is not the current event
    if (listings[0].eventId !== fullEvent.id) return;

    const { zoomType, zoomLevel, interaction } = seatMap;
    const map_type = 'listing_pins';

    const baseTracking = {
      event_id: fullEvent.id,
      metro: fullEvent.venue.metro,
      performer_id: fullEvent.getPrimaryPerformer().id,
      venue_id: fullEvent.venue.id,
    };

    const commonTracking = {
      all_in_pricing: isAllInPriceActive,
      quantity: seatCount,
      map_type,
    };

    const getListingsRendered = () => {
      if (!listings) return [];
      return listings.reduce((acc, listing) => {
        return acc.concat({
          price: isAllInPriceActive
            ? listing.getTotalPrice()
            : listing.getPrice(),
          id: listing.id,
        });
      }, []);
    };

    /**
     * @param page_load - initial map load tracking
     */
    if (interaction === 'page_load') {
      const renderedListings = getListingsRendered();
      const backendLatencyMs = Math.round(
        eventPageData.eventPageRequestsFinishTime -
          eventPageData.eventPageRequestsStartTime
      );
      const firstRenderMs = Math.round(
        seatMap.lastPinRenderMs - eventPageData.eventPageRequestsStartTime
      );
      const areaZoomTracking = {
        ...baseTracking,
        payload: {
          ...commonTracking,
          backend_latency_ms: backendLatencyMs,
          map_image_render_ms: seatMap.mapImageRenderMs,
          first_pin_render_ms: firstRenderMs,
          harmony_enabled: isListMapHarmonyToggleIsOn,
          listings: renderedListings,
          listings_length: renderedListings.length,
        },
      };

      setTimeout(() => this.handleMapHarmony(), 500);
      return analyticsContext.track(new PinsRendered(areaZoomTracking));
    }

    /**
     * @param seatMap - tracking: [click, double_click, wheel, drag, touch, reset]
     */
    const mapTracking = {
      ...baseTracking,
      payload: {
        timestamp: Date.now(),
        interaction,
        map_type,
        zoom_type: zoomType,
        zoom_level: zoomLevel,
        harmony_enabled: isListMapHarmonyToggleIsOn,
      },
    };

    /**
     * @param pan - zoom_type: [drag]
     */
    if (interaction === mapEventType.drag) {
      mapTracking.payload.zoom_type = mapEventType.drag;
    }

    analyticsContext.track(new ZoomEvent(mapTracking));
  }

  handleMapInteraction(seatMap) {
    const { zoomLevel, zoomType } = seatMap;
    this.mapTracking(seatMap);

    if (zoomType) {
      this.handleZoomLevelChange(zoomLevel);
    }

    if (
      this.props.isGalleryViewMobileVariant &&
      this.state.isMobileMapAnimating
    ) {
      setTimeout(() => this.setState({ isMobileMapAnimating: false }), 500);
    }
  }

  handleFocus() {
    const { fullEvent } = this.props;

    const channelExist = window.pusher?.channel(fullEvent.id);

    if (!channelExist) {
      const channel = window.pusher?.subscribe(fullEvent.id);
      channel?.bind('event_update', this.debouncePusherFetch);
    }
  }

  handleBlur() {
    const { fullEvent } = this.props;

    const channelExist = window.pusher?.channel(fullEvent.id);

    if (channelExist) {
      window.pusher.unsubscribe(fullEvent.id);
    }
  }

  initiateCheckoutFlow() {
    const { location } = this.props;
    let { pathname } = location;

    if (pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }

    setTimeout(() => {
      this.navigateToNextPath(`${pathname}/checkout`);
    }, 200);
  }

  navigateToNextPath(nextPath) {
    const { location } = this.props;

    this.props.router.push({
      pathname: nextPath,
      search: location.search,
    });
  }

  handleListMapHarmonyToggleTracking(listMapHarmonyToggleIsOn) {
    const { fullEvent, analyticsContext } = this.props;

    analyticsContext.track(
      new Click({
        interaction: listMapHarmonyToggleIsOn
          ? Click.INTERACTIONS.HARMONY_TOGGLE_ENABLED()
          : Click.INTERACTIONS.HARMONY_TOGGLE_DISABLED(),
        sourcePageType: Click.SOURCE_PAGE_TYPES.EVENT(),
        targetPageType: Click.TARGET_PAGE_TYPES.EVENT(fullEvent.id),
        metro: fullEvent.venue.metro,
      })
    );
  }

  handleTouchInteractionStart() {
    this.setState({ isMobileMapAnimating: true });
  }

  handleListingClose() {
    this.goBackEventRoutes(2);
  }

  render() {
    const {
      listings = [],
      carouselListings = [],
      carouselHarmony,
      listing,
      fullEvent,
      appContext: {
        state: { isMobile },
      },
      location: { pathname },
      showFullHeader,
      schedule,
      seatCount,
      sortId,
      availableSeatCounts,
      performersByCategory,
      priceQuartile,
      carouselDealType,
      isAllInPriceActive,
      noInventory,
      hoveredListingId,
      setHoveredListingId,
      lastZoomLevel,
      allDisclosures,
      allDeals,
      isGalleryViewMobileVariant,
    } = this.props;
    const {
      isScrollingToTop,
      sidebarView,
      displayKey,
      showOmnibarModal,
      galleryViewListing,
      isMobileMapAnimating,
    } = this.state;

    if (!fullEvent) {
      return <NotFound />;
    }

    const allCarouselListings = [
      ...carouselListings,
      ...carouselHarmony.listings,
    ];

    const mobileEventView = this.getMobileEventView();
    const isListing = isListingRoute(this.props);
    const isCheckout = isCheckoutPage(pathname);
    const showSidebarControls = sidebarView === EVENTBAR_VIEWS.LISTINGS;
    const shouldRenderMap = !isMobile || mobileEventView.isMap;

    const isListingFlow = isListingDetailsPage(pathname);
    const isPostListingFlow = isCheckoutPage(pathname) || isBuyRoute(pathname);

    const persistListings = this.shouldPersistListings();
    const isValidMobileEventView =
      isMobile && fullEvent.isValid() && !noInventory && !isListing;
    const isGalleryViewMobileListingPresent = !!galleryViewListing;

    const eventHeaderProps = {
      fullEvent,
      seatCount,
      sortId,
      isAllInPriceActive,
      priceQuartile,
      noInventory,
      view: mobileEventView.type,
      isListing,
      persistListings,
      showSidebarControls,
      isPurchaseRoute: isPurchaseRoute(pathname),
      onBack: this.handleBack,
      handleBackTracking: this.getBackClickTracker(),
      handleOpenSBModal: this.openSuperBowlModal,
      handleOmnibarControls: this.handleOmnibarControls,
    };

    const showGTMessage = !isCheckout && !isListing;

    const minimalHeaderProps =
      mobileEventView.isList && showFullHeader
        ? {
            search: true,
            showCategories: true,
            showAccount: true,
            showHamburger: true,
            hideMobileHeader: isListingFlow && isMobile,
          }
        : { hideMobileHeader: true };

    const isAllInEvent = isCanadianProvince(fullEvent.venueState);
    const showRegulatoryAllInPricing = isAllInEvent && isListing;
    const showSinglePin = (isListing && isMobile) || isPostListingFlow;

    if (!fullEvent.isValid() && fullEvent.daysSinceExpiration >= 30) {
      const redirectPath = getPerformerPath(fullEvent.getPrimaryPerformer());
      this.props.setServerRedirectPath(redirectPath, REDIRECT_PERMANENT_STATUS);
    }

    return (
      <EventProvider
        fullEvent={fullEvent}
        activeListing={listing}
        listings={listings}
        carouselListings={allCarouselListings}
      >
        <ContainerTemplate
          header={
            <MinimalHeader
              {...minimalHeaderProps}
              isEventPage
              showGTMessage={showGTMessage}
              hiddenByScroll={!isScrollingToTop}
            />
          }
          showFooter={false}
        >
          <EventMeta />
          <div
            className={classNames(
              styles['event-page'],
              styles[mobileEventView.type],
              {
                [styles.listing]: isListing,
              }
            )}
          >
            {!fullEvent.isValid() && (
              <div className={styles['fade-map-image']}></div>
            )}

            <EventHeader {...eventHeaderProps} variant="top" />

            <main className={styles.main}>
              <section
                className={classNames(styles['map-section'], {
                  [styles.listing]: isListing,
                })}
              >
                {shouldRenderMap && (
                  <div className={styles['map-view']}>
                    {!fullEvent.isValid() && (
                      <InvalidEvent
                        schedule={schedule}
                        eventId={fullEvent.id}
                        performersByCategory={performersByCategory}
                      />
                    )}
                    <ListingsMapView
                      key={fullEvent.id}
                      showSinglePin={showSinglePin}
                      onPinHover={this.handlePinHover}
                      handleMapInteraction={this.handleMapInteraction}
                      handleListingSelection={this.handleListingSelection}
                      handleDealUnlock={this.handleDealUnlock}
                      onListingClose={this.handleListingClose}
                      highlightedListingId={
                        isGalleryViewMobileVariant
                          ? galleryViewListing?.id
                          : hoveredListingId
                      }
                      seatCount={seatCount}
                      isEventPage={isEventPage(pathname)}
                      isListingDetailsPage={isListing}
                      clearHoveredListing={() => {
                        setHoveredListingId();
                      }}
                      isAllInPrice={
                        isAllInPriceActive || showRegulatoryAllInPricing
                      }
                      carouselDealType={carouselDealType}
                      isListingFlow={isListingFlow}
                      initiateCheckoutFlow={this.initiateCheckoutFlow}
                      handleListMapHarmonyToggleTracking={
                        this.handleListMapHarmonyToggleTracking
                      }
                      isCheckout={isCheckout}
                      zoomLevel={lastZoomLevel}
                      allDisclosures={allDisclosures}
                      onListingHover={this.handleListingHover}
                      isHarmonyPlusOverlay={!isMobile}
                      onTouchInteractionStart={this.handleTouchInteractionStart}
                      hasGalleryViewMobileListing={
                        isGalleryViewMobileListingPresent
                      }
                    />
                  </div>
                )}
              </section>
              <aside className={styles['event-sidebar']}>
                <EventHeader {...eventHeaderProps} variant="sidebar" />

                <div
                  className={classNames(styles['event-sidebar-content'], {
                    [styles['event-sidebar-checkout']]: isCheckout,
                  })}
                >
                  {this.renderSidebar(isCheckout)}
                </div>
              </aside>
            </main>

            {isValidMobileEventView && (
              <>
                {isGalleryViewMobileVariant && mobileEventView.isMap && (
                  <GalleryViewCard
                    onClose={() => this.handleClearGalleryViewMobile(true)}
                    isVisible={isGalleryViewMobileListingPresent}
                    isAnimating={isMobileMapAnimating}
                  >
                    <ListingCard
                      isExclusivesV1
                      isGalleryView
                      listingIndex={1}
                      fullEvent={fullEvent}
                      listing={galleryViewListing}
                      allDisclosures={allDisclosures}
                      allDeals={allDeals}
                      seatCount={seatCount}
                      zoomLevel={lastZoomLevel}
                      isFlashDeal={galleryViewListing?.isFlashDeal}
                      isZoneDeal={galleryViewListing?.isZoneDeal}
                      className={styles['gallery-view-card']}
                      isFocusedCard
                    />
                  </GalleryViewCard>
                )}

                {!isMobileMapAnimating && (
                  <MapListSwitch
                    onClick={this.handleMobileViewChange}
                    view={mobileEventView}
                  />
                )}
              </>
            )}
          </div>

          <OmnibarOptions
            schedule={schedule}
            fullEvent={fullEvent}
            seatCount={seatCount}
            sortId={sortId}
            availableSeatCounts={availableSeatCounts}
            handleSeatsChange={this.handleSeatsChange}
            handleSortChange={this.handleSortChange}
            displayKey={displayKey}
            showOmnibarOptions={showOmnibarModal}
            onClose={this.handleOmnibarOptionsClose}
          />
        </ContainerTemplate>
      </EventProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    params: { eventId, listingId },
    appContext,
    location,
  } = props;
  // allDisclosures is a dictionary of every disclosure we support so that we can enrich the disclosures coming with listings
  const allDisclosures = allDisclosuresSelector(state);
  // allDeals is a dict with an up-to-date list of deals we support
  const allDeals = selectAllDeals(state);
  const fullEvent = selectFullEventById(state, eventId);
  if (!fullEvent) {
    return {};
  }

  const query = location.query;
  const schedule =
    selectFullEventsByPrimaryPerformerId(
      state,
      fullEvent.getPrimaryPerformer().id
    ) || [];
  const preferredSeatCount = seatCountSelector(state) || DEFAULT_SEAT_COUNT;
  const sortId = userPreferredSortIdSelector(state);
  const selectPerformersByCategory = makeGetSelectPerformersByCategory();
  const performersByCategory = selectPerformersByCategory(
    state,
    fullEvent.getPrimaryPerformer().category
  );

  const lastZoomLevel = lastZoomLevelSelector(state);
  const isAllInPriceActive = isAllInPriceSelector(state);

  const listingsData = getListingsDataSelector(state);
  const seatCount = getSuggestedSeatCount(
    listingsData.availableLots,
    preferredSeatCount
  );

  const displayGroup = getListingsBySeatCount(listingsData, seatCount);

  const isDealsHarmony = selectIsDealsHarmonyExperiment(state);
  const carouselHarmony = getCarouselHarmony({
    listings: listingsData,
    seatCount,
    isDealsHarmony,
  });

  const carouselListings = getCarouselListings({
    listings: listingsData,
    seatCount,
    isDealsHarmony: carouselHarmony.isDealsHarmony,
  });

  const showSuperBowlModal = false;
  const showFullHeader =
    isEventPagePreListings(location.pathname) ||
    isListingDetailsPage(location.pathname);

  const algoliaFields = {
    queryId: query?.queryId,
    resultPosition: query?.resultPosition,
    searchIndex: query?.searchIndex,
    searchSessionId: query?.searchSessionId,
  };

  const listing = [
    ...carouselListings.listings,
    ...carouselHarmony.listings,
    ...displayGroup.listings,
  ].find((listing) => listing.id === listingId);

  const hasFlashDealsInEvent =
    carouselListings.isFlashDeal ||
    carouselHarmony.isFlashDeal ||
    displayGroup.hasFlashDeals;

  const hasZoneDealsInEvent =
    carouselListings.isZoneDeal ||
    carouselHarmony.isZoneDeal ||
    displayGroup.hasZoneDeals;

  const zoneListingsIds = [
    ...carouselListings.zoneDealListingIds,
    ...carouselHarmony.zoneDealListingIds,
    ...displayGroup.zoneDealListingIds,
  ];

  let zoneListingMode = null;
  if (hasZoneDealsInEvent) {
    zoneListingMode = displayGroup.hasZoneDeals
      ? LOCKED_DEAL.LIST
      : LOCKED_DEAL.CAROUSEL;
  }

  const { listings } = displayGroup;

  const { highPriceListing } = getExtremePriceListings(listings, sortId);
  if (highPriceListing && highPriceListing.price) {
    fullEvent.highPrice = highPriceListing.price;
  }

  return {
    eventPageData: eventsPageDataSelector(state),
    fullEvent,
    listings,
    listing,
    schedule,
    user: selectUserDetails(state),
    seatCount,
    showFullHeader,
    sortId,
    showSuggestedSeatCount: seatCount != preferredSeatCount,
    availableSeatCounts: listingsData.availableLots,
    noInventory: Object.keys(listingsData.allListings).length === 0,
    performersByCategory: performersByCategory || [],
    query,
    allDisclosures,
    allDeals,
    lastZoomLevel,
    isAllInPriceActive,
    showAllInPrice: userPreferenceShowAllInPriceSelector(state),
    carouselDealType: carouselListings.dealType,
    carouselListings: carouselListings.listings,
    carouselHarmony,
    priceQuartile: priceQuartileSelector(state),
    showSuperBowlModal,
    isLockedCarouselHovered: isLockedCarouselHoveredSelector(state),
    listingId,
    isListMapHarmonyToggleIsOn: listMapHarmonyToggleSelector(state),
    algoliaFields,
    isZoneDealUnlocked: isZoneUnlockedSelector(state, eventId),
    hoveredListingId: hoveredListingIdSelector(state),
    dealsTracking: {
      hasFlashDealsInEvent,
      hasZoneDealsInEvent,
      hasFeaturedDealsInEvent: displayGroup.hasFeaturedDeals,
      zoneListingsIds: zoneListingsIds.join(),
      zoneListingMode: zoneListingMode,
    },
    isGalleryViewMobileVariant:
      selectIsGalleryViewV2Experiment(state) && appContext.state.isMobile,
    isAllInPricingWithFlag: selectIsVenueAllInPrice(
      state,
      fullEvent.venueState
    ),
    searchTestData: selectSearchTestData(state),
    isExclusivesV1: selectIsWebExclusivesV1Experiment(state),
  };
};

const mapDispatchToProps = {
  updateUserPreference,
  showModal,
  getListings: fetchListings,
  updateLastZoomLevel: updateLastZoomLevelDispatch,
  toggleAllInPrice,
  setAllInPrice,
  setLockedCarouselHover,
  setPriceQuartile: setPriceQuartileDispatch,
  showAppSpinner,
  fetchFullEventsByPrimaryPerformerId:
    fetchFullEventsByPrimaryPerformerIdDispatch,
  updateMapHarmony: updateMapHarmonyDispatch,
  updateUnlockedSponsoredDeals,
  setHoveredListingId,
  setServerRedirectPath,
};

const dataLoaderPromise = async ({
  store: { dispatch, getState },
  params: { eventId, listingId },
  location,
  asyncRedirect,
  appContext: { isMobile },
  lastRouteLocation,
}) => {
  const requestStartTime = Date.now();
  const listing = listingId && getListingById(getState(), listingId);
  const { pathname } = location;

  if (listing && isListingDetailsPage(pathname)) {
    dispatch(updateSeatMap({ listingId }));
    return { lastRouteLocation };
  }

  const preListingsPromises = [
    dispatch(fetchMetros()),
    dispatch(fetchDisclosures()),
    dispatch(fetchDeals()),
  ];

  if (!selectFullEventById(getState(), eventId)) {
    preListingsPromises.push(dispatch(fetchFullEventById(eventId)));
  }

  // ensure that the event exists before fetching listings, we need the event
  // category to set the default zoom level
  await Promise.all(preListingsPromises);

  // always fetch listings if no listingId (Event page), and ensure listing
  // exists if listingId is present
  if (!listingId || !listing) {
    /**
     * if zoom is present in query param, it means that user is in listing detail page
     * and we set zoom level using zoom in query param.
     * if user enters listings page, we initialize the zoom level with default values.
     * otherwise, we fetch listings using last used zoom level
     */
    const { zoom: zoomQueryParam } = location.query;
    let zoomLevel = lastZoomLevelSelector(getState());

    if (zoomQueryParam && !Number.isNaN(zoomQueryParam)) {
      zoomLevel = parseInt(zoomQueryParam, 10);
    } else if (!listingId) {
      if (isMobile) {
        zoomLevel = 5;
      } else {
        // revert to old default zoom level for NFL events on desktop only
        const isNoNFLAutozoomDesktop =
          selectIsNoNFLAutozoomDesktopExperiment(getState());
        const fullEvent = selectFullEventById(getState(), eventId);
        zoomLevel =
          isNoNFLAutozoomDesktop && fullEvent.category === CATEGORIES.NFL
            ? 9
            : 10;
      }
    }

    const preferredSeatCount =
      seatCountSelector(getState()) || DEFAULT_SEAT_COUNT;

    await Promise.all([
      dispatch(
        fetchListings({
          eventId,
          zoomLevel,
          isMobile,
          location,
          lastRoute: lastRouteLocation?.pathname,
          quantity: preferredSeatCount,
        })
      ).then((res) => {
        if (!res.available_lots.includes(preferredSeatCount)) {
          // if the requested quantity is unavailable we need to re-request
          // with one of the available lot sizes
          return dispatch(
            fetchListings({
              eventId,
              zoomLevel,
              isMobile,
              location,
              lastRoute: lastRouteLocation?.pathname,
              quantity: getSuggestedSeatCount(res.available_lots),
            })
          );
        }
      }),
      dispatch(updateLastZoomLevelDispatch(zoomLevel)),
    ]);
  }

  const fullEvent = selectFullEventById(getState(), eventId);

  if (listingId && !getListingById(getState(), listingId)) {
    asyncRedirect(`${fullEvent.getPath()}${location.search}`);
    throw new Error('no listing found');
  }

  if (fullEvent.isValid()) {
    const currentLocation = currentLocationSelector(getState());
    if (!currentLocation) {
      dispatch(updateCurrentLocation(fullEvent.venue.metro));
    }
  }

  dispatch(
    updateEventsPageData({
      eventPageRequestsStartTime: requestStartTime,
      eventPageRequestsFinishTime: Date.now(),
    })
  );
  return { lastRouteLocation };
};

export default withDataLoader(
  withAppContext(connect(mapStateToProps, mapDispatchToProps)(EventPage)),
  {
    promise: dataLoaderPromise,
    key: 'eventRoutes',
  }
);
