const FETCH_GLOBAL_CONTENT = 'FETCH_GLOBAL_CONTENT';
export const FETCH_GLOBAL_CONTENT_FAIL = 'FETCH_GLOBAL_CONTENT_FAIL';
export const FETCH_GLOBAL_CONTENT_SUCCESS = 'FETCH_GLOBAL_CONTENT_SUCCESS';
const FETCH_GLOBAL_HEADER = 'FETCH_GLOBAL_HEADER';
export const FETCH_GLOBAL_HEADER_FAIL = 'FETCH_GLOBAL_HEADER_FAIL';
export const FETCH_GLOBAL_HEADER_SUCCESS = 'FETCH_GLOBAL_HEADER_SUCCESS';

export const fetchGlobalBannerContent = () => (dispatch) =>
  dispatch({
    types: [
      FETCH_GLOBAL_CONTENT,
      FETCH_GLOBAL_CONTENT_SUCCESS,
      FETCH_GLOBAL_CONTENT_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('global', 'global-banner'),
  });

export const fetchGlobalHeaderTab = () => (dispatch) =>
  dispatch({
    types: [
      FETCH_GLOBAL_HEADER,
      FETCH_GLOBAL_HEADER_SUCCESS,
      FETCH_GLOBAL_HEADER_FAIL,
    ],
    cms: (cms) => cms.page.retrieve('*', 'promo-header-tabs'),
  });
