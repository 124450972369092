import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import {
  Logout as LogoutEvent,
  LOGOUT_PAGE_TYPES,
  useAnalyticsContext,
} from 'analytics';
import LogOutIcon from 'icons/LogOutIcon';
import { logout as logoutDispatch } from 'store/modules/user/actions';

import styles from './LogOutButton.module.scss';

const LogOutButton = ({ router, logout }) => {
  const analytics = useAnalyticsContext();

  const handleLogout = async () => {
    analytics.track(
      new LogoutEvent({ pageType: LOGOUT_PAGE_TYPES.MY_TICKETS })
    );
    await logout();
    router.push('/');
  };

  return (
    <button
      type="button"
      onClick={handleLogout}
      className={styles['log-out-button']}
    >
      <LogOutIcon width={18} height={18} />
      <span className={styles.text}>Log Out</span>
    </button>
  );
};

LogOutButton.propTypes = {
  router: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutDispatch()),
});

export default withRouter(connect(null, mapDispatchToProps)(LogOutButton));
