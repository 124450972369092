import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import FullEvent from 'models/FullEvent';
import Listing from 'models/Listing';
import { usePurchaseFlow } from 'routes/PurchaseFlowContext';

import { PURCHASE_STEPS } from './constants';
import {
  PurchaseSecure,
  PurchaseUserCollectAddress,
  PurchaseUserPhone,
  PurchaseUserTextMessageCollection,
  PurchaseUserTransfer,
  PurchaseUserZip,
} from './steps';

function Purchase({ location, listing, event, router }) {
  const {
    purchaseStep,
    purchaseType,
    handleSubmitPurchaseZip,
    handleSubmitTransferEmail,
    handleSubmitTransferPhone,
    handleSubmitUserPhone,
    handleSubmitUserDeliveryAddress,
    handleSubmitVerifyBillingAddress,
    isMLBInfoCollection,
  } = usePurchaseFlow();

  const listingPath = listing.getPath(event);

  useEffect(() => {
    if (!purchaseStep) {
      router.replace({
        pathname: listingPath + '/checkout',
        search: location.search,
      });
    }
  }, [purchaseStep, location, listingPath]);

  switch (purchaseStep) {
    // pre-purchase:
    case PURCHASE_STEPS.USER_ZIP: {
      return (
        <PurchaseUserZip onSubmit={handleSubmitPurchaseZip} listing={listing} />
      );
    }
    case PURCHASE_STEPS.USER_TEXT: {
      return (
        <PurchaseUserTextMessageCollection
          onSubmit={handleSubmitTransferPhone}
          listing={listing}
        />
      );
    }
    case PURCHASE_STEPS.USER_TRANSFER: {
      return (
        <PurchaseUserTransfer
          onSubmit={handleSubmitTransferEmail}
          isMLBInfoCollection={isMLBInfoCollection}
          listing={listing}
        />
      );
    }
    case PURCHASE_STEPS.SECURE: {
      return <PurchaseSecure listing={listing} />;
    }

    // post-purchase:
    case PURCHASE_STEPS.USER_PHONE: {
      return (
        <PurchaseUserPhone onSubmit={handleSubmitUserPhone} listing={listing} />
      );
    }
    case PURCHASE_STEPS.USER_VERIFY: {
      return (
        <PurchaseUserCollectAddress
          onSubmit={handleSubmitVerifyBillingAddress}
          purchaseType={purchaseType}
          listing={listing}
        />
      );
    }
    case PURCHASE_STEPS.USER_ADDRESS: {
      return (
        <PurchaseUserCollectAddress
          delivery
          onSubmit={handleSubmitUserDeliveryAddress}
          purchaseType={purchaseType}
          listing={listing}
        />
      );
    }

    // should not be reachable
    default: {
      return null;
    }
  }
}
Purchase.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  listing: PropTypes.instanceOf(Listing).isRequired,
  event: PropTypes.instanceOf(FullEvent).isRequired,
  router: PropTypes.object.isRequired,
};

export default Purchase;
