export interface FAQ {
  question: string;
  answer: string;
}

const FAQ_HEADING: string = 'Frequently Asked Questions';
const QUESTION_MASK: RegExp =
  /<(?:b|strong|span|h\d).*?>(.*?\?.*?)<\/(?:b|strong|span|h\d)>/i;
const ANSWER_MASK: RegExp = /<(?:p|div).*?>([\s\S]*?)<\/(?:p|div)>/i;

export const removeHTMLTags = (htmlString: string): string => {
  return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
};

export const findFAQSection = (sections: string[]): string | undefined =>
  sections.find((section) => section.includes(FAQ_HEADING));

export const extractFAQParts = (faqSection: string): string[] =>
  faqSection.split('<h3>');

export const extractSingleFAQ = (part: string): FAQ | null => {
  const question = part.match(QUESTION_MASK);
  const answer = part.match(ANSWER_MASK);
  if (!question || !answer) return null;
  return {
    question: question[1],
    answer: removeHTMLTags(answer[1]),
  };
};

export const hasFAQs = (htmlString: string): boolean =>
  htmlString.includes(FAQ_HEADING);

const extractFAQs = (htmlString: string): FAQ[] | null => {
  const sections = htmlString.split('<h2>');
  const faqSection = findFAQSection(sections);
  if (!faqSection) return null;

  const parts = extractFAQParts(faqSection);
  return parts.map(extractSingleFAQ).filter((faq): faq is FAQ => faq !== null);
};

export default extractFAQs;
