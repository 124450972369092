import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import { withDataLoader } from 'contexts/LoaderContext';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import {
  BTN_TYPES,
  Click,
  ClickTracker,
  DeepLinkClickTracker,
  PAYLOAD,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import { HOMEPAGE_BREADCRUMB_CONFIG } from 'components/Breadcrumbs/breadcrumb.constants';
import { generateBreadcrumbSchema } from 'components/Breadcrumbs/breadcrumb.helpers';
import DropdownButton from 'components/Buttons/DropdownButton';
import SimpleButton, {
  TYPES as BUTTON_TYPES,
  TYPES,
} from 'components/Buttons/SimpleButton';
import HomepageCollection from 'components/Collection/Collection.tsx';
import DeepLink from 'components/DeepLink/DeepLink';
import { DEEPLINK_CAMPAIGNS } from 'components/DeepLink/DeepLink.constants';
import GTFooter from 'components/Footers/GTFooter/GTFooter';
import GametimeGuarantee from 'components/GametimeGuarantee/GametimeGuarantee';
import GTGrid from 'components/GTGrid/GTGrid';
import HeadImage from 'components/Head/Image';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import HeroContainer from 'components/HeroContainer/HeroContainer';
import { downloadAppHeroData } from 'components/HeroContainer/HeroContainer.constants';
import JsonLD from 'components/JsonLD/JsonLD';
import MetroContainer from 'components/MetroPills/MetroContainer';
import MetroSelectorContainer from 'components/MetroSelector/MetroSelectorContainer';
import SearchHero from 'components/Search/SearchHero/SearchHero';
import { HEADER_TYPES } from 'components/SectionHeader/constants';
import MetroSelector from 'components/SelectorModals/MetroSelector/MetroSelector';
import { selectIsMarketShareCollectionV2Experiment } from 'experiments';
import ChevronIcon from 'icons/ChevronIcon';
import Collection from 'models/Collection';
import { HOMEPAGE_TITLE } from 'modules/pageTitles';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import { gametimePreviewImageUrl } from 'pages/constants';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';
import NotFound from 'pages/NotFound/NotFound';
import {
  currentLocationSelector,
  updateCurrentLocation,
} from 'store/modules/app/app';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import { showMobileHeroSearchBox } from 'store/modules/app/app.ui';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import {
  metroPageCollectionsSelector,
  selectHeroCarouselEvents,
} from 'store/modules/data/Collections/selectors';
import { fetchPerformersByCategoryGroup } from 'store/modules/data/Performers/actions';
import { fetchMetros } from 'store/modules/resources/resource.actions';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import { fetchCompleteUserPurchases } from 'store/modules/userPurchases/actions';
import { selectCompleteUserPurchasesStatus } from 'store/modules/userPurchases/userPurchases.selectors';
import colors from 'styles/colors.constants';
import { extendDefaultBranchState, generateBranchLink } from 'utils/branchLink';
import { REQUEST_STATUS } from 'utils/requestStatuses';

import homeFeatures from './home.features';

import styles from './Home.module.scss';

@withAppContext
@connect(
  (state, props) => {
    const currentMetro =
      selectUserMetro(state) ||
      selectClosestMetro(state, props.appContext.state.ipGeoLocation);

    const isMarketShareCollectionV2Experiment =
      selectIsMarketShareCollectionV2Experiment(state);

    const collectionDiscoverView = isMarketShareCollectionV2Experiment
      ? COLLECTION_VIEWS.WEB_DISCOVER_MARKETSHARE
      : COLLECTION_VIEWS.WEB_DISCOVER;

    const homeFilterKeys = {
      metro: currentMetro?.id,
      view: collectionDiscoverView,
    };

    const { collections } = metroPageCollectionsSelector(state, homeFilterKeys);
    // sent to Riskified to assist in fraud detection
    const { webSessionId } = appConfigSelector(state);

    return {
      collections,
      branchData: extendDefaultBranchState({
        state,
        campaign: DEEPLINK_CAMPAIGNS.HOME_PAGE,
        location: props.location,
      }),
      currentMetro,
      heroOptions: selectHeroCarouselEvents(state, homeFilterKeys),
      webSessionId,
    };
  },
  {
    showMobileHeroSearchBox,
  }
)
@TrackPageView(({ currentMetro, isInitialUserPurchase }) => {
  return {
    [TRACK.PAGE_TYPE]: View.PAGE_TYPES.HOMEPAGE(currentMetro?.id),
    payload: {
      [PAYLOAD.PROMO_ELIGIBLE]: isInitialUserPurchase,
    },
  };
})
@withClickContext(() => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.HOMEPAGE(),
}))
@withAnalyticsContext
class Home extends Component {
  static propTypes = {
    branchData: PropTypes.object.isRequired,
    collections: PropTypes.arrayOf(PropTypes.instanceOf(Collection)).isRequired,
    currentMetro: PropTypes.object,
    heroOptions: PropTypes.array,
    webSessionId: PropTypes.string,
    showMobileHeroSearchBox: PropTypes.func,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        isMobile: PropTypes.bool.isRequired,
        ipGeoLocation: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    analyticsContext: PropTypes.shape({
      track: PropTypes.func.isRequired,
    }),
    clickContext: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      branchHref: '#', // this needs to be truthy so that DeepLink doesn't call the branch api and generate a url 3 times
      openModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.guaranteeRef = React.createRef();
    this.gametimeShieldTracker = new ClickTracker().interaction(
      Click.INTERACTIONS.GT_SHIELD_BUTTON()
    );

    this.handleHeroSearchboxFocus = this.handleHeroSearchboxFocus.bind(this);
  }

  componentDidMount() {
    const branchCallback = (error, branchHref) => {
      if (error) {
        console.error(error);
        return;
      }
      this.setState({ branchHref });
    };

    generateBranchLink(this.props.branchData, branchCallback.bind(this));
  }

  shouldComponentUpdate(_, { branchHref, showModal, openModal }) {
    return (
      this.state.showModal !== showModal ||
      branchHref !== this.state.branchHref ||
      this.state.openModal !== openModal
    );
  }

  handleGTShieldClick = () => {
    this.guaranteeRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    const { clickContext } = this.props;

    this.props.analyticsContext.track(
      new Click(_merge({}, clickContext, this.gametimeShieldTracker.json()))
    );
  };

  handleHeroSearchboxFocus() {
    this.props.showMobileHeroSearchBox();
  }

  toggleModal() {
    const { openModal } = this.state;
    this.setState({ openModal: !openModal });
  }

  renderMeta() {
    return (
      <div>
        <HeadTitle
          title={HOMEPAGE_TITLE}
          helmetProps={{ titleTemplate: '%s' }}
        />
        <HeadImage src={gametimePreviewImageUrl} />
        <JsonLD json={generateBreadcrumbSchema([HOMEPAGE_BREADCRUMB_CONFIG])} />
      </div>
    );
  }

  renderCollections() {
    const { collections, currentMetro, heroOptions } = this.props;

    if (!collections?.length) {
      return null;
    }

    return (
      <>
        {collections.map((c, index) => {
          return (
            <div key={c.id}>
              <HomepageCollection
                collection={c}
                collectionTitle={c.title}
                currentMetro={currentMetro}
                sectionIndex={index + 1}
                lazyLoad={index !== 0}
                heroCarouselEvents={heroOptions}
                {...(c.isPopular() && { headerType: HEADER_TYPES.TITLE })}
              />
            </div>
          );
        })}
      </>
    );
  }

  renderGetAppButton(section) {
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    return (
      <DeepLink
        href={this.state.branchHref}
        preventLinkFetch
        campaign={DEEPLINK_CAMPAIGNS.HOME_PAGE}
        clickTracker={new DeepLinkClickTracker(isMobile).interaction(
          Click.INTERACTIONS.BUTTON(),
          {
            [PAYLOAD.TYPE]: BTN_TYPES.GET_APP,
            [PAYLOAD.SECTION]: section,
          }
        )}
        fireFacebookEvent
      >
        <SimpleButton
          text="download"
          type={TYPES.GREEN_SOLID}
          data-cy="home-download-app"
        />
      </DeepLink>
    );
  }

  renderFeatures() {
    return (
      <div className={styles['features-section']}>
        <div className={styles.features}>
          {homeFeatures.map((feature) => (
            <a
              href={feature.href}
              target="_blank"
              rel="noopener noreferrer"
              key={feature.name}
              className={classNames(
                styles['feature-image'],
                styles[feature.name]
              )}
            >
              <img loading="lazy" alt={feature.name} {...feature.imgProps} />
            </a>
          ))}
        </div>
      </div>
    );
  }

  renderHeroSection() {
    const {
      collections,
      currentMetro,
      heroOptions,
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    const collectionsIsEmpty = !collections?.length;
    const heroOptionsIsEmpty = !heroOptions?.length;

    if (collectionsIsEmpty) {
      return (
        <div className={styles['no-events']}>
          <p>No events found in </p>
          <div className={styles['metro-selector-button-holder']}>
            <DropdownButton
              text={currentMetro.name}
              type={BUTTON_TYPES.GREEN_SOLID}
              onClick={this.toggleModal}
              icon={
                <ChevronIcon
                  width="12"
                  height="12"
                  direction="right"
                  color={colors.white}
                />
              }
              clickTracker={new ClickTracker().interaction(
                Click.INTERACTIONS.CHANGE_LOCATION()
              )}
            />
          </div>
          <MetroSelectorContainer noEvents>
            <MetroSelector
              onHide={this.toggleModal}
              show={this.state.openModal}
              redirectToMetro
            />
          </MetroSelectorContainer>
        </div>
      );
    }

    return (
      <MetroContainer
        currentMetro={currentMetro}
        isMobile={isMobile}
        heroOptionsIsEmpty={heroOptionsIsEmpty}
      >
        <SearchHero
          currentMetro={currentMetro}
          handleHeroSearchboxFocus={this.handleHeroSearchboxFocus}
        />
      </MetroContainer>
    );
  }

  render() {
    const { currentMetro, webSessionId } = this.props;

    if (!currentMetro) {
      return <NotFound />;
    }

    return (
      <ContainerTemplate
        canShowGoogleAdbanner
        header={
          <MinimalHeader
            search
            showCategories
            showAccount
            showHamburger
            showGTShield
            webSessionId={webSessionId}
            handleGTShieldClick={this.handleGTShieldClick}
            isHomepage
            showGTMessage
          />
        }
        footer={<GTFooter />}
        className={styles['home-container']}
      >
        {this.renderMeta()}
        {this.renderHeroSection()}
        <GTGrid>{this.renderCollections()}</GTGrid>
        <GametimeGuarantee guaranteeRef={this.guaranteeRef} />
        <HeroContainer data={downloadAppHeroData}>
          <div>{this.renderGetAppButton('get-app-tile')}</div>
        </HeroContainer>
        {this.renderFeatures()}
      </ContainerTemplate>
    );
  }
}

export default withDataLoader(Home, {
  key: 'home',
  promise: async ({ store, appContext }) => {
    const { dispatch, getState } = store;
    await dispatch(fetchMetros());

    const state = getState();
    const metro =
      selectUserMetro(state) ||
      selectClosestMetro(state, appContext.ipGeoLocation);
    const currentLocation = currentLocationSelector(state);

    if (!currentLocation) {
      dispatch(updateCurrentLocation(metro.id));
    }

    const isMarketShareCollectionV2Experiment =
      selectIsMarketShareCollectionV2Experiment(state);

    const collectionDiscoverView = isMarketShareCollectionV2Experiment
      ? COLLECTION_VIEWS.WEB_DISCOVER_MARKETSHARE
      : COLLECTION_VIEWS.WEB_DISCOVER;

    const promises = [
      dispatch(
        fetchCollections({
          metro: metro.id,
          with_results: true,
          view: collectionDiscoverView,
        })
      ),
      dispatch(fetchPerformersByCategoryGroup(metro.id)),
    ];

    const user = selectUserDetails(state);
    const purchasesStatus = selectCompleteUserPurchasesStatus(state);

    if (user && purchasesStatus === REQUEST_STATUS.IDLE) {
      promises.push(
        dispatch(
          fetchCompleteUserPurchases({
            user_id: user.id,
            session_token: user.session_token,
          })
        )
      );
    }

    await Promise.all(promises); // all side effects are resolved
  },
});
