import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';

import {
  Click,
  FullEventClickTracker,
  NoEventsFound,
  PAYLOAD,
  useAnalyticsContext,
} from 'analytics';
import { EVENT_CELL_TYPES } from 'components/Event/EventCell/EventCell';
import EventContainer from 'components/Event/EventContainer';
import NoEventsCard from 'components/NoEventsCard/NoEventsCard';
import PaginationButton from 'components/PaginationButton/PaginationButton';
import Section from 'components/Section/Section';
import { FullEvent, Performer } from 'models';
import { getEventType } from 'modules/CategoryGroups/CategoryGroups.helpers';
import { getPerformerPriceButtonColors } from 'store/modules/performers/performers.helpers';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';

import { EVENT_TYPES } from './constants';
import EventsSectionHeader from './EventsSectionHeader';

import styles from './FullEventsSection.module.scss';

function FullEventsSection({
  event,
  fetchMoreEvents,
  getDefaultMetro,
  renderNoEvents = false,
  matchupPerformer,
  performer,
  paginationState,
  showUrgencyBadge,
  topEvents = {
    hotEvents: [],
    dealEvents: [],
  },
  showInlinePricing,
  customComponent,
  componentDisplayIndex,
}) {
  const analytics = useAnalyticsContext();
  const appContextState = useAppContext().state;
  const isMobile = appContextState.isMobile;
  const handleClickPaginationButton = () => fetchMoreEvents(event.eventType);
  const isEventsEmpty = !paginationState?.fullEvents.length;
  const currentMetro = getDefaultMetro(appContextState.ipGeoLocation);
  const isVenuePage = !performer;

  useEffect(() => {
    if (isEventsEmpty) {
      analytics.track(new NoEventsFound({ performer_name: performer?.name }));
    }
  }, [analytics, isEventsEmpty, performer]);

  // Keep showing No Events Card for Venue Page
  if (isEventsEmpty && isVenuePage) {
    if (!renderNoEvents) {
      return null;
    }

    return <NoEventsCard metro={currentMetro} eventGroup={event.eventGroup} />;
  }

  const displayPageButton =
    paginationState.more && (!matchupPerformer || !matchupPerformer.slug);
  const isNearSection = event.eventType === EVENT_TYPES.NEAR.eventType;
  const shouldShowSectionHeader = !isVenuePage && !isNearSection;

  const eventRows = customComponent
    ? paginationState.fullEvents.slice()
    : paginationState.fullEvents;

  eventRows.length > 0 &&
    customComponent &&
    eventRows.splice(componentDisplayIndex, 0, {
      key: 'customComponent',
      component: customComponent,
    });

  return (
    <Section
      className={styles.section}
      headline={event.headline}
      grayHeadline
      showHeadline={false}
    >
      {shouldShowSectionHeader && (
        <EventsSectionHeader
          performer={performer}
          isEventsEmpty={isEventsEmpty}
          renderNoEvents={renderNoEvents}
          eventType={getEventType(performer.categoryGroup)}
        />
      )}
      {eventRows.map((eventRowItem) => {
        if (eventRowItem.key === 'customComponent') {
          return (
            <div key={eventRowItem.key} className={styles['custom-container']}>
              {eventRowItem.component}
            </div>
          );
        }
        if (!eventRowItem.isValid()) {
          return null;
        }

        const isTopHotEvent = topEvents.hotEvents.includes(eventRowItem.id);
        const isTopDealEvent = topEvents.dealEvents.includes(eventRowItem.id);
        const badgeProps = eventRowItem.badgeProps({
          isTopHotEvent,
          isTopDealEvent,
        });

        const eventTracker = new FullEventClickTracker(eventRowItem)
          .interaction(Click.INTERACTIONS.TILE())
          .payload({
            [PAYLOAD.SECTION]: event.eventGroup,
            [PAYLOAD.EVENT_TAGS]:
              showUrgencyBadge && badgeProps ? badgeProps.payload : null,
          });

        return (
          <EventContainer
            key={`full-event-${eventRowItem.id}`}
            fullEvent={eventRowItem}
            clickTracker={eventTracker}
            type={isMobile ? EVENT_CELL_TYPES.MEDIUM : EVENT_CELL_TYPES.DEFAULT}
            priceButtonColors={
              !isMobile ? getPerformerPriceButtonColors(performer) : null
            }
            badgeProps={showUrgencyBadge ? badgeProps : null}
            showInlinePricing={showInlinePricing}
          />
        );
      })}
      {displayPageButton && (
        <div className={styles['pagination-button-container']}>
          <PaginationButton
            onClick={handleClickPaginationButton}
            loading={paginationState.isLoading}
            showSeparator
          />
        </div>
      )}
    </Section>
  );
}
FullEventsSection.propTypes = {
  event: PropTypes.object.isRequired,
  fetchMoreEvents: PropTypes.func.isRequired,
  getDefaultMetro: PropTypes.func.isRequired,
  renderNoEvents: PropTypes.bool,
  matchupPerformer: PropTypes.instanceOf(Performer),
  performer: PropTypes.instanceOf(Performer),
  showUrgencyBadge: PropTypes.bool,
  paginationState: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    more: PropTypes.bool.isRequired,
    fullEvents: PropTypes.arrayOf(PropTypes.instanceOf(FullEvent)).isRequired,
  }).isRequired,
  topEvents: PropTypes.shape({
    hotEvents: PropTypes.arrayOf(PropTypes.string),
    dealEvents: PropTypes.arrayOf(PropTypes.string),
  }),
  showInlinePricing: PropTypes.bool,
  customComponent: PropTypes.node,
  componentDisplayIndex: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    getDefaultMetro: (ipGeoLocation) =>
      selectUserMetro(state) || selectClosestMetro(state, ipGeoLocation),
  };
}

export default connect(mapStateToProps)(FullEventsSection);
