import React from 'react';
import PropTypes from 'prop-types';

import HeadRobots from 'components/Head/Robots';
import HeadTitle from 'components/Head/Title';
import MinimalHeader from 'components/Headers/MinimalHeader/MinimalHeader';
import ContainerTemplate from 'pages/Containers/ContainerTemplate/ContainerTemplate';

import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';

import styles from './ResetPassword.module.scss';

const ResetPassword = ({ location }) => {
  return (
    <ContainerTemplate header={<MinimalHeader />}>
      <HeadTitle title="Reset Password" />
      <HeadRobots noindex noarchive nofollow />
      <div className={styles['reset-password']}>
        <div className={styles['form-container']}>
          <h1 className={styles['form-title']}>
            Choose A New
            <br />
            Password
          </h1>
          <ResetPasswordForm
            userId={location.query.user_id ?? ''}
            tempToken={location.query.temp_token ?? ''}
          />
        </div>
      </div>
    </ContainerTemplate>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ResetPassword;
