import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import PropTypes from 'prop-types';

import {
  Click,
  getMetroClickTracker,
  MetroClickTracker,
  TRACK,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import GTAnimationModal from 'components/GTAnimationModal';
import Link from 'components/Link/Link';
import SelectorModalOptions from 'components/SelectorModals/SelectorModalOptions/SelectorModalOptions';
import LocationFillIcon from 'icons/LocationFillIcon';
import { updateCurrentLocation } from 'store/modules/app/app';
import { getMetroPerformersPathByMetro } from 'store/modules/resources/resource.paths';
import {
  selectAllMetros,
  selectClosestMetro,
} from 'store/modules/resources/resource.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';

import styles from './MetroSelector.module.scss';

@withAppContext
@connect(
  (state, props) => ({
    metros: selectAllMetros(state),
    closestMetro: selectClosestMetro(
      state,
      props.appContext.state.ipGeoLocation
    ),
  }),
  { updateUserPreference, updateCurrentLocation }
)
@withClickContext(() => ({
  [TRACK.INTERACTION]: Click.INTERACTIONS.CHANGE_LOCATION(),
}))
export default class MetroSelector extends Component {
  static propTypes = {
    currentMetro: PropTypes.object,
    metros: PropTypes.array,
    closestMetro: PropTypes.object,
    onMetroSelected: PropTypes.func,
    onHide: PropTypes.func,
    updateUserPreference: PropTypes.func,
    redirectToMetro: PropTypes.bool,
    updateCurrentLocation: PropTypes.func,
    appContext: PropTypes.shape({
      state: PropTypes.shape({
        ipGeoLocation: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(selectedMetro, event) {
    const { onHide, onMetroSelected, currentMetro } = this.props;

    // If metro has not changed, just close modal
    if (currentMetro && selectedMetro.id === currentMetro.id) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      return onHide();
    }

    onHide();

    // Any CB
    if (onMetroSelected) {
      onMetroSelected();
    }

    // update location and user preference with new metro
    if (selectedMetro) {
      this.props.updateCurrentLocation(selectedMetro.id);
      this.props.updateUserPreference({
        lastVisitedMetro: selectedMetro.id,
      });
    }
  }

  currentLocationRow() {
    const { closestMetro, redirectToMetro } = this.props;
    const path = redirectToMetro
      ? getMetroPerformersPathByMetro(closestMetro)
      : '';

    return (
      <div className={styles.currentLocation}>
        <Link
          to={path}
          className={styles.currentLocationButton}
          onClick={(event) => this.onSelect(closestMetro, event)}
          clickTracker={new MetroClickTracker(closestMetro)}
        >
          <span className={styles['location-title']}>Use My Location</span>
          <LocationFillIcon fill={colors.gametimeGreen} />
        </Link>
      </div>
    );
  }

  renderMetros() {
    const { metros, currentMetro, redirectToMetro } = this.props;

    const metroOptions = metros.map((metro) => ({
      ...metro,
      path: redirectToMetro ? getMetroPerformersPathByMetro(metro) : '',
    }));

    return (
      <SelectorModalOptions
        currentSelection={currentMetro}
        options={metroOptions}
        onSelect={this.onSelect}
        getClickTracker={getMetroClickTracker}
      />
    );
  }

  render() {
    return (
      <GTAnimationModal
        className="metro-selector"
        headerTitle="Location"
        data-cy="metro-selector"
        {...this.props}
      >
        {this.currentLocationRow()}
        <div
          className={styles['metro-selector-body']}
          data-cy="metro-selector-modal"
        >
          {this.renderMetros()}
        </div>
      </GTAnimationModal>
    );
  }
}
