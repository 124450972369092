import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  HostedFieldFieldOptions,
  HostedFieldsHostedFieldsCard,
} from 'braintree-web/hosted-fields';
import { Form, Formik } from 'formik';
import { Dispatch } from 'redux';
import * as Yup from 'yup';

import {
  BraintreeHostedFieldGroup,
  hostedFieldStyles,
} from 'components/BraintreeHostedFieldForm/BraintreeHostedFieldGroup';
import DarkThemedFormButtonLoader from 'components/DarkThemedComponents/DarkThemedFormButtonLoader';
import TextInput from 'components/Inputs/TextInput';
import {
  BraintreeBillingAddress,
  BraintreeTokenize,
  useBraintreeHostedFields,
} from 'helpers/Braintree';
import { CreditCardBackFillIcon, CreditCardsBarIcon, LockIcon } from 'icons';
import colors from 'styles/colors.constants';

import { useAddressAutocomplete } from './AddCardForm.utils';

import styles from './AddCardForm.module.scss';

const FormValidationSchema: Yup.ObjectSchema<BraintreeBillingAddress> =
  Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    streetAddress: Yup.string().required('Address is required'),
    extendedAddress: Yup.string(),
    locality: Yup.string().required('City is required'),
    region: Yup.string().required('State is required'),
    postalCode: Yup.string().required('Postal Code is required'),
  });

const initialValues: BraintreeBillingAddress = {
  firstName: '',
  lastName: '',
  streetAddress: '',
  extendedAddress: '',
  locality: '',
  region: '',
  postalCode: '',
};

function isBillingAddressPropertyName(
  propertyName?: string
): propertyName is keyof BraintreeBillingAddress {
  return typeof propertyName === 'string' && propertyName in initialValues;
}

const FIELD_ID = {
  NUMBER: 'add-card-modal-cc-number',
  CVV: 'add-card-modal-cvv',
  EXPIRATION_DATE: 'add-card-modal-expiration-date',
};

const hostedFieldsOptions: HostedFieldFieldOptions = {
  number: {
    selector: `#${FIELD_ID.NUMBER}`,
    placeholder: 'Card Number',
    maxCardLength: 16,
    supportedCardBrands: {
      visa: true,
      mastercard: true,
      'american-express': true,
      discover: true,
      jcb: false,
    },
  },
  cvv: {
    selector: `#${FIELD_ID.CVV}`,
    placeholder: 'CVV',
  },
  expirationDate: {
    selector: `#${FIELD_ID.EXPIRATION_DATE}`,
    placeholder: 'MM/YY',
  },
};

/**
 * Form for collecting credit card information including billing address
 */
function AddCardForm({
  onSubmit,
  dispatch,
}: {
  dispatch: Dispatch;
  onSubmit: (
    braintreeTokenize: BraintreeTokenize,
    billingAddress: BraintreeBillingAddress
  ) => Promise<void>;
}) {
  const [cardType, setCardType] = useState<
    HostedFieldsHostedFieldsCard['type'] | undefined
  >();

  const { createHostedFields, hostedFields, fieldErrors, fieldFocus } =
    useBraintreeHostedFields(dispatch);

  useEffect(() => {
    if (!hostedFields) {
      createHostedFields(hostedFieldsOptions, hostedFieldStyles).then(
        (hostedFieldsInstance) => {
          if (!hostedFieldsInstance) {
            return;
          }

          hostedFieldsInstance.on('cardTypeChange', (event) => {
            if (event.cards.length === 1) {
              setCardType(event.cards[0].type);
            } else {
              setCardType(undefined);
            }
          });

          hostedFieldsInstance.focus('number');
        }
      );
    }
  }, [createHostedFields]);

  return (
    <Formik<BraintreeBillingAddress>
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        if (!hostedFields) {
          return;
        }

        const isValid = hostedFields.validate();
        if (isValid) {
          await onSubmit(hostedFields.tokenize, values);
        }

        formik.setSubmitting(false);
      }}
      validate={(values) => {
        const errors: Partial<BraintreeBillingAddress> = {};

        // we need to validate both hosted fields and Formik-managed fields.
        // since they are separate validation mechanisms, we need to check both
        // independently.
        try {
          FormValidationSchema.validateSync(values, { abortEarly: false });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error) => {
              if (isBillingAddressPropertyName(error.path)) {
                errors[error.path] = error.message;
              }
            });
          }
        }

        hostedFields?.validate();

        return errors;
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} className={styles['form']}>
          <div className={styles['hosted-fields-group']}>
            <BraintreeHostedFieldGroup
              label="Credit Card Number"
              hint={
                <>
                  <LockIcon fill={colors.gray400} size={16} />
                  Secured by PayPal
                </>
              }
              fieldId={FIELD_ID.NUMBER}
              isFocused={fieldFocus.number}
              error={fieldErrors.number}
              endIcon={<CreditCardsBarIcon cardType={cardType} />}
            />
            <div className={styles.row}>
              <BraintreeHostedFieldGroup
                label="Expiration"
                fieldId={FIELD_ID.EXPIRATION_DATE}
                isFocused={fieldFocus.expirationDate}
                error={fieldErrors.expirationDate}
              />
              <BraintreeHostedFieldGroup
                label="CVV"
                fieldId={FIELD_ID.CVV}
                isFocused={fieldFocus.cvv}
                error={fieldErrors.cvv}
                endIcon={<CreditCardBackFillIcon />}
              />
            </div>
          </div>
          <div className={styles.row}>
            <TextInput
              id="first-name"
              name="firstName"
              label="First Name"
              placeholder="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              error={{
                hasError:
                  !!formik.errors.firstName && !!formik.touched.firstName,
                message: formik.errors.firstName || '',
              }}
            />
            <TextInput
              id="last-name"
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              error={{
                hasError: !!formik.errors.lastName && !!formik.touched.lastName,
                message: formik.errors.lastName || '',
              }}
            />
          </div>
          <TextInput
            id="streetAddress"
            name="streetAddress"
            label="Billing Street Address"
            placeholder="Address"
            ref={useAddressAutocomplete((address) => {
              formik.setValues((formValues) => ({
                ...formValues,
                ...address,
              }));
            })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            value={formik.values.streetAddress}
            error={{
              hasError:
                !!formik.errors.streetAddress && !!formik.touched.streetAddress,
              message: formik.errors.streetAddress || '',
            }}
          />
          <div className={styles.row}>
            <TextInput
              id="extendedAddress"
              name="extendedAddress"
              label="Apt/Unit (Optional)"
              placeholder="Apt/Unit"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              value={formik.values.extendedAddress}
              error={{
                hasError:
                  !!formik.errors.extendedAddress &&
                  !!formik.touched.extendedAddress,
                message: formik.errors.extendedAddress || '',
              }}
            />
            <TextInput
              id="locality"
              name="locality"
              label="City"
              placeholder="City"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              value={formik.values.locality}
              error={{
                hasError: !!formik.errors.locality && !!formik.touched.locality,
                message: formik.errors.locality || '',
              }}
            />
          </div>
          <div className={styles.row}>
            <TextInput
              id="region"
              name="region"
              label="State / Province"
              placeholder="State"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              value={formik.values.region}
              error={{
                hasError: !!formik.errors.region && !!formik.touched.region,
                message: formik.errors.region || '',
              }}
            />
            <TextInput
              id="postalCode"
              name="postalCode"
              label="Postal Code"
              placeholder="Postal Code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={formik.isSubmitting}
              value={formik.values.postalCode}
              error={{
                hasError:
                  !!formik.errors.postalCode && !!formik.touched.postalCode,
                message: formik.errors.postalCode || '',
              }}
            />
          </div>
          <div className={styles['submit-button']}>
            <DarkThemedFormButtonLoader
              type="submit"
              loading={false}
              text="CONFIRM"
              showBackground={false}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default connect()(AddCardForm);
