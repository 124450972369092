export const COLLECTION_TITLES = {
  POPULAR: 'popular',
  HOME_GAMES: 'home games',
  MUSIC: 'music',
  UNDER_50: 'events under $50',
  SUPERBOWL_LVIII: 'super bowl lviii',
};

export const COLLECTION_HEADINGS = {
  POPULAR_EVENTS: 'Popular Events',
  POPULAR_EVENTS_NEAR_YOU: 'Popular Events Near You',
  WEEKEND: 'This Weekend Events',
  UNDER_50: 'Events Under $50',
  TICKETS_FOR_EVENTS: 'Tickets for Events',
  THEATER: 'Cheap Theater Tickets',
  EVENTS: 'Events',
  SUPERBOWL_LVIII: 'Super Bowl LVIII',
  JUST_ANNOUNCED: 'Just Announced Events',
  GT_PICKS: 'Gametime Picks',
};

export const COLLECTION_SLUGS = {
  UNDER_50: 'under_50',
  THIS_WEEKEND: 'this_weekend',
  JUST_ANNOUNCED: 'just_announced',
  POPULAR: 'popular',
  PROMOTED: 'promoted',
  SUPERBOWL_LVIII: 'superbowl_lviii',
  VENUE_SCHEDULES: 'venue_schedules',
  RECENTLY_VIEWED: 'recently_viewed',
  POPULAR_MARKETSHARE: 'popular_marketshare',
};

export const COLLECTION_VIEWS = {
  MUSIC: 'music',
  MUSIC_WEB: 'music_web',
  THEATER: 'theater',
  THEATER_WEB: 'theater_web',
  WEB_DISCOVER: 'web_discover',
  WEB_PERFORMER: 'web_performer',
  WEB_LEAGUE: 'web_league',
  WEB_DISCOVER_MARKETSHARE: 'web_discover_marketshare',
  WEB_CATEGORY: 'web_category',
};

const COLLECTION_THEATER_SLUG = 'web_theater';
const COLLECTION_MUSIC_SLUG = 'web_music';
export const COLLECTION_SPORT_SLUG = 'web_sport';

export const COLLECTIONS_MUSIC_THEATER_SLUG = [
  COLLECTION_THEATER_SLUG,
  COLLECTION_MUSIC_SLUG,
];

export const COLLECTION_SLUG_TO_URL = {
  [COLLECTION_THEATER_SLUG]: '/c/theater',
  [COLLECTION_MUSIC_SLUG]: '/c/concert',
};

export const VISIBLE_COLLECTION_ITEMS = 20;
