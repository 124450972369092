import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import DealsTag from 'components/Deals/DealsTag';
import { useDragDetection } from 'hooks/useDragDetection';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent } from 'models';
import { addQuery } from 'utils/url';

import DealsSliderNavigation, { navigate } from './DealsSliderNavigation';

import styles from './DealsSlider.module.scss';

const settings = {
  infinite: false,
  variableWidth: true,
  arrows: false,
};

const DealsSlider = ({
  header,
  listingCards = [],
  listingModels = [], // clean up later MWEB-4009, don't need this for cell timer
  fullEvent,
  dealType = '',
  router,
  isCarouselListingLight = false,
}) => {
  const sliderRef = useRef();
  const isMobile = useMediaQuery(device.down.md);
  const { handleMouseDown, dragging } = useDragDetection();
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(listingCards.length === 1);

  const handleNavigation = (dir) => {
    if (!sliderRef?.current) return;
    if (dir === navigate.next) {
      return sliderRef.current.slickNext();
    }
    sliderRef.current.slickPrev();
  };

  const handleAfterChange = (slideIndex) => {
    const index = slideIndex + 1;
    const slidesTotal = listingCards.length;
    setDisableNext(index === slidesTotal);
    setDisablePrev(index === 1);
  };

  function handleChildClick(e, item) {
    if (dragging) {
      e.preventDefault();
      return;
    }

    if (isCarouselListingLight) {
      router.push(
        addQuery(item.listing.getPath(fullEvent), item.previousQuerySearch, {
          zoom: item.zoomLevel,
        })
      );
    } else {
      router.push(
        addQuery(
          item.children.props.listing.getPath(fullEvent),
          item.children.props.previousQuerySearch,
          {
            zoom: item.children.props.zoomLevel,
          }
        )
      );
    }
  }

  if (!listingCards.length) {
    return null;
  }

  return (
    <section
      className={styles['slider-container']}
      data-testid="slider-container"
    >
      <header className={styles['slider-header']}>
        <div className={styles['slider-header-info']}>
          <span className={styles['slider-header-name']}> {header} </span>
          {dealType && (
            <DealsTag
              listing={listingModels[0]}
              fullEvent={fullEvent}
              isListingPage={false}
              dealType={dealType}
              showIconOnly={false}
              showInfoTagOnly
            />
          )}
        </div>
        {!isMobile && (
          <DealsSliderNavigation
            onClick={handleNavigation}
            disabledPrev={disablePrev}
            disabledNext={disableNext}
          />
        )}
      </header>

      <Slider ref={sliderRef} {...settings} afterChange={handleAfterChange}>
        {listingCards.map((item) => (
          <div
            key={item.key}
            className={styles['slider-item']}
            onMouseDownCapture={handleMouseDown}
            onClickCapture={(e) => handleChildClick(e, item.props)}
          >
            {item}
          </div>
        ))}
      </Slider>
    </section>
  );
};

DealsSlider.propTypes = {
  header: PropTypes.any,
  listingCards: PropTypes.array,
  fullEvent: PropTypes.instanceOf(FullEvent),
  listingModels: PropTypes.array, // clean up later MWEB-4009, don't need this for cell timer
  dealType: PropTypes.string,
  router: PropTypes.object.isRequired,
  isCarouselListingLight: PropTypes.bool,
};

export default withRouter(DealsSlider);
