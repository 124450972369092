import React from 'react';
import { connect } from 'react-redux';

import { ClickTracker } from 'analytics';
import BulletList from 'components/BulletList/BulletList';
import Image from 'components/Image/Image';
import { EventJsonLD } from 'components/JsonLD/helpers/event/types';
import JsonLD from 'components/JsonLD/JsonLD';
import Link from 'components/Link/Link';
import MatchupImage from 'components/MatchupImage/MatchupImage';
import Tag from 'components/Tag/Tag';
import { selectIsMLBMatchupLogosExperiment } from 'experiments';
import FullEvent from 'models/FullEvent';
import PerformerInContext from 'models/PerformerInContext';
import { formatPriceWithComma } from 'utils/number';

import CollectionCardPromoBanner from './CollectionCardPromoBanner';

import styles from './CollectionCard.module.scss';

interface CollectionCardProps {
  source: FullEvent | PerformerInContext;
  clickTracker?: ClickTracker;
  toListingPage?: boolean;
  lazyLoad?: boolean;
  showPromoBanner?: boolean;
  isTopPick?: boolean;
  jsonLD?: EventJsonLD | null;
  isMLBMatchupLogosExperiment: boolean;
}

const CollectionCard = ({
  source,
  clickTracker,
  toListingPage,
  lazyLoad = true,
  showPromoBanner = false,
  isTopPick = false,
  jsonLD,
  isMLBMatchupLogosExperiment,
}: CollectionCardProps) => {
  let imageSrc = '';
  let imageLink = '';
  let title = '';
  let shortTitle = '';
  let subtitle = null;
  let price = 0;
  let tagElm = null;
  let altTextForImage = '';

  if (source instanceof FullEvent) {
    imageSrc = source.getPrimaryPerformer().heroImageUrl;
    imageLink = source.getPath();
    title = source.getName('@');
    shortTitle = source.getShortName('@');
    price = source.getPrice();

    subtitle = <BulletList listItems={source.dateTimeVenue} />;

    altTextForImage = source.getAltText();

    const tagProps = source.tagProps(3, isTopPick);
    if (tagProps) {
      tagElm = <Tag {...{ ...tagProps, position: 'top-left' }} />;
    }
  } else if (source instanceof PerformerInContext) {
    imageSrc = source.heroImageUrl();
    imageLink = source.getPath(toListingPage);
    title = source.nextFullEvent?.getName('@') || source.name();
    shortTitle = source.shortName();
    price = source.nextFullEvent.getPrice();
    altTextForImage = source.getAltText();

    subtitle = <BulletList listItems={source.nextFullEvent.dateTimeVenue} />;

    const tagProps = source.nextFullEvent.tagProps(3, isTopPick);

    if (tagProps) {
      tagElm = <Tag {...{ ...tagProps, position: 'top-left' }} />;
    }
  }

  const cta = !!price && (
    <span className={styles.price}>{`$${formatPriceWithComma(price)}`}</span>
  );

  const showMLBMatchupLogos =
    isMLBMatchupLogosExperiment &&
    source instanceof FullEvent &&
    source.hasMatchupLogos;

  return (
    <Link
      className={styles['collection-card']}
      to={imageLink}
      clickTracker={clickTracker}
      data-cy="collection-card-link"
    >
      <div className={styles['image-container']}>
        {showMLBMatchupLogos ? (
          <MatchupImage
            lazyLoad={lazyLoad}
            homePerformer={source.getPrimaryPerformer()}
            awayPerformer={source.getSecondaryPerformer()}
          />
        ) : (
          <Image src={imageSrc} alt={altTextForImage} lazyLoad={lazyLoad} />
        )}
        {showPromoBanner && source instanceof FullEvent && (
          <CollectionCardPromoBanner
            category={source.category}
            banner={source.banner}
          />
        )}
      </div>
      {tagElm}
      <div className={styles['body']}>
        <div className={styles['title-container']}>
          <div className={styles.title}>{title}</div>
          <div className={styles['short-title']}>{shortTitle}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        {cta}
      </div>
      {jsonLD && <JsonLD json={jsonLD} />}
    </Link>
  );
};

const mapStateToProps = (state: unknown) => {
  return {
    isMLBMatchupLogosExperiment: selectIsMLBMatchupLogosExperiment(state),
  };
};

export default connect(mapStateToProps)(CollectionCard);
