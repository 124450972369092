import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SeatDescription from 'components/SeatDescription/SeatDescription';

import styles from './PricingSummary.module.scss';

const PricingSummary = ({
  seatCount,
  section,
  rowDesc,
  showSeats,
  isGeneralAdmission,
}) => (
  <ul
    className={classNames(styles['seat-details'])}
    data-cy="pricing-summary-container"
  >
    <li className={styles['list-item']}>
      {'Sec '}
      {section}
    </li>
    <li className={styles['list-item']}>
      {'Row '}
      {rowDesc}
    </li>
    {showSeats && (
      <SeatDescription
        seatCount={seatCount}
        isGeneralAdmission={isGeneralAdmission}
      />
    )}
  </ul>
);

PricingSummary.defaultProps = {};

PricingSummary.propTypes = {
  seatCount: PropTypes.number.isRequired,
  section: PropTypes.string.isRequired,
  rowDesc: PropTypes.string.isRequired,
  showSeats: PropTypes.bool.isRequired,
  isGeneralAdmission: PropTypes.bool.isRequired,
};

export default PricingSummary;
