import { FAQ } from 'helpers/extractFAQs/extractFAQs';

import { FAQPageJsonLD } from './types';

const faqsToFAQPageJSONLD = (faqs: FAQ[]): FAQPageJsonLD => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq: FAQ) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  };
};

export default faqsToFAQPageJSONLD;
