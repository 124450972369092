import React from 'react';
import PropTypes from 'prop-types';

const SeatDescription = ({ seatCount, isGeneralAdmission }) => {
  const getDescriptionText = () => {
    if (isGeneralAdmission) {
      return seatCount > 1
        ? `${seatCount} Tickets Together`
        : `${seatCount} Ticket`;
    }

    return seatCount > 1 ? `${seatCount} Seats Together` : `${seatCount} Seat`;
  };

  return <li data-testid="seat-description">{getDescriptionText()}</li>;
};

SeatDescription.defaultProps = {};

SeatDescription.propTypes = {
  seatCount: PropTypes.number.isRequired,
  isGeneralAdmission: PropTypes.bool.isRequired,
};

export default SeatDescription;
