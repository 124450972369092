import React from 'react';

import { ArrowIcon } from 'icons';

import { NAVIGATION_SECTIONS } from '../../careers.constants';

import styles from './CareersHero.module.scss';

interface CareersHeroProps {
  onClick: (navSection: string) => void;
}

const CareersHero = ({ onClick }: CareersHeroProps) => {
  return (
    <div className={styles['careers-hero-container']}>
      <h1 className={styles.headline} data-cy="career-hero-title">
        Work At Gametime
      </h1>
      <h2 className={styles.subheadline}>Join our fully remote team!</h2>
      <button
        type="button"
        className={styles['jump-to-positions']}
        onClick={() => onClick(NAVIGATION_SECTIONS.openPositions)}
        data-cy="hiring-button"
      >
        <span className={styles['jump-to-positions-copy']}>We're Hiring</span>
        <ArrowIcon
          height="14"
          width="14"
          style={{
            transform: 'rotate(90deg)',
          }}
        />
      </button>
    </div>
  );
};

export default CareersHero;
