import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAppContext } from 'contexts/AppContext';
import { withGooglePay } from 'contexts/GooglePayContext';
import { withDataLoader } from 'contexts/LoaderContext';
import { withReCaptcha } from 'contexts/ReCaptchaContext';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  PAYMENT_METHOD_NAMES,
  PurchaseListing,
  TRACK,
  TrackPageView,
  View,
  withAnalyticsContext,
} from 'analytics';
import {
  AddCreditCardClickTracker,
  AffirmPaymentClickTracker,
  ApplePayPaymentClickTracker,
  PayPalPaymentClickTracker,
} from 'analytics/ClickTracker';
import { withClickContext } from 'analytics/context/ClickContext';
import { AffirmButton } from 'components/Affirm';
import ApplePayButton from 'components/ApplePayButton/ApplePayButton';
import BraintreeHostedFieldForm from 'components/BraintreeHostedFieldForm/BraintreeHostedFieldForm';
import { getDeliveryDisplayProps } from 'components/DeliveryFormat/delivery.format.constants';
import GooglePayButton from 'components/GooglePayButton/GooglePayButton';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import AddCardModal, {
  getAddCardTitle,
} from 'components/Modals/AddCardModal/AddCardModal';
import AffirmFormModal from 'components/Modals/AffirmFormModal/AffirmFormModal';
import EditCardModal from 'components/Modals/EditCardModal/EditCardModal';
import PromoCodeModal from 'components/Modals/PromoCodeModal/PromoCodeModal';
import VerifyCVVModal from 'components/Modals/VerifyCVVModal';
import { CREDIT_CARD_ERROR_NOTIFICATION } from 'components/Notifications/constants';
import { showNotification } from 'components/Notifications/Notifications';
import {
  DetailDeliveryCard,
  DetailEventCard,
  DetailListingCard,
  DetailPriceSummaryCard,
  DetailPromoCard,
  DetailVenueCard,
  FaceValueCard,
} from 'components/OrderDetail';
import { PayPalButton } from 'components/PayPal';
import ThemedButtonLoader from 'components/ThemedComponents/ThemedButtonLoader';
import {
  ACTIONS as T_ACTIONS,
  getTrackingProps,
} from 'components/Trackable/TrackingHelper';
import {
  selectIsBillingWebMVPExperiment,
  selectIsInsuranceWebPilotExperiment,
} from 'experiments';
import {
  selectEnabledInsurancePaymentMethods,
  selectIsMLBIntegrationEnabled,
} from 'featureFlags';
import { RE_CAPTCHA_ACTIONS } from 'helpers/Google/GoogleReCaptcha';
import CircleCheckFillIcon from 'icons/CircleCheckFillIcon';
import { FullEvent, Listing } from 'models';
import { getCurrencyPrefix, isDefaultAllInState } from 'pages/Event/helpers';
import { URGENCY_MESSAGING_THRESHOLD } from 'pages/Listing/constants';
import { withPurchaseFlow } from 'routes/PurchaseFlowContext';
import { deliveryFormatForListing } from 'store/datatypes/DELIVERY_FORMATS';
import { CARD_TYPES } from 'store/datatypes/PAYMENT_TYPES';
import { showAppSpinner } from 'store/modules/app/app';
import {
  lastZoomLevelSelector,
  setFormSubmitted,
  setFormSubmitting,
} from 'store/modules/app/app.ui';
import { selectFullEventById } from 'store/modules/data/FullEvents/selectors';
import { fetchListings } from 'store/modules/data/Listings/actions';
import { getListingById } from 'store/modules/data/Listings/selectors';
import { PURCHASE_TYPE } from 'store/modules/purchase/purchase.constants';
import {
  extendedPurchaseSelector,
  seatCountSelector,
} from 'store/modules/purchase/purchase.selectors';
import {
  addUserCreditCard as addUserCreditCardDispatch,
  deleteUserCreditCard,
} from 'store/modules/purchase/purchaseFlow';
import { fetchDisclosures } from 'store/modules/resources/resource.actions';
import {
  allDisclosuresSelector,
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import {
  fetchUserPromoCodesForListing,
  updateUser,
} from 'store/modules/user/actions';
import { getExternalAccountType } from 'store/modules/user/constants';
import {
  isUserLoggedIn,
  selectBestUserPromoForListing,
  selectUserDetails,
  userPromosForListingSavingsSelector,
} from 'store/modules/user/user.selectors';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import { updateUserDefaultPayment } from 'store/modules/userPurchases/actions';
import { processAffirmPayVcn } from 'store/modules/userPurchases/affirmPay/affirmPay';
import {
  fetchUserCards,
  fetchUserDeviceVerified,
  verifyCVV,
} from 'store/modules/userPurchases/creditCard/creditCard';
import { userPurchaseCreditCard } from 'store/modules/userPurchases/creditCard/creditCard.selectors';
import {
  defaultCardTokenSelector,
  selectApplePay,
} from 'store/modules/userPurchases/userPurchases.selectors';
import colors from 'styles/colors.constants';
import { isMLBEvent } from 'utils/mlb';

import CheckoutCard from './components/CheckoutCard';
import InsuranceOptions from './components/InsuranceOptions';
import MLBOptInSection from './components/MLBOptInSection';
import PaymentMethods from './components/PaymentMethods';
import PromoCodeSection from './components/PromoCodeSection';
import StickyPurchaseButtonGroup from './components/StickyPurchaseButtonGroup';
import UserEmailRow from './components/UserEmailRow';
import { CheckoutHookProvider } from './CheckoutHookProvider';
import InvalidCVVModal from './InvalidCVVModal';

import styles from './Checkout.module.scss';

const AUTO_DISMISS_TIMEOUT = 1e3; // Timeout for dimissing the promo code modal automatically
const BRAINTREE_ADD_CARD_FORM_ID = 'checkout-braintree-hosted-fields';

const propTypes = {
  user: PropTypes.object.isRequired,
  isUserLoggedIn: PropTypes.bool,
  listing: PropTypes.instanceOf(Listing),
  fullEvent: PropTypes.instanceOf(FullEvent),
  listingPath: PropTypes.string.isRequired,
  seatCount: PropTypes.number.isRequired,
  extendedPurchase: PropTypes.object.isRequired,
  fetchUserCards: PropTypes.func.isRequired,
  fetchUserPromoCodesForListing: PropTypes.func.isRequired,
  applePay: PropTypes.shape({
    statusChecked: PropTypes.bool.isRequired,
    available: PropTypes.bool.isRequired,
    applePayInstance: PropTypes.object,
  }).isRequired,
  googlePay: PropTypes.shape({
    statusChecked: PropTypes.bool,
    available: PropTypes.bool.isRequired,
    googlePaymentInstance: PropTypes.object,
    googlePaymentClient: PropTypes.object,
  }),
  eventCategory: PropTypes.string,
  params: PropTypes.object,
  creditCard: PropTypes.object.isRequired,
  updateUserDefaultPayment: PropTypes.func.isRequired,
  defaultCardToken: PropTypes.string,
  updateUserPreference: PropTypes.func,
  deliveryFormat: PropTypes.string.isRequired,
  deliveryDisplayProps: PropTypes.object.isRequired,
  allDisclosures: PropTypes.object,
  performer: PropTypes.object.isRequired,
  updateUser: PropTypes.func,
  listingPromoSavings: PropTypes.number,
  listingPromo: PropTypes.object,
  deleteUserCreditCard: PropTypes.func,
  addUserCreditCard: PropTypes.func,
  showAppSpinner: PropTypes.func,
  setFormSubmitted: PropTypes.func.isRequired,
  setFormSubmitting: PropTypes.func.isRequired,
  fetchUserDeviceVerified: PropTypes.func.isRequired,
  showUrgencyMessage: PropTypes.bool,
  maxLotSize: PropTypes.number,
  executeReCaptcha: PropTypes.func,
  reCaptchaReady: PropTypes.bool.isRequired,
  verifyCVV: PropTypes.func.isRequired,
  selectedMetro: PropTypes.string,
  location: PropTypes.object.isRequired,
  initPurchaseFlow: PropTypes.func.isRequired,
  shouldShowMLBOptInSection: PropTypes.bool,
  isInsuranceWebPilotExperiment: PropTypes.bool.isRequired,
  adjustedTotal: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  analyticsContext: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  clickContext: PropTypes.object,
  enabledInsurancePaymentMethods: PropTypes.shape({
    [PURCHASE_TYPE.APPLE_PAY]: PropTypes.bool.isRequired,
    [PURCHASE_TYPE.GOOGLE_PAY]: PropTypes.bool.isRequired,
    [PURCHASE_TYPE.AFFIRM_PAY]: PropTypes.bool.isRequired,
    [PURCHASE_TYPE.PAYPAL_PAY]: PropTypes.bool.isRequired,
    [PURCHASE_TYPE.CREDIT_CARD_ON_FILE]: PropTypes.bool.isRequired,
    [PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY]: PropTypes.bool.isRequired,
  }).isRequired,
  isBillingWebMVPExperiment: PropTypes.bool.isRequired,
};

@TrackPageView(
  ({
    fullEvent,
    listing,
    performer,
    shouldShowMLBOptInSection,
    seatCount,
    adjustedTotal,
  }) =>
    View.PAGE_TYPES.CHECKOUT({
      fullEvent,
      listing,
      performer,
      payload: {
        [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
        [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
        [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
        [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
        [PAYLOAD.MLB_MARKETING_OPT_IN_ELIGIBLE]: shouldShowMLBOptInSection,
        /**
         * quantity and post_fee_price for xcover insurance
         */
        [PAYLOAD.QUANTITY]: seatCount,
        [PAYLOAD.POST_FEE_PRICE]: adjustedTotal,
      },
    })
)
@withClickContext(({ listing }) => ({
  [TRACK.SOURCE_PAGE_TYPE]: Click.SOURCE_PAGE_TYPES.CHECKOUT(),
  payload: {
    [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
    [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
    [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
    [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
  },
}))
@withReCaptcha
@withPurchaseFlow
@withGooglePay
@withAnalyticsContext
class Checkout extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      isFetchingCards: !props.creditCard.cardsFetched && !!props.user.id,
      isAffirmModalOpen: false,
      isEditCardModalOpen: false,
      isPromoCodeModalOpen: false,
      isVerifyCVVModalOpen: false,
      isInvalidCVVModalOpen: false,
      invalidCVVAttemptNum: 1,
      selectedCardToken: this.props.defaultCardToken,
      selectedPurchaseType: null,
      isMLBMarketingOptedIn: false,
      isAddCardModalOpen: false,
    };

    this.toggleIsAffirmModalOpen = this.toggleIsAffirmModalOpen.bind(this);
    this.handleAffirmCheckout = this.handleAffirmCheckout.bind(this);
    this.handlePromoCodeModalLoading =
      this.handlePromoCodeModalLoading.bind(this);
    this.handlePromoCodeModalSuccess =
      this.handlePromoCodeModalSuccess.bind(this);
    this.initializeAffirmCheckout = this.initializeAffirmCheckout.bind(this);
    this.openAffirmCheckout = this.openAffirmCheckout.bind(this);
    this.updateEditModalStatus = this.updateEditModalStatus.bind(this);
    this.openPromoCodeModal = this.openPromoCodeModal.bind(this);
    this.closePromoCodeModal = this.closePromoCodeModal.bind(this);
    this.isApplePayAvailable = this.isApplePayAvailable.bind(this);
    this.isGooglePayAvailable = this.isGooglePayAvailable.bind(this);
    this.initiateCreditCardFlow = this.initiateCreditCardFlow.bind(this);
    this.closeVerifyCVVModal = this.closeVerifyCVVModal.bind(this);
    this.closeInvalidCVVModal = this.closeInvalidCVVModal.bind(this);
    this.handleAddCardSubmit = this.handleAddCardSubmit.bind(this);
    this.handleVerifyCVV = this.handleVerifyCVV.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.navigateBackToListing = this.navigateBackToListing.bind(this);
    this.onOptInChange = this.onOptInChange.bind(this);
    this.handleShowAddCardModal = this.handleShowAddCardModal.bind(this);
    this.handleAddNewCard = this.handleAddNewCard.bind(this);
  }

  componentDidMount() {
    const {
      creditCard: { cardsFetched },
      user,
    } = this.props;

    if (!cardsFetched && user.id) {
      this.fetchUserCards();
    }

    if (
      __CLIENT__ &&
      typeof window !== 'undefined' &&
      typeof window.fbq === 'function'
    ) {
      window.fbq('track', 'InitiateCheckout');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultCardToken !== prevProps.defaultCardToken) {
      this.setState({ selectedCardToken: this.props.defaultCardToken });
    }
    const {
      creditCard: { cardsFetched },
      user,
    } = this.props;

    if (!cardsFetched && !prevProps.user.id && user.id) {
      this.fetchUserCards();
    }
  }

  fetchUserCards() {
    this.setState({ isFetchingCards: true });
    this.props
      .fetchUserCards(this.props.user)
      .then(() => this.setState({ isFetchingCards: false }))
      .catch(() => this.setState({ isFetchingCards: false }));
  }

  getPaymentCard() {
    const { selectedCardToken } = this.state;
    const { creditCard } = this.props;
    return creditCard.cards[selectedCardToken];
  }

  /**
   * Handle common purchase flow steps.
   * @param {string} purchaseType —
   * @param {Function} resolvePurchaseOptions — Async callback to resolve
   *   payment method specific options (nonce, token, etc.) and insurance
   *   booking options.
   */
  handlePurchaseFlow(purchaseType, resolvePurchaseOptions) {
    const {
      initPurchaseFlow,
      defaultCardToken,
      seatCount,
      listing,
      analyticsContext,
    } = this.props;

    // In addition to when a user updates their seat count manually, we update this value
    // whenever a purchase is initiated, because there are cases where a user is making a purchase
    // with a new seatCount quantity (ex: landing on a listing that doesn't actually have
    // the user's original preferred seatCount quantity available)
    this.props.updateUserPreference({ seatCount });

    const { selectedCardToken, isMLBMarketingOptedIn } = this.state;
    if (selectedCardToken && defaultCardToken !== selectedCardToken) {
      this.props.updateUserDefaultPayment(selectedCardToken);
      analyticsContext.track(getTrackingProps(T_ACTIONS.UPDATE_DEFAULT_CARD));
    }

    analyticsContext.track(
      new PurchaseListing(
        getTrackingProps(T_ACTIONS.PURCHASE_STARTED, this.props, this.state, {
          payload: {
            [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
            [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
            [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
            [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
          },
        })
      )
    );

    initPurchaseFlow(
      purchaseType,
      resolvePurchaseOptions,
      isMLBMarketingOptedIn
    );
  }

  handleSelectPaymentMethod(paymentMethod) {
    const [purchaseType, cardToken] = paymentMethod.split(':');

    const clickTrackerByPurchaseType = {
      [PURCHASE_TYPE.AFFIRM_PAY]: new AffirmPaymentClickTracker(),
      [PURCHASE_TYPE.PAYPAL_PAY]: new PayPalPaymentClickTracker(),
      [PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY]: new AddCreditCardClickTracker(),
      [PURCHASE_TYPE.AFFIRM_PAY]: new AffirmPaymentClickTracker(),
      [PURCHASE_TYPE.APPLE_PAY]: new ApplePayPaymentClickTracker(),
    };

    const clickTracker = clickTrackerByPurchaseType[purchaseType];
    if (clickTracker) {
      const { clickContext } = this.props;
      this.props.analyticsContext.track(
        new Click(_merge({}, clickContext, clickTracker.json()))
      );
    }

    this.setState({
      selectedCardToken: cardToken,
      selectedPurchaseType: purchaseType,
    });
  }

  // Fires when the user clicks on the "Checkout with Affirm" button
  toggleIsAffirmModalOpen() {
    this.setState((prev) => ({
      isAffirmModalOpen: !prev.isAffirmModalOpen,
    }));
  }

  /**
   * Initializes the Affirm checkout object that is used to populate the
   * information in the Affirm checkout modal.
   */
  initializeAffirmCheckout(
    { firstName, lastName },
    // adjusted total in dollars
    adjustedTotalWithInsurance
  ) {
    const {
      extendedPurchase,
      listing,
      seatCount,
      performer,
      user,
      eventCategory,
    } = this.props;

    const sku = listing?.id || '';
    const unit_price = listing?.price?.total;
    const tax_amount = extendedPurchase?.salesTax * 100;
    const display_name = performer?.name || '';

    if (typeof window !== 'undefined' && window.affirm) {
      // see documentation for updating the structure of this object
      // https://docs.affirm.com/developers/v1.0-Global-Integration/reference/the-item-object
      // 'categories' should be an array of string[]'s or undefined
      const categories = eventCategory ? [[eventCategory]] : undefined;
      window.affirm.checkout({
        merchant: {
          use_vcn: true,
        },
        billing: {
          name: {
            first: firstName,
            last: lastName,
          },
          email: user.email,
        },
        items: [
          {
            display_name,
            sku,
            unit_price,
            qty: seatCount,
            categories,
          },
        ],
        currency: 'USD',
        shipping_amount: 0,
        tax_amount,
        total: adjustedTotalWithInsurance * 100, // convert to cents
      });
    }
  }

  /**
   * Opens Affirm's checkout modal where the user can enter their information
   * to apply for an Affirm loan. If the application is approved and processed
   * successfully, it returns a VCN to be processed.
   */
  openAffirmCheckout(insurance) {
    if (typeof window !== 'undefined' && window.affirm) {
      window.affirm.checkout.open_vcn({
        error() {
          console.error('User cancelled the Affirm checkout flow');
        },
        success: (vcn) => {
          const { showAppSpinner } = this.props;
          showAppSpinner(true);

          processAffirmPayVcn(vcn)
            .then((nonce) => {
              this.handlePurchaseFlow(PURCHASE_TYPE.AFFIRM_PAY, () => ({
                paymentOptions: {
                  nonce,
                  pay_later_type: 'affirm',
                },
                insuranceOptions: insurance.getPurchaseOptions(),
                insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
              }));
            })
            .catch((err) => console.error(err))
            .finally(() => showAppSpinner(false));
        },
      });
    }
  }

  /**
   * After the user clicks on the "Checkout with Affirm" button, the AffirmFormModal
   * appears where the user must enter their first and last name. When that form is
   * submitted, this function is called where the purchase data and name is populated
   * as a checkout object and sent to Affirm.
   */
  handleAffirmCheckout({ firstName, lastName }, insurance) {
    const { user } = this.props;

    // Add the first and last name to the user's data from the form
    this.props.updateUser({
      ...user,
      first_name: firstName,
      last_name: lastName,
    });

    // Set up the Affirm checkout object
    this.initializeAffirmCheckout(
      {
        firstName,
        lastName,
      },
      insurance.adjustedTotalWithInsurance
    );

    // Close the Affirm name collection modal
    this.setState({ isAffirmModalOpen: false });

    // Open Affirm's checkout modal
    this.openAffirmCheckout(insurance);
  }

  async handleAddCardSubmit(braintreeTokenize, insurance) {
    const { reCaptchaReady, executeReCaptcha, analyticsContext } = this.props;

    if (!reCaptchaReady) {
      throw new Error('reCaptcha not ready');
    }

    this.props.showAppSpinner(true);
    this.setState({ isSubmitting: true });

    const [tokenizeData, reCaptchaToken] = await Promise.all([
      braintreeTokenize(),
      executeReCaptcha(RE_CAPTCHA_ACTIONS.ADD_PAYMENT_METHOD),
    ]);

    await this.props
      .addUserCreditCard(tokenizeData.nonce, reCaptchaToken)
      .then((token) => {
        analyticsContext.track(
          getTrackingProps(T_ACTIONS.ADD_NEW_CARD_SUCCESS)
        );

        this.props.showAppSpinner(false);
        this.setState({ isSubmitting: false });
        this.initiateCreditCardFlow(token, insurance);
      })
      .catch(() => {
        analyticsContext.track(getTrackingProps(T_ACTIONS.ADD_NEW_CARD_ERROR));

        this.props.showAppSpinner(false);
        this.setState({ isSubmitting: false });
        showNotification(CREDIT_CARD_ERROR_NOTIFICATION);
      });
  }

  initiateCreditCardFlow(token, insurance) {
    this.handlePurchaseFlow(PURCHASE_TYPE.CREDIT_CARD_ON_FILE, () => ({
      paymentOptions: {
        token,
      },
      insuranceOptions: insurance.getPurchaseOptions(),
      insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
    }));
  }

  async handleVerifyCVV(tokenize, setFieldError, insurance) {
    this.props.showAppSpinner(true);
    const braintreeCVVNonce = await tokenize()
      .then((payload) => payload.nonce)
      .catch((tokenizeError) => {
        setFieldError('cvv', 'An unknown error occurred');
        console.error(
          new Error('Error tokenizing braintree hosted fields instance', {
            cause: tokenizeError,
          })
        );
      });

    if (braintreeCVVNonce) {
      const { user, analyticsContext, clickContext } = this.props;
      const { invalidCVVAttemptNum } = this.state;

      const paymentCard = this.getPaymentCard();
      if (!paymentCard) {
        console.warn('Payment card not found');
        return;
      }

      const tracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.CONFIRM())
        .sourcePageType(Click.SOURCE_PAGE_TYPES.CVV_VERIFICATION());

      await this.props
        .verifyCVV(user, paymentCard.object_id, braintreeCVVNonce)
        .then(() => {
          analyticsContext.track(
            new Click(
              _merge({ status: 'succeeded' }, clickContext, tracker.json())
            )
          );
          this.initiateCreditCardFlow(paymentCard.token, insurance);
        })
        .catch((cvvVerificationError) => {
          const HTTP_STATUS = {
            FORBIDDEN: 403,
            CONFLICT: 409,
            UNPROCESSABLE_ENTITY: 422,
          };

          switch (cvvVerificationError.status) {
            case HTTP_STATUS.CONFLICT: {
              // Device association already exists, treat as success
              this.initiateCreditCardFlow(paymentCard.token, insurance);
              break;
            }
            case HTTP_STATUS.UNPROCESSABLE_ENTITY: {
              // Non-matching CVV, try again
              setFieldError('cvv', 'Invalid CVV');
              analyticsContext.track(
                new Click(
                  _merge(
                    { status: 'failed', attempt: invalidCVVAttemptNum },
                    clickContext,
                    tracker.json()
                  )
                )
              );
              this.setState({
                invalidCVVAttemptNum: invalidCVVAttemptNum + 1,
              });
              break;
            }
            case HTTP_STATUS.FORBIDDEN: {
              // CVV verification failed 10x and payment method has been deleted
              tracker.targetPageType(Click.TARGET_PAGE_TYPES.CHECKOUT());
              analyticsContext.track(
                new Click(
                  _merge({ status: 'failed' }, clickContext, tracker.json())
                )
              );
              this.setState({
                isInvalidCVVModalOpen: true,
                isVerifyCVVModalOpen: false,
              });
              break;
            }
            default: {
              setFieldError('cvv', 'An unknown error occurred');
              console.error(
                new Error('Error verifying CVV', {
                  cause: cvvVerificationError,
                })
              );
              break;
            }
          }
        });
    }

    this.props.showAppSpinner(false);
  }

  closeVerifyCVVModal() {
    this.setState({
      isVerifyCVVModalOpen: false,
      isSubmitting: false,
    });
  }

  async closeInvalidCVVModal() {
    this.setState({
      isInvalidCVVModalOpen: false,
      isSubmitting: true,
    });
    this.props.showAppSpinner(true);

    await this.props.fetchUserCards(this.props.user, {
      force: true,
    });

    this.setState({ isSubmitting: false });
    this.props.showAppSpinner(false);
  }

  /**
   * Helper function to check if Google Pay is available as a payment option.
   *
   * @returns true if Google Pay is available for the user to use as a payment option.
   */
  isGooglePayAvailable() {
    const { googlePay } = this.props;

    if (!googlePay) {
      return false;
    }

    const { statusChecked, available } = googlePay;

    return statusChecked && available;
  }

  /**
   * Helper function to check if Apple Pay is available as a payment option.
   *
   * @returns true if Apple Pay is available for the user to use as a payment option.
   */
  isApplePayAvailable() {
    const {
      applePay: { available, statusChecked },
    } = this.props;

    return statusChecked && available;
  }

  renderApplePayButton(trackPurchaseButtonClick, insurance) {
    const {
      applePay: { applePayInstance },
      listingPath,
      analyticsContext,
    } = this.props;

    if (!this.isApplePayAvailable()) {
      return null;
    }

    const handleClickApplePayButton = (createApplePaySession) => {
      trackPurchaseButtonClick();

      let applePaySession = null;

      const promise = new Promise((resolve, reject) => {
        applePaySession = createApplePaySession({
          applePayInstance,
          amount: insurance.adjustedTotalWithInsurance,
          /**
           *
           * @param {Object} payment - Purchase request payment, refers to the
           *    `Payment` interface from Mobile API — note that both `nonce` and
           *    `token` are optional, but at least one must be provided.
           * @param {string} [payment.nonce]
           * @param {string} [payment.token]
           * @param {string} [payment.first_name]
           * @param {string} [payment.last_name]
           * @param {string} [payment.street_address]
           * @param {string} [payment.extended_address]
           * @param {string} [payment.locality]
           * @param {string} [payment.region]
           * @param {string} [payment.postal_code]
           */
          onPaymentAuthorized: (payment) => {
            resolve({
              paymentOptions: payment,
              insuranceOptions: insurance.getPurchaseOptions(),
              insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
            });
          },
          onError: (error) => {
            analyticsContext.track('APPLE_PAY_ERRORS', { error });
            reject(error);
          },
          onCancel: () => {
            console.warn('Apple pay session cancelled by user');
            this.props.router.push({
              pathname: `${listingPath}/checkout`,
              search: this.props.location.search,
            });
            // reject with an error to avoid moving forward in the
            // purchase flow
            reject(new Error('USER_CANCELLED'));
          },
        });
      });

      this.handlePurchaseFlow(PURCHASE_TYPE.APPLE_PAY, () => {
        applePaySession.begin();
        // Promise resolution value is defined above in `onPaymentAuthorized`
        return promise;
      });
    };

    return (
      <div className={styles['applepay-container']}>
        <ApplePayButton onClick={handleClickApplePayButton} />
      </div>
    );
  }

  renderGooglePayButton(trackPurchaseButtonClick, insurance) {
    const {
      googlePay: { googlePaymentInstance, googlePaymentClient },
      listingPath,
    } = this.props;

    if (!this.isGooglePayAvailable()) {
      return null;
    }

    const handleClickGooglePayButton = () => {
      trackPurchaseButtonClick();

      const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest(
        {
          transactionInfo: {
            currencyCode: 'USD',
            totalPriceStatus: 'FINAL',
            totalPrice: insurance.adjustedTotalWithInsurance.toString(),
          },
        }
      );

      return googlePaymentClient
        .loadPaymentData(paymentDataRequest)
        .then((response) => googlePaymentInstance.parseResponse(response))
        .then((parsedResponse) => {
          this.handlePurchaseFlow(PURCHASE_TYPE.GOOGLE_PAY, () => ({
            paymentOptions: {
              nonce: parsedResponse.nonce,
            },
            insuranceOptions: insurance.getPurchaseOptions(),
            insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
          }));
        })
        .catch((err) => {
          if (err.status === 'CANCELED') {
            console.warn('Google pay session cancelled by user');
            this.props.router.push({
              pathname: `${listingPath}/checkout`,
              search: this.props.location.search,
            });
          }

          console.error('Error during Google Pay: ', err);
          this.props.analyticsContext.track('GOOGLE_PAY_ERRORS');
        });
    };

    return <GooglePayButton onClick={handleClickGooglePayButton} />;
  }

  renderAddCardButton(trackPurchaseButtonClick) {
    const loading = this.state.isSubmitting || !this.props.reCaptchaReady;

    return (
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        type="submit"
        form={BRAINTREE_ADD_CARD_FORM_ID}
        loading={loading}
        disabled={loading}
        onClick={() => trackPurchaseButtonClick()}
      >
        <div className={styles['checkout-button-content']}>
          <CircleCheckFillIcon />
          <span className={styles['cta-desktop']}>Complete Checkout</span>
          <span className={styles['cta-mobile']}>Purchase</span>
        </div>
      </ThemedButtonLoader>
    );
  }

  renderAffirmButton(trackPurchaseButtonClick) {
    return (
      <AffirmButton
        disabled={this.state.isAffirmModalOpen}
        onClick={() => {
          trackPurchaseButtonClick((tracker) => {
            tracker.payload({ [PAYLOAD.TYPE]: T_ACTIONS.OPEN_AFFIRM_MODAL });
          });
          this.toggleIsAffirmModalOpen();
        }}
      />
    );
  }

  renderPayPalButton(trackPurchaseButtonClick, insurance) {
    const handlePaymentSuccess = (data) => {
      this.props.showAppSpinner(true);
      this.handlePurchaseFlow(PURCHASE_TYPE.PAYPAL_PAY, () => ({
        paymentOptions: {
          nonce: data.nonce,
        },
        insuranceOptions: insurance.getPurchaseOptions(),
        insuranceTracking: insurance.getPostPurchaseTrackingPayload(),
      }));
      this.props.showAppSpinner(false);
    };

    return (
      <PayPalButton
        total={insurance.adjustedTotalWithInsurance}
        onPaymentSuccess={handlePaymentSuccess}
        onClick={() => trackPurchaseButtonClick()}
      />
    );
  }

  renderPayWithCreditCardButton({
    trackPurchaseButtonClick,
    insurance,
    isDisabled,
  }) {
    const { isSubmitting, isFetchingCards } = this.state;

    const handleClick = async () => {
      const { user, analyticsContext } = this.props;

      const paymentCard = this.getPaymentCard();
      if (!paymentCard) {
        console.warn('Payment card not found');
        return;
      }

      this.props.showAppSpinner(true);
      this.setState({ isSubmitting: true });

      await this.props
        .fetchUserDeviceVerified(user, paymentCard.token)
        .then(() => {
          trackPurchaseButtonClick();
          this.initiateCreditCardFlow(paymentCard.token, insurance);
        })
        .catch((userDeviceVerifiedError) => {
          if (userDeviceVerifiedError.status === 404) {
            trackPurchaseButtonClick((tracker) => {
              tracker.targetPageType(
                Click.TARGET_PAGE_TYPES.CVV_VERIFICATION()
              );
            });
            this.setState({ isVerifyCVVModalOpen: true });
            analyticsContext.track(
              new View(View.PAGE_TYPES.CVV_VERIFICATION())
            );
          } else {
            console.error(
              new Error('Error verifying user device', {
                cause: userDeviceVerifiedError,
              })
            );
          }
        });

      this.props.showAppSpinner(false);
      this.setState({ isSubmitting: false });
    };

    const isLoading = isSubmitting || isFetchingCards;

    return (
      <ThemedButtonLoader
        backgroundColor={colors.gametimeGreen}
        color={colors.white}
        onClick={handleClick}
        loading={isLoading}
        disabled={isLoading || isDisabled}
      >
        <div className={styles['checkout-button-content']}>
          <CircleCheckFillIcon />
          <span>
            <span className={styles['cta-desktop']}>COMPLETE&nbsp;</span>
            PURCHASE
          </span>
        </div>
      </ThemedButtonLoader>
    );
  }

  renderPurchaseButton(purchaseType, insurance) {
    /**
     * Tracks purchase button clicks with the appropriate payment method and
     * insurance tracking payload. If a modifyTracker function is passed
     * in, it will be called with the ClickTracker instance to allow for
     * extending the click tracker.
     */
    const trackPurchaseButtonClick = (modifyTracker) => {
      const { clickContext } = this.props;
      const tracker = new ClickTracker()
        .interaction(Click.INTERACTIONS.PURCHASE_BUTTON())
        .payload(
          insurance.getPurchaseTrackingPayload(
            PAYMENT_METHOD_NAMES[purchaseType]
          )
        );

      if (typeof modifyTracker === 'function') {
        modifyTracker(tracker);
      }

      this.props.analyticsContext.track(
        new Click(_merge({}, clickContext, tracker.json()))
      );
    };

    switch (purchaseType) {
      case PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY: {
        // add card button submits Add Credit Card form, pass insurance
        // to handleAddCardSubmit from the form
        return this.renderAddCardButton(trackPurchaseButtonClick);
      }
      case PURCHASE_TYPE.APPLE_PAY: {
        return this.renderApplePayButton(trackPurchaseButtonClick, insurance);
      }
      case PURCHASE_TYPE.GOOGLE_PAY: {
        return this.renderGooglePayButton(trackPurchaseButtonClick, insurance);
      }
      case PURCHASE_TYPE.AFFIRM_PAY: {
        // affirm button opens affirm modal, pass insurance to
        // handleAffirmCheckout which is called from the modal
        return this.renderAffirmButton(trackPurchaseButtonClick);
      }
      case PURCHASE_TYPE.PAYPAL_PAY: {
        return this.renderPayPalButton(trackPurchaseButtonClick, insurance);
      }
      case PURCHASE_TYPE.CREDIT_CARD_ON_FILE:
      default: {
        return this.renderPayWithCreditCardButton({
          trackPurchaseButtonClick,
          insurance,
          isDisabled: !purchaseType,
        });
      }
    }
  }

  /**
   * Returns a payment method string that includes purchase type and card token
   * (if it exists). This should be the source of truth for payment method
   * selection and rendering the purchase button. If a card token is not
   * selected, the first card in the user's credit card list will be used
   * as the default payment method.
   *
   * If no cards are available, the default payment method will either be
   * undefined (if in billing address collection experiment) or will be manual
   * entry.
   */
  getSelectedPaymentMethod() {
    const { selectedPurchaseType } = this.state;

    if (
      selectedPurchaseType === PURCHASE_TYPE.AFFIRM_PAY ||
      selectedPurchaseType === PURCHASE_TYPE.PAYPAL_PAY
    ) {
      return selectedPurchaseType;
    }

    if (selectedPurchaseType === PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY) {
      // we shouldn't expect the selected purchase type to get set to manual
      // entry if in the billing address collection experiment, but just in
      // case, return undefined to prevent the user from proceeding
      return this.props.isBillingWebMVPExperiment
        ? undefined
        : selectedPurchaseType;
    }

    if (selectedPurchaseType === PURCHASE_TYPE.APPLE_PAY) {
      return `${PURCHASE_TYPE.APPLE_PAY}:${CARD_TYPES.APPLEPAY}`;
    }

    if (selectedPurchaseType === PURCHASE_TYPE.GOOGLE_PAY) {
      return `${PURCHASE_TYPE.GOOGLE_PAY}:${CARD_TYPES.GOOGLEPAY}`;
    }

    const { selectedCardToken } = this.state;
    const cardTokens = Object.keys(this.props.creditCard.cards);
    // selected card on file must have a card token that exists in the user's
    // credit card list, otherwise default to the first card in the list or
    // manual entry if no cards are available
    if (cardTokens.length > 0) {
      const cardToken = cardTokens.includes(selectedCardToken)
        ? selectedCardToken
        : cardTokens[0];
      return `${PURCHASE_TYPE.CREDIT_CARD_ON_FILE}:${cardToken}`;
    }

    // return undefined if in billing address collection experiment
    if (!this.props.isBillingWebMVPExperiment) {
      return PURCHASE_TYPE.CREDIT_CARD_MANUAL_ENTRY;
    }
  }

  updateEditModalStatus(modalStatus) {
    this.setState({ isEditCardModalOpen: modalStatus });
  }

  openPromoCodeModal() {
    this.setState({
      isPromoCodeModalOpen: true,
      promoCodeModalErrorMessage: '',
    });
  }

  closePromoCodeModal() {
    this.setState({
      isPromoCodeModalOpen: false,
      promoCodeModalErrorMessage: '',
    });
  }

  handlePromoCodeModalLoading(isLoading) {
    this.props.setFormSubmitting(isLoading);
    this.setState({
      promoCodeModalErrorMessage: '',
    });
  }

  /**
   * When the user has successfully submitted a promo code, fetch the promo
   * codes for the current listing before setting the form as completely
   * submitted and closing the modal.
   */
  async handlePromoCodeModalSuccess() {
    const {
      params: { eventId, listingId },
    } = this.props;

    this.props.setFormSubmitting(false);

    try {
      await this.props.fetchUserPromoCodesForListing({ eventId, listingId });

      this.props.setFormSubmitted(true);

      // Check to see if the promo code submitted can be applied to current order
      if (this.props.listingPromo) {
        setTimeout(() => {
          this.props.setFormSubmitted(false);
          this.closePromoCodeModal();
        }, AUTO_DISMISS_TIMEOUT);
      } else {
        this.props.setFormSubmitted(false);
        this.setState({
          promoCodeModalErrorMessage: 'Code cannot be used on this order.',
        });
      }
    } catch (err) {
      console.error(err);
      this.props.setFormSubmitted(false);
      this.closePromoCodeModal();
    }
  }

  navigateBackToListing() {
    this.props.router.goBack();
  }

  onOptInChange() {
    this.setState((prevState) => ({
      isMLBMarketingOptedIn: !prevState.isMLBMarketingOptedIn,
    }));
  }

  handleShowAddCardModal() {
    const { clickContext, analyticsContext } = this.props;
    const clickTracker = new AddCreditCardClickTracker();
    analyticsContext.track(
      new Click(_merge({}, clickContext, clickTracker.json()))
    );

    this.setState({ isAddCardModalOpen: true });
  }

  async handleAddNewCard(braintreeTokenize, billingAddress) {
    const { reCaptchaReady, executeReCaptcha, analyticsContext, clickContext } =
      this.props;

    if (!reCaptchaReady) {
      throw new Error('reCaptcha not ready');
    }

    this.props.showAppSpinner(true);
    this.setState({ isSubmitting: true });

    const [tokenizeData, reCaptchaToken] = await Promise.all([
      braintreeTokenize(billingAddress),
      executeReCaptcha(RE_CAPTCHA_ACTIONS.ADD_PAYMENT_METHOD),
    ]);

    await this.props
      .addUserCreditCard(tokenizeData.nonce, reCaptchaToken)
      .then((token) => {
        const clickTracker = new ClickTracker()
          .sourcePageType(Click.SOURCE_PAGE_TYPES.ADD_CREDIT_CARD_MANUAL())
          .targetPageType(Click.TARGET_PAGE_TYPES.CHECKOUT())
          .interaction(Click.INTERACTIONS.CONFIRM());

        analyticsContext.track(
          new Click(_merge({}, clickContext, clickTracker.json()))
        );

        this.props.showAppSpinner(false);
        this.setState({
          isSubmitting: false,
          selectedPurchaseType: PURCHASE_TYPE.CREDIT_CARD_ON_FILE,
          selectedCardToken: token,
        });
      })
      .catch(() => {
        analyticsContext.track(getTrackingProps(T_ACTIONS.ADD_NEW_CARD_ERROR));

        this.props.showAppSpinner(false);
        this.setState({ isSubmitting: false });
        showNotification(CREDIT_CARD_ERROR_NOTIFICATION);
      });

    this.setState({ isAddCardModalOpen: false });
  }

  render() {
    const {
      extendedPurchase: { seatFee, prefeeSeatPrice, salesTax },
      listing,
      fullEvent,
      seatCount,
      deliveryFormat,
      deliveryDisplayProps: { orderDetailTitle },
      allDisclosures,
      listingPromo,
      listingPromoSavings,
      user,
      isUserLoggedIn,
      showUrgencyMessage,
      maxLotSize,
      selectedMetro,
      creditCard,
      performer,
      shouldShowMLBOptInSection,
      isInsuranceWebPilotExperiment,
      adjustedTotal,
      analyticsContext,
      enabledInsurancePaymentMethods,
      isBillingWebMVPExperiment,
    } = this.props;

    const {
      isAffirmModalOpen,
      isPromoCodeModalOpen,
      isEditCardModalOpen,
      promoCodeModalErrorMessage,
      isFetchingCards,
      isMLBMarketingOptedIn,
    } = this.state;

    const paymentCard = this.getPaymentCard();

    const currencyPrefix = getCurrencyPrefix(fullEvent, selectedMetro);

    const promoCode = listingPromo?.promo_details?.code;
    const promoTitle = listingPromo?.promo_details?.title;
    const promoDescription = listingPromo?.promo_details?.customer_description;

    const userEmail = isUserLoggedIn ? user.email : '';
    const showRegulatoryAllInPricing = isDefaultAllInState(
      fullEvent.venueState
    );

    const selectedPaymentMethod = this.getSelectedPaymentMethod();
    const selectedPurchaseType = selectedPaymentMethod?.split(':')[0];

    const openEditCardModalButton =
      Object.keys(creditCard.cards).length === 0 ? null : (
        <button
          onClick={() => this.updateEditModalStatus(true)}
          className={styles['edit-card-modal-button']}
        >
          Edit
        </button>
      );

    return (
      <CheckoutHookProvider
        useInsuranceOptions={{
          adjustedTotal,
          isEnabled: isInsuranceWebPilotExperiment,
          eventId: fullEvent.id,
          seatCount,
          authUser: user,
          updateUser: this.props.updateUser,
          location: this.props.location,
          selectedPurchaseType,
          eventDatetimeUtc: fullEvent.event.datetimeUtc,
          analytics: analyticsContext,
          listingId: listing.id,
          performerId: performer.id,
          enabledInsurancePaymentMethods,
        }}
      >
        {({ insurance }) => (
          <div className={styles['checkout-container']}>
            <InfoHeader
              bold
              headerContent="Checkout"
              isDarkTheme
              showBack
              onBack={this.navigateBackToListing}
            />
            {/*
              the following wrapper element needs to be a div for StickyPurchaseButton
              to remove the box shadow when the user scrolls to the bottom of the page
            */}
            <div>
              <CheckoutCard
                title="Payment Method"
                headerButton={openEditCardModalButton}
                body={
                  <PaymentMethods
                    isLoading={isFetchingCards}
                    creditCards={Object.values(creditCard.cards)}
                    isApplePayAvailable={this.isApplePayAvailable()}
                    isGooglePayAvailable={this.isGooglePayAvailable()}
                    selectedPaymentMethod={selectedPaymentMethod}
                    onSelectPaymentMethod={this.handleSelectPaymentMethod}
                    adjustedTotal={adjustedTotal}
                    addCardForm={
                      <BraintreeHostedFieldForm
                        formId={BRAINTREE_ADD_CARD_FORM_ID}
                        onSubmit={(braintreeTokenize) =>
                          this.handleAddCardSubmit(braintreeTokenize, insurance)
                        }
                        isDisabled={!isUserLoggedIn}
                      />
                    }
                    isBillingWebMVPExperiment={isBillingWebMVPExperiment}
                    handleShowAddCardModal={this.handleShowAddCardModal}
                    addCardTitle={getAddCardTitle(
                      Object.keys(this.props.creditCard.cards).length > 0
                    )}
                  />
                }
              />
              {insurance.isEnabled &&
              (enabledInsurancePaymentMethods[selectedPurchaseType] ||
                !selectedPurchaseType) ? (
                <InsuranceOptions insurance={insurance} user={user} />
              ) : null}
              <CheckoutCard
                noPadding
                title="Your Order"
                body={
                  <>
                    <DetailEventCard
                      uniformPadding
                      fullEvent={fullEvent}
                      viewUrl={listing.getImageOptions(fullEvent.venue).src}
                      showUrgencyMessage={showUrgencyMessage}
                      urgencyQuantity={maxLotSize}
                    />
                    <DetailVenueCard
                      uniformPadding
                      fullEvent={fullEvent}
                      svgFill={colors.white}
                    />
                    <DetailListingCard
                      uniformPadding
                      sectionGroup={listing.sectionGroup}
                      section={listing.section}
                      row={listing.row}
                      disclosures={listing.disclosures}
                      allDisclosures={allDisclosures}
                      seatCount={seatCount}
                      svgFill={colors.white}
                    />
                    <DetailDeliveryCard
                      uniformPadding
                      deliveryFormat={deliveryFormat}
                      title={orderDetailTitle}
                      deliveryIconProps={{ fill: colors.white }}
                    />
                    {showRegulatoryAllInPricing && (
                      <FaceValueCard
                        faceValue={listing.getFaceValue()}
                        currencyPrefix={currencyPrefix}
                      />
                    )}
                    {listingPromo ? (
                      <DetailPromoCard
                        code={promoTitle || promoCode}
                        title={promoDescription}
                      />
                    ) : (
                      <PromoCodeSection
                        openPromoCodeModal={this.openPromoCodeModal}
                        isDisabled={!isUserLoggedIn}
                      />
                    )}
                    {isUserLoggedIn && (
                      <DetailPriceSummaryCard
                        isCheckout
                        prefeeSeatPrice={prefeeSeatPrice}
                        seatFee={seatFee}
                        seatCount={seatCount}
                        salesTax={salesTax}
                        totalPrice={adjustedTotal}
                        promoAmount={listingPromoSavings}
                        currencyPrefix={currencyPrefix}
                        insurancePrice={
                          insurance?.isBookingInsurance && insurance.quote
                            ? insurance.quote?.totalPrice
                            : undefined
                        }
                      />
                    )}
                  </>
                }
              />
              {shouldShowMLBOptInSection && (
                <MLBOptInSection
                  isOptedIn={isMLBMarketingOptedIn}
                  performerName={performer.name}
                  onOptInChange={this.onOptInChange}
                  listing={listing}
                />
              )}
              <UserEmailRow userEmail={userEmail} />
              <StickyPurchaseButtonGroup
                totalPrice={adjustedTotal}
                purchaseButton={this.renderPurchaseButton(
                  selectedPurchaseType,
                  insurance
                )}
                currencyPrefix={currencyPrefix}
                insurance={insurance}
              />
            </div>
            <AffirmFormModal
              isOpen={isAffirmModalOpen}
              onClose={this.toggleIsAffirmModalOpen}
              onSubmit={(formData) =>
                this.handleAffirmCheckout(formData, insurance)
              }
            />
            <PromoCodeModal
              isOpen={isPromoCodeModalOpen}
              onLoading={this.handlePromoCodeModalLoading}
              onSuccess={this.handlePromoCodeModalSuccess}
              onClose={this.closePromoCodeModal}
              errorMessage={promoCodeModalErrorMessage}
            />
            <AddCardModal
              isOpen={this.state.isAddCardModalOpen}
              onClose={() => this.setState({ isAddCardModalOpen: false })}
              onSubmit={this.handleAddNewCard}
              title={getAddCardTitle(
                Object.keys(this.props.creditCard.cards).length > 0
              )}
            />
            <EditCardModal
              cards={this.props.creditCard.cards}
              isOpen={isEditCardModalOpen}
              onClose={() => {
                this.updateEditModalStatus(false);
              }}
              deleteCard={this.props.deleteUserCreditCard}
              clickTracker={new ClickTracker().interaction(
                Click.INTERACTIONS.BUTTON(),
                { [PAYLOAD.TYPE]: T_ACTIONS.DELETE_CARD }
              )}
            />
            {paymentCard && (
              <VerifyCVVModal
                isOpen={this.state.isVerifyCVVModalOpen}
                onClose={this.closeVerifyCVVModal}
                card={paymentCard}
                onSubmitVerifyCVVForm={(tokenize, setFieldError) =>
                  this.handleVerifyCVV(tokenize, setFieldError, insurance)
                }
              />
            )}
            <InvalidCVVModal
              isOpen={this.state.isInvalidCVVModalOpen}
              onClose={this.closeInvalidCVVModal}
            />
          </div>
        )}
      </CheckoutHookProvider>
    );
  }
}

const mapStateToProps = (state, props) => {
  // allDisclosures is a dictionary of every disclosure we support so that we can enrich the disclosures coming with listings
  const allDisclosures = allDisclosuresSelector(state);

  const {
    listing,
    params: { listingId },
    appContext,
  } = props;

  const fullEvent = selectFullEventById(state, listing.eventId);
  const performer = fullEvent.getPrimaryPerformer();
  const listingPath = listing.getPath(fullEvent, listingId);
  const creditCard = userPurchaseCreditCard(state);
  const applePay = selectApplePay(state);
  const defaultCardToken = defaultCardTokenSelector(state);
  const user = selectUserDetails(state);
  const deliveryFormat = deliveryFormatForListing(listing);
  const deliveryDisplayProps = getDeliveryDisplayProps(deliveryFormat);
  const listingPromoSavings = userPromosForListingSavingsSelector(state);

  const maxLotSize = listing.getMaxLotSize();
  // Only show urgency messaging if the maximum available lot size is less than or equal to threshold
  const showUrgencyMessage =
    maxLotSize > 0 && maxLotSize <= URGENCY_MESSAGING_THRESHOLD;

  let selectedMetro = (
    selectUserMetro(state) ||
    selectClosestMetro(state, appContext.state.ipGeoLocation)
  )?.name;

  const seatCount = seatCountSelector(state);

  const extendedPurchase = listing && extendedPurchaseSelector(state, props);

  const isMLBIntegrationEnabled = selectIsMLBIntegrationEnabled(state);
  const shouldShowMLBOptInSection =
    isMLBIntegrationEnabled &&
    isMLBEvent(fullEvent) &&
    !!getExternalAccountType(listing.ticketType);

  const adjustedTotal = extendedPurchase.totalPrice - listingPromoSavings;

  return {
    listing,
    fullEvent,
    performer,
    listingPath,
    seatCount,
    extendedPurchase,
    creditCard,
    applePay,
    defaultCardToken,
    user,
    isUserLoggedIn: isUserLoggedIn(state),
    deliveryFormat,
    deliveryDisplayProps,
    allDisclosures,
    listingPromoSavings,
    listingPromo: selectBestUserPromoForListing(state),
    showUrgencyMessage,
    maxLotSize,
    selectedMetro,
    shouldShowMLBOptInSection,
    eventCategory: fullEvent.category,
    isInsuranceWebPilotExperiment: selectIsInsuranceWebPilotExperiment(state),
    adjustedTotal,
    enabledInsurancePaymentMethods: selectEnabledInsurancePaymentMethods(state),
    isBillingWebMVPExperiment: selectIsBillingWebMVPExperiment(state),
  };
};

const mapDispatchToProps = {
  fetchUserCards,
  fetchUserPromoCodesForListing,
  updateUser,
  updateUserPreference,
  updateUserDefaultPayment,
  addUserCreditCard: addUserCreditCardDispatch,
  deleteUserCreditCard,
  showAppSpinner,
  setFormSubmitted,
  setFormSubmitting,
  fetchUserDeviceVerified,
  verifyCVV,
};

const dataLoaderPromise = async ({
  store: { dispatch, getState },
  params: { eventId, listingId },
  appContext: { isMobile },
  location,
  lastRouteLocation,
}) => {
  const state = getState();
  const { zoom: zoomQueryParam } = location.query;
  let zoomLevel = lastZoomLevelSelector(state);

  if (zoomQueryParam && !Number.isNaN(zoomQueryParam)) {
    zoomLevel = parseInt(zoomQueryParam, 10);
  }

  const listing = getListingById(state, listingId);

  return Promise.allSettled([
    dispatch(fetchDisclosures()),
    !listing
      ? dispatch(
          fetchListings({
            eventId,
            zoomLevel,
            isMobile,
            location,
            lastRoute: lastRouteLocation?.pathname,
          })
        ).then(() =>
          dispatch(fetchUserPromoCodesForListing({ eventId, listingId }))
        )
      : dispatch(fetchUserPromoCodesForListing({ eventId, listingId })),
  ]);
};

export default withDataLoader(
  withAppContext(connect(mapStateToProps, mapDispatchToProps)(Checkout)),
  {
    promise: dataLoaderPromise,
  }
);
