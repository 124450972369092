import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SponsorDealBadge from 'ui/SponsorDealBadge/SponsorDealBadge';

import { Click, ListingClickTracker, PAYLOAD } from 'analytics';
import DealsTag from 'components/Deals/DealsTag';
import { dealTypes } from 'components/Deals/helpers';
import FeaturedListingsTag from 'components/FeaturedListingsTag/FeaturedListingsTag';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import MLBBadge from 'components/MLBBadge/MLBBadge';
import Disclosures from 'components/Modals/DisclosuresModal/Disclosures';
import TicketPrice from 'components/TicketPrice/TicketPrice';
import ZoneTag from 'components/ZoneTag/ZoneTag';
import { selectIsIncludesFeesCopyExperiment } from 'experiments';
import { selectShowTicketSourceEnabled } from 'featureFlags';
import { device, useMediaQuery } from 'hooks/useMediaQuery';
import { FullEvent } from 'models';
import Listing, { ZONE_TICKET_DISCLOSURE } from 'models/Listing';
import { isAllInPriceSelector } from 'store/modules/app/app.ui';
import { isMLBEvent } from 'utils/mlb';
import { addQuery } from 'utils/url';

import PriceMarkdownTag from './PriceMarkdownTag';
import PrimaryPrice from './PrimaryPrice';

import styles from './ListingCard.module.scss';

const propTypes = {
  isZoneDeal: PropTypes.bool,
  isFlashDeal: PropTypes.bool,
  listing: PropTypes.instanceOf(Listing),
  fullEvent: PropTypes.instanceOf(FullEvent),
  onHover: PropTypes.func,
  listingIndex: PropTypes.number,
  allDisclosures: PropTypes.object,
  allDeals: PropTypes.object,
  seatCount: PropTypes.number,
  zoomLevel: PropTypes.number,
  showTicketSource: PropTypes.bool,
  isAllInPriceActive: PropTypes.bool,
  location: PropTypes.object.isRequired,
  isCarouselItem: PropTypes.bool,
  isIncludesFeesCopyExperiment: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isFocusedCard: PropTypes.bool,
  isExclusivesV1: PropTypes.bool,
  isGalleryView: PropTypes.bool,
};

const ListingCard = ({
  listing,
  fullEvent,
  onHover,
  listingIndex,
  isZoneDeal = false,
  isFlashDeal = false,
  allDisclosures,
  allDeals,
  seatCount,
  zoomLevel,
  showTicketSource,
  isAllInPriceActive = false,
  location,
  isCarouselItem = false,
  isIncludesFeesCopyExperiment,
  className,
  isFocusedCard = false,
  isExclusivesV1,
  isGalleryView,
}) => {
  const dealType = listing.getLotDealForQuantity(seatCount);
  const isMobile = useMediaQuery(device.down.sm);
  const deal = dealType ? allDeals[dealType] : undefined;
  // Zone and Flash deals are not considered featured, they are sponsored
  const isFeaturedListing = deal !== undefined && !(isZoneDeal || isFlashDeal);
  const showFeaturedDealTag = isFeaturedListing && !isExclusivesV1;
  const urlWithParams = addQuery(listing.getPath(fullEvent), location.search, {
    zoom: zoomLevel,
  });

  const isHighPrice = () => {
    const maxNumberDigits = 3;
    if (isAllInPriceActive) {
      return listing.getTotalPrice().toString().length > maxNumberDigits;
    }
    return listing.getPrice().toString().length > maxNumberDigits;
  };

  const showMLBBadge = isMLBEvent(fullEvent);
  const isSectionBadge = isMobile || isCarouselItem;
  const isWidePriceLabel =
    isHighPrice() && listing.isDiscounted() && isCarouselItem;

  const showSponsoredDealBadge = !!(isExclusivesV1 && dealType);
  const showSponsoredDealBadgeLeft =
    showSponsoredDealBadge && (isCarouselItem || isGalleryView);
  const showSponsoredDealBadgeRight =
    showSponsoredDealBadge && !(isCarouselItem || isGalleryView);

  return (
    <div
      key={listing.id}
      className={classNames(styles['listing-card-container'], className, {
        [styles['carousel-listing-card']]: isCarouselItem,
        [styles['exclusivesV1']]: isExclusivesV1,
        [styles['super-deal']]: dealType === 'super',
      })}
      data-cy={`listing-card-${listingIndex}`}
      data-testid={`listing-card-${listingIndex}`}
    >
      <Link
        to={isCarouselItem ? '' : urlWithParams}
        className={styles['listing-card']}
        onMouseEnter={() => onHover?.(listing)}
        onFocus={() => onHover?.(listing)}
        clickTracker={new ListingClickTracker(listing)
          .interaction(
            isFocusedCard
              ? Click.INTERACTIONS.FOCUSED_CARD()
              : Click.INTERACTIONS.TILE(isCarouselItem)
          )
          .payload({
            item_index: listingIndex,
            [PAYLOAD.DEAL_TYPE]: listing.listingTrackingData.dealType,
            [PAYLOAD.FEATURED_TYPE]: listing.listingTrackingData.featuredType,
            [PAYLOAD.IS_PROMO]: listing.listingTrackingData.isPromo,
            [PAYLOAD.IS_SPONSORED]: listing.listingTrackingData.isSponsored,
          })}
        data-cy="listing-card-link"
        rel="nofollow"
      >
        <div className={styles['image-container']}>
          <Image
            className={styles.image}
            {...{ ...listing.getImageOptions(), lazyLoad: false }}
          />
          {showMLBBadge && !isSectionBadge && (
            <div className={styles['image-badge-container']}>
              <MLBBadge />
            </div>
          )}
          {showSponsoredDealBadgeLeft && (
            <div className={styles['deal-tag-container']}>
              <SponsorDealBadge variant={dealType} isExclusivesV1 />
            </div>
          )}
          {!showSponsoredDealBadge && (isZoneDeal || isFlashDeal) && (
            <div className={styles['deal-tag-container']}>
              <DealsTag
                listing={listing}
                fullEvent={fullEvent}
                dealType={isZoneDeal ? dealTypes.zoneDeal : dealTypes.flashDeal}
                showIconOnly={isSectionBadge && !isFocusedCard}
              />
            </div>
          )}
          {listing.disclosures.includes(ZONE_TICKET_DISCLOSURE) && (
            <div
              className={
                styles[
                  isSectionBadge
                    ? 'zone-tag-container-section-badge'
                    : 'zone-tag-container'
                ]
              }
            >
              <ZoneTag />
            </div>
          )}
        </div>
        <div className={styles['bottom-fade']}></div>
        <div className={styles.details}>
          <div className={styles['seat-info-col1']}>
            {showMLBBadge && isSectionBadge && (
              <div className={styles['section-badge-container']}>
                <MLBBadge />
              </div>
            )}

            <div className={styles['section-name']}>{listing.sectionGroup}</div>
            <div
              className={classNames(styles['seat-details'], {
                [styles['wide-label']]: isWidePriceLabel,
              })}
            >
              <span className={styles['seat-details-row']}>
                {listing.section}
                {', Row '}
                {listing.row}
              </span>
              <Disclosures
                disclosures={listing.disclosures}
                allDisclosures={allDisclosures}
                onlyIcon
              />
            </div>
            {showTicketSource && (
              <div
                className={classNames(styles['listing-source'], {
                  [styles['wide-label']]: isWidePriceLabel,
                })}
              >
                {`Ticket source: ${listing.source}`}
              </div>
            )}
          </div>
          <div className={styles['seat-info-col2']}>
            {showSponsoredDealBadgeRight && (
              <div className={styles['tag-info']}>
                <SponsorDealBadge variant={dealType} isExclusivesV1 />
              </div>
            )}
            {showFeaturedDealTag && (
              <div className={styles['tag-info']}>
                <FeaturedListingsTag
                  title={deal.title}
                  color={deal.color}
                  icon={deal.iconURL}
                />
              </div>
            )}
            {!showFeaturedDealTag &&
              !showSponsoredDealBadgeRight &&
              listing.isDiscounted() && (
                <div className={styles['tag-info']}>
                  <PriceMarkdownTag
                    listing={listing}
                    showDiscountIcon={!isCarouselItem}
                  />
                </div>
              )}
            {isAllInPriceActive && (
              <span className={styles['all-in']}>
                {isIncludesFeesCopyExperiment ? 'Incl. Fees' : 'All-In'}
              </span>
            )}
            <div
              className={styles['price-info']}
              key={`${listing.id}-${listing.getPrice()}`}
            >
              <PrimaryPrice
                listing={listing}
                isAllInPriceActive={isAllInPriceActive}
              />

              <TicketPrice
                className={classNames({
                  [styles['super-deal-ticket-price']]: dealType === 'super',
                })}
                price={
                  isAllInPriceActive
                    ? listing.getTotalPrice()
                    : listing.getPrice()
                }
                showEachSuffix={!isCarouselItem}
                eachSuffixStyle={{ color: 'inherit' }}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ListingCard.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    isIncludesFeesCopyExperiment: selectIsIncludesFeesCopyExperiment(state),
    showTicketSource: selectShowTicketSourceEnabled(state),
    isAllInPriceActive: isAllInPriceSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(ListingCard));
