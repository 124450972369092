import { AsyncRedirect } from 'contexts/LoaderContext';

interface RedirectUnauthenticatedUserProps {
  location: {
    pathname: string;
  };
  asyncRedirect: AsyncRedirect;
}

/**
 * This component handles redirection for unauthenticated users.
 * If the last action was logout, it will redirect to the home page.
 * However, if the user intentionally visits the URL: /my-account, /my-tickets, etc.,
 * it will redirect to /login.
 */
function redirectUnauthenticatedUser({
  location,
  asyncRedirect,
}: RedirectUnauthenticatedUserProps) {
  const path =
    location.pathname === '/' ? '/' : `/login?redirect=${location.pathname}`;
  asyncRedirect(path);
}

export default redirectUnauthenticatedUser;
