import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { PAYLOAD, TRACK, TrackPageView, View } from 'analytics';
import SimpleButton, { TYPES } from 'components/Buttons/SimpleButton';
import GTUniversalModal from 'components/GTUniversalModal/GTUniversalModal';
import {
  CHECKOUT_ROUTE,
  ERROR_PAGES_MAPPING,
  EVENT_ROUTE,
  GAMETIME_PHONE,
} from 'pages/Errors/errors.constants';
import {
  getAssociatedEventPathname,
  getAssociatedListingPathname,
} from 'store/modules/location';

import styles from './ErrorModal.module.scss';

const propTypes = {
  purchaseError: PropTypes.string,
  router: PropTypes.object.isRequired,
  currentLocation: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

@TrackPageView(({ listing, purchaseError }) => {
  const purchaseConstants = ERROR_PAGES_MAPPING[purchaseError];

  if (!purchaseConstants) {
    return null;
  }

  const { status, subTitle } = purchaseConstants;
  return {
    [TRACK.PAGE_TYPE]: View.PAGE_TYPES.PURCHASE_COMPLETE(
      status,
      listing.eventId,
      listing.id
    ),
    [TRACK.PAYLOAD]: {
      [PAYLOAD.MODAL]: true,
      [PAYLOAD.REASON]: subTitle,
    },
  };
})
class ErrorModal extends Component {
  static propTypes = propTypes;

  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {
      purchaseError,
      currentLocation: { pathname },
      onHide: onHideCall,
      router,
    } = this.props;
    const { nextPage, stayOnPage } = ERROR_PAGES_MAPPING[purchaseError];

    const promise = new Promise((resolve) => {
      onHideCall();
      resolve();
    });

    promise.then(() => {
      if (!stayOnPage) {
        switch (nextPage) {
          case EVENT_ROUTE: {
            router.replace(getAssociatedEventPathname(pathname));
            break;
          }
          case CHECKOUT_ROUTE: {
            const listingPathname = getAssociatedListingPathname(pathname);
            router.replace(`${listingPathname}/checkout`);
            break;
          }
          default: {
            router.replace(getAssociatedListingPathname(pathname));
            break;
          }
        }
      }
    });
  }

  render() {
    const { purchaseError, show } = this.props;

    if (!purchaseError) {
      return null;
    }

    const {
      title,
      subTitle,
      buttonText,
      buttonType,
      buttonProps = {},
    } = ERROR_PAGES_MAPPING[purchaseError];

    if (buttonType === 'tel') {
      buttonProps.href = `tel:${GAMETIME_PHONE}`;
    }

    return (
      <GTUniversalModal
        onHide={this.handleClick}
        show={show}
        styleName="error-modal-content"
      >
        <div className={styles['error-modal']}>
          <div className={styles['error-title']}>{title}</div>
          <div className={styles['error-text']}>{subTitle}</div>
          <div className={styles['modal-close']}>
            <SimpleButton
              className={styles['close-button']}
              type={TYPES.TRANSPARENT}
              text={buttonText}
              onClick={this.handleClick}
              {...buttonProps}
            />
          </div>
        </div>
      </GTUniversalModal>
    );
  }
}

export default withRouter(ErrorModal);
