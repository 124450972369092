import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'ui/Layout';

import { HomeClickTracker } from 'analytics';
import {
  FOOTER_TITLES,
  generateFooterLinks,
  LEGAL_ROUTES,
} from 'components/Footers/footer.constants';
import Link from 'components/Link/Link';
import PrivacyOptionsIcon from 'icons/PrivacyOptionsIcon';
import { LogoLarge } from 'logos';
import { updateUserPreference } from 'store/modules/userPreference/userPreference';
import colors from 'styles/colors.constants';

import styles from './GTFooter.module.scss';

const GTFooter = ({ dispatch }) => {
  const renderLinks = () => {
    return generateFooterLinks().map((col, index) => {
      const sections = Array.isArray(col) ? col : [col];

      return (
        <div className={styles['link-item']} key={`${col}-${index}`}>
          {sections.map((section, index) => {
            return (
              <div key={`${section}-${index}`} className={styles['link-block']}>
                <h5 className={styles['link-title']}>{section.title}</h5>
                <ul className={styles['link-list']}>
                  {section.links.map(
                    ({ title, clickTracker, id, ...props }) => {
                      const handleClick = () => {
                        if (section.title === FOOTER_TITLES.HOT_METROS) {
                          dispatch(
                            updateUserPreference({
                              lastVisitedMetro: id,
                            })
                          );
                        }
                      };

                      return (
                        <li key={title} className={styles['link-list-item']}>
                          <Link
                            clickTracker={clickTracker}
                            onClick={handleClick}
                            className={styles['footer-link']}
                            {...props}
                          >
                            {title}
                          </Link>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const handlePrivacyChoicesClick = (e) => {
    e.preventDefault();

    if (typeof window !== 'undefined' && Array.isArray(window.semaphore)) {
      window.semaphore.push(['showPreferences']);
    }
  };

  return (
    <footer className={styles.footer}>
      <Container className={styles['content-container']}>
        <div className={styles.content}>
          <div className={styles['container-links']}>{renderLinks()}</div>
          <div className={styles['legal-container']}>
            <Link
              className={styles['footer-link']}
              to="/"
              clickTracker={new HomeClickTracker()}
            >
              <LogoLarge
                className={styles.logo}
                primaryColor={colors.gray400}
                secondaryColor={colors.gray400}
                height={40}
              />
            </Link>
            <Link
              className={styles['footer-link']}
              to={`/${LEGAL_ROUTES.PRIVACY.path}`}
            >
              Privacy Policy
            </Link>
            <Link
              className={styles['footer-link']}
              to={`/${LEGAL_ROUTES.TOS.path}`}
            >
              Terms of Service
            </Link>
            <div className={styles['privacy-choices']}>
              <button
                type="button"
                className={styles['privacy-link']}
                onClick={handlePrivacyChoicesClick}
              >
                Your Privacy Choices
              </button>
              <PrivacyOptionsIcon />
            </div>
            <span className={styles.copyright}>
              {'© '}
              {new Date().getFullYear()}
              {' Gametime United, Inc.'}
            </span>
          </div>
        </div>
      </Container>
    </footer>
  );
};
GTFooter.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(GTFooter);
