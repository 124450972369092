import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { Click, useAnalyticsContext, View } from 'analytics';
import InfoHeader from 'components/Headers/InfoHeader/InfoHeader';
import ThemedCard from 'components/ThemedCard/ThemedCard';
import OnLoadTracker from 'components/Trackable/OnLoadTracker';
import { ACTIONS as T_ACTIONS } from 'components/Trackable/TrackingHelper';
import { selectIsSMSLoginEnabled } from 'featureFlags';
import Listing from 'models/Listing';

import {
  DISPLAY_KEYS,
  DISPLAY_TEXT,
  EmailForm,
  MagicLinkSuccess,
  SMSVerificationForm,
} from '../components';
import {
  LOGIN_STEPS,
  loginPropTypes,
  selectCheckoutMagicLinkParams,
  useLogin,
} from '../Login';

import styles from './CheckoutLogin.module.scss';

// TODO: is this still relevant? this is the only component that uses
// OnLoadTracker, which references analyticsDeprecated...
const onLoad = { action: T_ACTIONS.SENT_MAGIC_LINK };
const MagicLinkConfirmation = OnLoadTracker(onLoad)(MagicLinkSuccess);

function CheckoutLogin({
  magicLinkParams,
  dispatch,
  isSMSLoginEnabled,
  listing,
  router,
}) {
  const analytics = useAnalyticsContext();

  const {
    state,
    reset,
    clearErrors,
    handleGenerateMagicLink,
    handleGenerateSMSCode,
    handleSMSCodeLogin,
    handleEmailLogin,
  } = useLogin({
    dispatch,
    magicLinkParams,
    sourcePageTypeCallback: Click.SOURCE_PAGE_TYPES.ADD_EMAIL,
    analytics,
    isSMSLoginEnabled,
  });

  const [displayKey, setDisplayKey] = useState(DISPLAY_KEYS.NEW);

  const displayText = DISPLAY_TEXT[displayKey];

  switch (state.step) {
    case LOGIN_STEPS.MAGIC_LINK_SUCCESS: {
      return (
        <div className={styles['magic-link-confirmation']}>
          <MagicLinkConfirmation isCheckout email={state.email} />
        </div>
      );
    }
    case LOGIN_STEPS.SMS_CODE: {
      return (
        <>
          <InfoHeader
            headerContent="Sign In"
            isDarkTheme
            bold
            showBack
            onBack={reset}
          />

          <ThemedCard
            title={`${DISPLAY_TEXT.SMS_CODE.title}${state.phone}.`}
            desc={DISPLAY_TEXT.SMS_CODE.desc}
          >
            <SMSVerificationForm
              email={state.email}
              hasInvalidCodeError={state.hasInvalidCodeError}
              isSubmitting={state.isSubmitting}
              clearErrors={clearErrors}
              handleGenerateSMSCode={handleGenerateSMSCode}
              handleSMSCodeLogin={handleSMSCodeLogin}
              handleGenerateMagicLink={handleGenerateMagicLink}
              nextAllowedTokenRequestTime={state.nextAllowedTokenRequestTime}
              listing={listing}
            />
          </ThemedCard>
        </>
      );
    }
    case LOGIN_STEPS.USER_EMAIL:
    default: {
      return (
        <>
          <InfoHeader
            headerContent="Enter Email"
            isDarkTheme
            bold
            showBack
            onBack={router.goBack}
          />
          <ThemedCard title={displayText.title} desc={displayText.desc}>
            {state.hasError && (
              <div className={styles['error-message']}>
                {DISPLAY_TEXT.ERROR.title}
              </div>
            )}
            <EmailForm
              ctaText="Continue"
              pageTypeCallback={View.PAGE_TYPES.ADD_EMAIL}
              isSubmitting={state.isSubmitting}
              handleEmailLogin={handleEmailLogin}
              listing={listing}
            />
            {displayKey === DISPLAY_KEYS.NEW && (
              <button
                type="button"
                onClick={() => setDisplayKey(DISPLAY_KEYS.RETURNING)}
                className={styles['existing-user-text']}
              >
                I Already Have An Account
              </button>
            )}
          </ThemedCard>
        </>
      );
    }
  }
}
CheckoutLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  magicLinkParams: loginPropTypes.magicLinkParams,
  isSMSLoginEnabled: loginPropTypes.isSMSLoginEnabled,
  listing: PropTypes.instanceOf(Listing).isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  return {
    magicLinkParams: selectCheckoutMagicLinkParams(state, props.location),
    isSMSLoginEnabled: selectIsSMSLoginEnabled(state),
  };
}

export default withRouter(connect(mapStateToProps)(CheckoutLogin));
