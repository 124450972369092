import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Image from 'components/Image/Image';
import MLBMarketplaceTag from 'components/MLBMarketplaceTag/MLBMarketplaceTag';

import styles from './SimpleHeroSection.module.scss';

const SimpleHeroSection = ({
  title,
  breadcrumbProps,
  backgroundImage,
  showMLBMarketplaceTag,
}) => {
  return (
    <div className={styles['hero-container']}>
      <Image src={backgroundImage} />
      <div className={styles.details}>
        <div className={styles['title-container']}>
          {breadcrumbProps && <Breadcrumbs {...breadcrumbProps} />}
          <h1 className={styles.title} data-cy="hero-section-title">
            {title}
          </h1>
        </div>
        {showMLBMarketplaceTag && <MLBMarketplaceTag />}
      </div>
    </div>
  );
};

SimpleHeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbProps: PropTypes.object,
  backgroundImage: PropTypes.string.isRequired,
  showMLBMarketplaceTag: PropTypes.bool,
};

export default SimpleHeroSection;
