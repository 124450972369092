import url from 'url';

import { createSelector } from 'reselect';

import { TARGET_ID_URL_PARAMETER } from 'analytics';
import config from 'config/config';
import { ENV } from 'config/environments';
import { appConfigSelector } from 'store/modules/app/app.selectors';
import { MODALS } from 'store/modules/modals/modals';
import { selectUserDetails } from 'store/modules/user/user.selectors';
import { getUTMSearchParams } from 'utils/url';

export const generateBranchLink = (data, callback) => {
  if (
    __CLIENT__ &&
    typeof window !== 'undefined' &&
    window.branch &&
    window.branch.link
  ) {
    window.branch.link({ ...data }, callback);
  } else {
    console.error('branch is not initialized');
  }
};

/**
 * Generates a Branch URL from a pathname and query parameters.
 */
export function getBranchUrl(pathname, query) {
  const urlParts = {
    hostname: config.domain,
    pathname,
    query: {
      ...query,
      [MODALS.GET_APP]: true,
    },
  };

  if (config.env === ENV.DEVELOPMENT) {
    urlParts.port = config.port;
  } else {
    urlParts.protocol = 'https';
  }

  return url.format(urlParts);
}

/**
 * Generates Branch query parameters from the location query parameters.
 */
function getBranchQueryParams(query) {
  const queryParams = {};

  if (query !== undefined) {
    Object.entries(query).forEach(([queryKey, queryValue]) => {
      queryParams[`query_${queryKey}`] = queryValue;
    });
  }

  return queryParams;
}

/**
 * Generates a Branch "feature" string from app config. Note that the values
 * from app config are set once when the app initializes and are not updated
 * when location state changes.
 */
function getBranchFeature(appConfig) {
  const featureParams = {
    web_device_id: appConfig.webUserId,
    web_session_id: appConfig.webSessionId,
  };

  if (appConfig.urlQuery) {
    const targetId = appConfig.urlQuery[TARGET_ID_URL_PARAMETER];
    if (targetId) {
      featureParams[TARGET_ID_URL_PARAMETER] = targetId;
    }

    Object.assign(featureParams, getUTMSearchParams(appConfig.urlQuery));
  }

  return url.format({ query: featureParams });
}

/**
 * Returns a memoized selector to generate default Branch link parameters. The
 * object returned by the selector will be stable unless the input selector
 * values change, making it suitable for use in React hook dependency arrays.
 *
 * After we update React Redux to a version with hooks (>= v7.1.0) we should
 * consider using this selector factory function in a custom hook to better
 * handle dynamic campaign values:
 *
 *   function useDefaultBranchData(campaign) {
 *     const selectDefaultBranchData = useMemo(
 *       () => createSelectDefaultBranchData(campaign),
 *       [campaign]
 *     );
 *
 *     return useSelector(selectDefaultBranchData);
 *   };
 */
export const createSelectDefaultBranchData = (campaign) =>
  createSelector(appConfigSelector, selectUserDetails, (appConfig, user) => {
    return {
      campaign,
      feature: getBranchFeature(appConfig),
      data: {
        id: user?.id,
        email: user?.email,
        web_device_id: appConfig.webUserId,
        web_session_id: appConfig.webSessionId,
      },
    };
  });

/**
 * Legacy function for generating branch data.
 */
const selectDefaultBranchData = createSelectDefaultBranchData();
export const extendDefaultBranchState = ({
  state,
  campaign,
  branchData = {},
  location,
}) => {
  const defaultBranchData = selectDefaultBranchData(state);
  const desktopUrl = getBranchUrl(location.pathname, location.query);
  const queryParams = getBranchQueryParams(location.query);

  return {
    ...defaultBranchData,
    campaign,
    data: {
      $desktop_url: desktopUrl,
      $canonical_url: desktopUrl,
      ...queryParams,
      ...defaultBranchData.data,
      ...branchData,
    },
  };
};
