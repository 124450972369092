import React, { useState } from 'react';

import GTInfoModal from 'components/GTInfoModal/GTInfoModal';
import { GTGuaranteeFillIcon } from 'icons';
import {
  Coverage,
  MODAL_DATA,
} from 'pages/Listing/components/GTCoverage/GTCoverage.data';

import styles from './CoverageBlock.module.scss';

type Props = {
  coverage: Coverage;
  onClick: () => void;
};

export default function CoverageBlock({ coverage, onClick }: Props) {
  const { title, message, cta } = coverage;
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
    setModalOpen(true);
  };

  return (
    <>
      <div className={styles['coverage-details']}>
        <span className={styles.title}>{title}</span>
        <span className={styles.message}>
          {message}
          {cta && (
            <button
              aria-label="learn more about ticket coverage"
              onClick={handleClick}
              className={styles.cta}
            >
              {cta}
            </button>
          )}
        </span>
      </div>
      <GTInfoModal
        show={isModalOpen}
        onHide={() => setModalOpen(false)}
        buttonText="GOT IT"
        buttonClassName={styles['modal-button']}
        styleName={styles['modal']}
      >
        <div className={styles['modal-icon']}>
          <GTGuaranteeFillIcon width={40} height={40} />
        </div>

        {MODAL_DATA.map((item) => (
          <div key={item.id} className={styles['modal-container']}>
            <h2 className={styles['modal-content-title']}>{item.title}</h2>
            <p className={styles['modal-content']}>{item.content}</p>
          </div>
        ))}
      </GTInfoModal>
    </>
  );
}
