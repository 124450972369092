import React from 'react';
import { IndexRoute, Route } from 'react-router';

import App from '../pages/App/App';
import Blog from '../pages/Blog/Blog';
import Post from '../pages/Blog/Post';
import CategoryPerformers from '../pages/CategoryPerformers/CategoryPerformers';
import Checkout from '../pages/Checkout/Checkout';
import Collection from '../pages/Collection/Collection';
import ContentPage from '../pages/ContentPageCMS/ContentPage';
import Event from '../pages/Event/Event';
import GenrePerformers from '../pages/GenrePerformers/GenrePerformers';
import Healthcheck from '../pages/Healthcheck/Healthcheck';
import Home from '../pages/Home/Home';
import Listing from '../pages/Listing/Listing';
import Login from '../pages/Login/Login';
import MainMetro from '../pages/MainMetro/MainMetro';
import MetroPerformers from '../pages/MetroPerformers/MetroPerformers';
import MyAccount from '../pages/MyAccount/MyAccount';
import MyTickets from '../pages/MyTickets/MyTickets';
import NotFound from '../pages/NotFound/NotFound';
import Order from '../pages/Order/Order';
import Performer from '../pages/Performer/Performer';
import Picks from '../pages/Picks/Picks';
import { PURCHASE_PATH } from '../pages/Purchase/constants';
import Purchase from '../pages/Purchase/Purchase';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Search from '../pages/Search/Search';
import Sitemap from '../pages/Sitemap/Sitemap';
import SportsByMetro from '../pages/SportsByMetro/SportsByMetro';
import About from '../pages/StaticPages/About/About';
import Careers from '../pages/StaticPages/Careers/Careers';
import PromoCodes from '../pages/StaticPages/PromoCodes/PromoCodes';
import WhyGametime from '../pages/StaticPages/WhyGametime/WhyGametime';
import Unavailable from '../pages/Unavailable/Unavailable';
import Venue from '../pages/Venue/Venue';

import ProtectedCheckoutRoute from './ProtectedCheckoutRoute';

export default () => {
  return (
    <Route>
      <Route path="/healthcheck" component={Healthcheck} />
      <Route path="/unavailable" component={Unavailable} />
      <Route path="/" component={App}>
        <IndexRoute component={Home} />
        <Route path="search" component={Search} />
        <Route path="login" component={Login} />
        <Route path="order/:transactionId" component={Order} />
        <Route path="blog/:slug(/**)" component={Post} />
        <Route path="blog" component={Blog} />
        <Route path="my-tickets" component={MyTickets} />
        <Route path="my-account" component={MyAccount} />
        <Route path="c/:categoryId" component={CategoryPerformers} />
        <Route
          path="/concert-tickets/genre/:genre"
          component={GenrePerformers}
        />
        <Route
          path="(:collectionDescriptorSlug/):metro/collection/:slug(/:view)"
          component={Collection}
        />
        <Route
          path="(:performerTicketSlug/)performers/:slug(/:matchupSlug)(/:parkingSlug)"
          component={Performer}
        />
        <Route path="(:venueTicketSlug/)venues/:slug" component={Venue} />
        <Route
          path="(:metroTicketSlug/)metros/:metroId(/:categoryGroupId)"
          component={MetroPerformers}
        />
        <Route
          path="(:categoryPrefix/)(:eventName/)(:eventDetails/)events/:eventId"
          component={Event}
        >
          <Route path="listings/:listingId">
            <IndexRoute component={Listing} />
            <Route component={ProtectedCheckoutRoute}>
              <Route path={PURCHASE_PATH} component={Purchase} />
              <Route path="checkout" component={Checkout} />
            </Route>
          </Route>
        </Route>
        <Route path="events-near-me" component={MainMetro} />
        <Route path="/sport/:metroId" component={SportsByMetro} />
        <Route path="/about" component={About} />
        <Route path="/careers" component={Careers} />
        <Route path="/why-gametime" component={WhyGametime} />
        <Route path="/promo-codes" component={PromoCodes} />
        <Route path="/sitemap" component={Sitemap} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/picks" component={Picks} />

        {/* Path must be the same as the slug from ButterCMS API */}
        <Route path="/terms-of-service" component={ContentPage} />
        <Route path="/privacy-policy" component={ContentPage} />
        <Route path="/share-codes" component={ContentPage} />
        <Route path="/price-guarantee" component={ContentPage} />
        <Route path="/gametime-guarantee" component={ContentPage} />
        <Route path="/cookies-policy" component={ContentPage} />
        <Route path="/press" component={ContentPage} />

        <Route path="*" component={NotFound} />
      </Route>
    </Route>
  );
};
