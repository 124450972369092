import React from 'react';

import { CalendarIcon, DiscountIcon, ShieldIcon, TicketSetIcon } from 'icons';
import { Disclosure, FullEvent } from 'models';
import Listing from 'models/Listing';
import { Purchase, UserModel } from 'types';

import { COVERAGE } from '../components/GTCoverage/GTCoverage.data';
import { URGENCY_MESSAGING_THRESHOLD } from '../constants';

import AffirmBlock from './AffirmBlock/AffirmBlock';
import CoverageBlock from './CoverageBlock/CoverageBlock';
import DetailsBlock from './DetailsBlock/DetailsBlock';
import PriceBlock from './PriceBlock/PriceBlock';
import TicketsBlock from './TicketsBlock/TicketsBlock';
import UrgencyMessageBlock from './UrgencyMessageBlock/UrgencyMessageBlock';

import styles from './DetailsStack.module.scss';

type Props = {
  maxLotSize: number;
  continueButton: React.ReactNode;
  onPriceBreakdownInfoClick: () => void;
  showFaceValuePricing: boolean;
  extendedPurchase?: { seatFee: number; salesTax: number; totalPrice: number };
  showAllInPricing: boolean;
  currencyPrefix: string;
  listing: Listing;
  fullEvent: FullEvent;
  seatCount: number;
  allDisclosures: { [key: string]: Disclosure };
  isUpdatingPricing: boolean;
  priceWithPromoApplied: number;
  onAffirmBannerClick: () => void;
  coverageVariant: keyof typeof COVERAGE;
  onGTCoverageClick: () => void;
  seatRange?: string;
  ticketQuantities?: number[];
  onSeatsChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  user?: UserModel;
  purchases: Record<string, Purchase>;
};

function DetailsStackItem({
  icon,
  body,
  testId,
}: {
  icon?: React.ReactNode;
  body: React.ReactNode;
  testId?: string;
}) {
  return (
    <div data-testid={testId} className={styles['stacked-card-item']}>
      <div
        data-testid={testId ? testId + '_icon' : undefined}
        className={styles['icon']}
      >
        {icon}
      </div>
      <div data-testid={testId ? testId + '_body' : undefined}>{body}</div>
    </div>
  );
}

export default function DetailsStack({
  continueButton,
  onPriceBreakdownInfoClick,
  showFaceValuePricing,
  showAllInPricing,
  currencyPrefix,
  listing,
  isUpdatingPricing,
  priceWithPromoApplied,
  extendedPurchase,
  onAffirmBannerClick,
  coverageVariant,
  onGTCoverageClick,
  seatRange,
  seatCount,
  ticketQuantities = [],
  onSeatsChange,
  maxLotSize,
  fullEvent,
  allDisclosures,
  user,
  purchases,
}: Props) {
  const isUrgencyMessagingEligible =
    maxLotSize > 0 && maxLotSize <= URGENCY_MESSAGING_THRESHOLD;

  const totalPrice =
    priceWithPromoApplied > 0
      ? priceWithPromoApplied
      : extendedPurchase?.totalPrice || 0;

  const showAffirmBlock =
    !isUpdatingPricing && totalPrice >= 50 && totalPrice < 20000;

  const coverage = COVERAGE[coverageVariant];

  const ticketsOwned = Object.values(purchases).reduce((total, purchase) => {
    if (purchase.event_id === fullEvent.id) {
      return total + purchase.tickets.length;
    }
    return total;
  }, 0);

  return (
    <div className={styles['stacked-card-body']}>
      {isUrgencyMessagingEligible && (
        <UrgencyMessageBlock quantity={maxLotSize} />
      )}
      <DetailsStackItem
        icon={<CalendarIcon width="100%" />}
        body={
          <DetailsBlock
            fullEvent={fullEvent}
            listing={listing}
            seatCount={seatCount}
            showSeats={!seatRange}
            allDisclosures={allDisclosures}
          />
        }
      />
      <DetailsStackItem
        icon={<DiscountIcon width="100%" />}
        testId="price-block"
        body={
          !isUpdatingPricing && (
            <PriceBlock
              onPriceBreakdownInfoClick={onPriceBreakdownInfoClick}
              showFaceValuePricing={showFaceValuePricing}
              extendedPurchase={extendedPurchase}
              showAllInPricing={showAllInPricing}
              priceWithPromoApplied={priceWithPromoApplied}
              currencyPrefix={currencyPrefix}
              listing={listing}
              seatCount={seatCount}
            />
          )
        }
      />
      {showAffirmBlock && (
        <DetailsStackItem
          body={
            <AffirmBlock price={totalPrice} onClick={onAffirmBannerClick} />
          }
        />
      )}
      <DetailsStackItem
        icon={<TicketSetIcon width="100%" />}
        body={
          <TicketsBlock
            seatRange={seatRange}
            seatCount={seatCount}
            ticketQuantities={ticketQuantities}
            handleSeatsChange={onSeatsChange}
            isGeneralAdmission={listing.isGeneralAdmission}
          />
        }
      />
      <DetailsStackItem
        icon={<ShieldIcon width="100%" fill={coverage.shieldColor} />}
        body={<CoverageBlock coverage={coverage} onClick={onGTCoverageClick} />}
      />
      {ticketsOwned > 0 && (
        <div className={styles['tickets-owned']}>
          You already purchased {ticketsOwned} ticket
          {ticketsOwned > 1 ? 's' : ''} to this event.
        </div>
      )}
      <div
        className={styles['action-button-container']}
        data-cy="continue-button-container"
      >
        {continueButton}
      </div>
      {user && <div className={styles.email}>{user.email}</div>}
    </div>
  );
}
