import React from 'react';

interface IOSAppStoreBadgeProps {
  width?: React.SVGProps<SVGSVGElement>['width'];
  height?: React.SVGProps<SVGSVGElement>['height'];
}

export default function IOSAppStoreBadge({
  height = '26',
  width = '78',
}: IOSAppStoreBadgeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 78 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.5876 -3.75703e-05H6.19754C5.95919 -3.75703e-05 5.72369 -3.75703e-05 5.48597 0.00126243C5.28697 0.00256243 5.08956 0.00633893 4.88865 0.00951743C4.45218 0.0146536 4.01674 0.053052 3.58612 0.124379C3.15609 0.197259 2.73954 0.334658 2.35054 0.531929C1.96202 0.730869 1.60702 0.989371 1.29842 1.29806C0.988197 1.60588 0.729601 1.96167 0.532578 2.35177C0.33501 2.74108 0.198016 3.15827 0.126328 3.58892C0.0539571 4.01903 0.0150139 4.4541 0.0098475 4.89022C0.0038155 5.08951 0.0031785 5.28947 0 5.48878V20.5131C0.0031785 20.7149 0.0038155 20.9104 0.0098475 21.1123C0.0150155 21.5484 0.0539587 21.9834 0.126328 22.4135C0.197818 22.8444 0.33482 23.2619 0.532578 23.6513C0.729512 24.0402 0.988144 24.3945 1.29842 24.7006C1.60585 25.0107 1.96108 25.2694 2.35054 25.4668C2.73953 25.6646 3.15604 25.8028 3.58612 25.8768C4.01682 25.9475 4.45221 25.986 4.88865 25.9917C5.08956 25.9961 5.28697 25.9987 5.48597 25.9987C5.72369 26 5.9592 26 6.19754 26H71.5876C71.8212 26 72.0586 26 72.2922 25.9987C72.4903 25.9987 72.6934 25.9961 72.8914 25.9917C73.3271 25.9863 73.7616 25.9478 74.1914 25.8768C74.6229 25.8023 75.0409 25.6641 75.4318 25.4668C75.8209 25.2692 76.1758 25.0106 76.4829 24.7006C76.7924 24.3933 77.0516 24.0392 77.251 23.6513C77.4473 23.2616 77.583 22.8442 77.6534 22.4135C77.7259 21.9834 77.7662 21.5484 77.774 21.1123C77.7766 20.9104 77.7766 20.7149 77.7766 20.5131C77.7817 20.2769 77.7816 20.0421 77.7816 19.8021V6.19845C77.7816 5.96041 77.7817 5.72428 77.7766 5.48878C77.7766 5.28947 77.7766 5.08951 77.774 4.8902C77.7662 4.45404 77.7259 4.01907 77.6534 3.5889C77.5828 3.1585 77.4471 2.74134 77.251 2.35174C76.8498 1.56984 76.2135 0.933379 75.4318 0.53187C75.0409 0.335081 74.6229 0.197719 74.1914 0.12432C73.7617 0.0526792 73.3271 0.0142675 72.8914 0.00942643C72.6934 0.00625443 72.4903 0.00244543 72.2922 0.00117793C72.0586 -0.00012207 71.8212 -0.00012207 71.5876 -0.00012207V-3.75703e-05Z"
        fill="#A6A6A6"
      />
      <path
        d="M5.48914 25.4312C5.2911 25.4312 5.09784 25.4287 4.90135 25.4243C4.49431 25.419 4.08822 25.3835 3.68641 25.3183C3.31175 25.2537 2.9488 25.1337 2.60953 24.9621C2.27338 24.792 1.96678 24.5689 1.70148 24.3013C1.43234 24.037 1.20827 23.7303 1.03815 23.3936C0.866153 23.0547 0.747121 22.6914 0.6852 22.3164C0.618331 21.9135 0.582152 21.5061 0.576975 21.0977C0.572854 20.9606 0.567459 20.5042 0.567459 20.5042V5.4888C0.567459 5.4888 0.573205 5.03938 0.577008 4.90735C0.581964 4.49959 0.617933 4.0928 0.684602 3.6905C0.746637 3.31449 0.865762 2.95016 1.03785 2.61014C1.20735 2.27364 1.43017 1.96678 1.69769 1.70147C1.96491 1.43363 2.27249 1.20937 2.60921 1.03686C2.9477 0.865836 3.30996 0.746652 3.68387 0.683301C4.087 0.617369 4.4945 0.581723 4.90295 0.576662L5.48946 0.568726H72.289L72.8825 0.576981C73.2873 0.58179 73.6911 0.617118 74.0905 0.682664C74.4682 0.746809 74.8342 0.866826 75.1766 1.03877C75.8512 1.38642 76.4001 1.93643 76.7463 2.61172C76.9157 2.9494 77.033 3.31076 77.0942 3.68352C77.1617 4.08912 77.1994 4.49911 77.2072 4.91021C77.2091 5.09429 77.2091 5.29202 77.2091 5.4888C77.2142 5.73255 77.2142 5.96455 77.2142 6.19846V19.8021C77.2142 20.0382 77.2142 20.2687 77.2091 20.501C77.2091 20.7124 77.2091 20.906 77.2065 21.1053C77.1989 21.509 77.1618 21.9117 77.0954 22.3101C77.0348 22.6877 76.9164 23.0539 76.7444 23.3956C76.5731 23.7286 76.3503 24.0326 76.0843 24.2963C75.8188 24.5652 75.5116 24.7896 75.1747 24.9609C74.8333 25.1338 74.4678 25.2543 74.0905 25.3183C73.6887 25.3839 73.2826 25.4193 72.8755 25.4243C72.6851 25.4287 72.4858 25.4312 72.2922 25.4312L71.5876 25.4325L5.48914 25.4312Z"
        fill="black"
      />
      <path
        d="M16.0998 13.1954C16.1068 12.6529 16.2509 12.121 16.5187 11.6491C16.7865 11.1772 17.1693 10.7808 17.6315 10.4966C17.3379 10.0773 16.9505 9.73214 16.5002 9.48868C16.0499 9.24522 15.549 9.11012 15.0373 9.09412C13.9458 8.97955 12.8876 9.74725 12.3314 9.74725C11.7644 9.74725 10.908 9.10549 9.98587 9.12446C9.38939 9.14373 8.80806 9.31719 8.29854 9.62793C7.78902 9.93867 7.36868 10.3761 7.07848 10.8976C5.82138 13.0741 6.75906 16.2727 7.96326 18.032C8.56574 18.8935 9.26987 19.8558 10.1912 19.8217C11.0928 19.7843 11.4295 19.2468 12.5178 19.2468C13.596 19.2468 13.9119 19.8217 14.852 19.8C15.8194 19.7843 16.429 18.9347 17.0103 18.065C17.4432 17.4512 17.7763 16.7728 17.9973 16.0549C17.4352 15.8172 16.9556 15.4193 16.6181 14.9108C16.2807 14.4023 16.1004 13.8057 16.0998 13.1954V13.1954Z"
        fill="white"
      />
      <path
        d="M14.3242 7.93705C14.8517 7.30382 15.1116 6.4899 15.0487 5.66815C14.2428 5.7528 13.4984 6.13796 12.9637 6.7469C12.7023 7.04438 12.5021 7.39046 12.3746 7.76536C12.247 8.14027 12.1946 8.53663 12.2203 8.9318C12.6234 8.93595 13.0222 8.84858 13.3866 8.67628C13.751 8.50397 14.0716 8.25122 14.3242 7.93705Z"
        fill="white"
      />
      <path
        d="M27.4965 17.6407H24.4198L23.6809 19.8224H22.3777L25.2919 11.7507H26.6459L29.5601 19.8224H28.2347L27.4965 17.6407ZM24.7384 16.634H27.1772L25.975 13.0933H25.9413L24.7384 16.634Z"
        fill="white"
      />
      <path
        d="M35.8538 16.8803C35.8538 18.7091 34.875 19.884 33.3979 19.884C33.0237 19.9036 32.6516 19.8174 32.3241 19.6353C31.9967 19.4532 31.7271 19.1826 31.5463 18.8544H31.5183V21.7693H30.3104V13.9375H31.4796V14.9163H31.5019C31.691 14.5897 31.9651 14.3205 32.2951 14.1373C32.625 13.9541 32.9985 13.8638 33.3757 13.876C34.8693 13.876 35.8538 15.0566 35.8538 16.8803ZM34.6122 16.8803C34.6122 15.6889 33.9965 14.9056 33.057 14.9056C32.1341 14.9056 31.5133 15.7054 31.5133 16.8803C31.5133 18.0661 32.1341 18.8602 33.057 18.8602C33.9965 18.8602 34.6122 18.0826 34.6122 16.8803Z"
        fill="white"
      />
      <path
        d="M42.3309 16.8803C42.3309 18.7091 41.3521 19.884 39.875 19.884C39.5009 19.9036 39.1287 19.8174 38.8013 19.6353C38.4738 19.4532 38.2042 19.1826 38.0234 18.8544H37.9955V21.7693H36.7875V13.9375H37.9568V14.9163H37.979C38.1681 14.5897 38.4422 14.3205 38.7722 14.1373C39.1021 13.9541 39.4756 13.8638 39.8528 13.876C41.3464 13.876 42.3309 15.0566 42.3309 16.8803ZM41.0893 16.8803C41.0893 15.6889 40.4736 14.9056 39.5342 14.9056C38.6112 14.9056 37.9904 15.7054 37.9904 16.8803C37.9904 18.0661 38.6112 18.8601 39.5342 18.8601C40.4736 18.8601 41.0894 18.0826 41.0894 16.8803H41.0893Z"
        fill="white"
      />
      <path
        d="M46.6118 17.5735C46.7013 18.3739 47.4789 18.8995 48.5415 18.8995C49.5597 18.8995 50.2922 18.3739 50.2922 17.6521C50.2922 17.0256 49.8504 16.6505 48.8043 16.3934L47.7582 16.1414C46.276 15.7834 45.5879 15.0902 45.5879 13.9654C45.5879 12.5727 46.8016 11.6161 48.525 11.6161C50.2306 11.6161 51.3999 12.5727 51.4392 13.9654H50.2198C50.1468 13.1599 49.4809 12.6737 48.5078 12.6737C47.5348 12.6737 46.8689 13.1656 46.8689 13.8816C46.8689 14.4523 47.2942 14.7881 48.3346 15.0451L49.2239 15.2635C50.88 15.6551 51.5681 16.3204 51.5681 17.501C51.5681 19.0111 50.3652 19.9569 48.452 19.9569C46.6619 19.9569 45.4534 19.0334 45.3753 17.5734L46.6118 17.5735Z"
        fill="white"
      />
      <path
        d="M54.175 12.5449V13.9375H55.2941V14.8941H54.175V18.1384C54.175 18.6424 54.3991 18.8773 54.8911 18.8773C55.0239 18.875 55.1566 18.8656 55.2884 18.8493V19.8002C55.0672 19.8415 54.8424 19.8603 54.6175 19.8561C53.426 19.8561 52.9614 19.4086 52.9614 18.2673V14.8941H52.1057V13.9375H52.9614V12.5449H54.175Z"
        fill="white"
      />
      <path
        d="M55.9423 16.8803C55.9423 15.0287 57.0328 13.8652 58.7333 13.8652C60.4396 13.8652 61.525 15.0287 61.525 16.8803C61.525 18.737 60.4453 19.8954 58.7333 19.8954C57.022 19.8954 55.9423 18.737 55.9423 16.8803ZM60.2942 16.8803C60.2942 15.6101 59.7121 14.8605 58.7333 14.8605C57.7545 14.8605 57.1731 15.6159 57.1731 16.8803C57.1731 18.1556 57.7545 18.8995 58.7333 18.8995C59.7121 18.8995 60.2942 18.1556 60.2942 16.8803H60.2942Z"
        fill="white"
      />
      <path
        d="M62.5209 13.9375H63.673V14.9392H63.701C63.7789 14.6264 63.9622 14.3499 64.22 14.1563C64.4778 13.9626 64.7944 13.8637 65.1165 13.876C65.2557 13.8755 65.3946 13.8906 65.5304 13.921V15.0509C65.3547 14.9972 65.1713 14.9726 64.9876 14.9779C64.8122 14.9708 64.6372 15.0017 64.4748 15.0686C64.3124 15.1355 64.1664 15.2367 64.0468 15.3653C63.9272 15.494 63.8369 15.647 63.782 15.8138C63.7272 15.9806 63.709 16.1574 63.7289 16.3319V19.8224H62.5209L62.5209 13.9375Z"
        fill="white"
      />
      <path
        d="M71.0998 18.094C70.9373 19.1623 69.8969 19.8954 68.5658 19.8954C66.8539 19.8954 65.7913 18.7484 65.7913 16.9082C65.7913 15.0623 66.8596 13.8652 68.515 13.8652C70.1432 13.8652 71.1671 14.9836 71.1671 16.768V17.1818H67.0106V17.2548C66.9915 17.4714 67.0186 17.6896 67.0902 17.8949C67.1618 18.1002 67.2763 18.2879 67.426 18.4455C67.5758 18.6032 67.7573 18.7272 67.9586 18.8093C68.16 18.8914 68.3765 18.9297 68.5938 18.9217C68.8792 18.9485 69.1657 18.8823 69.4105 18.7332C69.6554 18.5841 69.8456 18.3599 69.9528 18.094L71.0998 18.094ZM67.0164 16.3376H69.9585C69.9693 16.1428 69.9398 15.948 69.8718 15.7652C69.8037 15.5824 69.6986 15.4156 69.5631 15.2754C69.4276 15.1352 69.2645 15.0244 69.0841 14.9502C68.9038 14.876 68.71 14.8398 68.515 14.844C68.3183 14.8428 68.1233 14.8806 67.9413 14.9552C67.7593 15.0298 67.5938 15.1397 67.4545 15.2785C67.3152 15.4174 67.2047 15.5825 67.1295 15.7642C67.0543 15.946 67.0159 16.1409 67.0164 16.3376V16.3376Z"
        fill="white"
      />
      <path
        d="M24.587 5.67518C24.8403 5.657 25.0944 5.69526 25.3311 5.78719C25.5678 5.87913 25.7811 6.02244 25.9557 6.20679C26.1302 6.39115 26.2617 6.61195 26.3406 6.85328C26.4196 7.09461 26.4439 7.35044 26.412 7.60232C26.412 8.84139 25.7423 9.55362 24.587 9.55362H23.1861V5.67518H24.587ZM23.7885 9.00513H24.5197C24.7007 9.01594 24.8818 8.98627 25.0499 8.91827C25.2179 8.85028 25.3687 8.74566 25.4912 8.61204C25.6137 8.47842 25.7049 8.31917 25.7581 8.14587C25.8113 7.97256 25.8252 7.78958 25.7988 7.61023C25.8233 7.43159 25.808 7.24973 25.754 7.0777C25.7 6.90567 25.6085 6.74772 25.4863 6.61516C25.3641 6.48261 25.214 6.37873 25.0469 6.31098C24.8798 6.24323 24.6998 6.21327 24.5197 6.22326H23.7885V9.00513Z"
        fill="white"
      />
      <path
        d="M27.0925 8.08884C27.0741 7.89649 27.0961 7.70242 27.1571 7.51908C27.218 7.33573 27.3167 7.16716 27.4467 7.02417C27.5766 6.88118 27.735 6.76693 27.9117 6.68875C28.0884 6.61057 28.2795 6.57019 28.4727 6.57019C28.666 6.57019 28.8571 6.61057 29.0338 6.68875C29.2105 6.76693 29.3689 6.88118 29.4988 7.02417C29.6288 7.16716 29.7275 7.33573 29.7885 7.51908C29.8494 7.70242 29.8714 7.89649 29.853 8.08884C29.8718 8.28138 29.8501 8.47572 29.7892 8.65936C29.7284 8.843 29.6298 9.01188 29.4998 9.15515C29.3698 9.29842 29.2113 9.41291 29.0344 9.49126C28.8575 9.5696 28.6662 9.61008 28.4727 9.61008C28.2793 9.61008 28.088 9.5696 27.9111 9.49126C27.7342 9.41291 27.5757 9.29842 27.4457 9.15515C27.3157 9.01188 27.2171 8.843 27.1563 8.65936C27.0954 8.47572 27.0737 8.28138 27.0925 8.08884V8.08884ZM29.2589 8.08884C29.2589 7.45439 28.9739 7.08337 28.4737 7.08337C27.9716 7.08337 27.6892 7.45439 27.6892 8.08884C27.6892 8.72837 27.9716 9.09653 28.4737 9.09653C28.9739 9.09653 29.2589 8.72582 29.2589 8.08884H29.2589Z"
        fill="white"
      />
      <path
        d="M33.5226 9.55355H32.9234L32.3185 7.39789H32.2728L31.6704 9.55355H31.0769L30.2701 6.62665H30.856L31.3803 8.86005H31.4235L32.0252 6.62665H32.5794L33.1811 8.86005H33.2268L33.7486 6.62665H34.3262L33.5226 9.55355Z"
        fill="white"
      />
      <path
        d="M35.0048 6.62664H35.5609V7.0916H35.604C35.6773 6.92461 35.8008 6.78461 35.9573 6.69114C36.1139 6.59767 36.2957 6.55537 36.4775 6.57014C36.6199 6.55943 36.7629 6.58091 36.8959 6.63297C37.0289 6.68503 37.1484 6.76635 37.2457 6.8709C37.343 6.97545 37.4155 7.10055 37.4579 7.23693C37.5003 7.37331 37.5115 7.51748 37.4905 7.65876V9.55351H36.9129V7.80382C36.9129 7.33346 36.7085 7.09954 36.2813 7.09954C36.1846 7.09504 36.0881 7.11149 35.9984 7.14778C35.9086 7.18407 35.8278 7.23934 35.7614 7.30979C35.695 7.38024 35.6447 7.46421 35.6138 7.55594C35.5829 7.64768 35.5722 7.74501 35.5824 7.84126V9.55354H35.0048L35.0048 6.62664Z"
        fill="white"
      />
      <path d="M38.411 5.48407H38.9886V9.55355H38.411V5.48407Z" fill="white" />
      <path
        d="M39.7916 8.08883C39.7732 7.89647 39.7952 7.70239 39.8562 7.51904C39.9172 7.33569 40.0159 7.16711 40.1459 7.02412C40.2758 6.88112 40.4343 6.76688 40.611 6.68869C40.7877 6.61051 40.9788 6.57013 41.172 6.57013C41.3653 6.57013 41.5564 6.61051 41.7331 6.68869C41.9098 6.76688 42.0682 6.88112 42.1982 7.02412C42.3282 7.16711 42.4268 7.33569 42.4878 7.51904C42.5489 7.70239 42.5709 7.89647 42.5525 8.08883C42.5712 8.28138 42.5495 8.47572 42.4886 8.65936C42.4277 8.843 42.3291 9.01188 42.1991 9.15515C42.0691 9.29841 41.9106 9.41289 41.7337 9.49124C41.5568 9.56958 41.3655 9.61005 41.172 9.61005C40.9786 9.61005 40.7873 9.56958 40.6104 9.49124C40.4335 9.41289 40.2749 9.29841 40.1449 9.15515C40.0149 9.01188 39.9163 8.843 39.8555 8.65936C39.7946 8.47572 39.7729 8.28138 39.7916 8.08883V8.08883ZM41.958 8.08883C41.958 7.45438 41.673 7.08336 41.1728 7.08336C40.6707 7.08336 40.3883 7.45438 40.3883 8.08884C40.3883 8.72837 40.6707 9.09653 41.1728 9.09653C41.673 9.09652 41.958 8.72582 41.958 8.08883H41.958Z"
        fill="white"
      />
      <path
        d="M43.1606 8.72579C43.1606 8.19893 43.5529 7.8952 44.2492 7.85203L45.042 7.80633V7.5537C45.042 7.24456 44.8376 7.07001 44.4428 7.07001C44.1204 7.07001 43.8969 7.18839 43.8328 7.39532H43.2736C43.3326 6.89259 43.8055 6.57013 44.4695 6.57013C45.2033 6.57013 45.6171 6.93543 45.6171 7.5537V9.55353H45.0611V9.1422H45.0154C44.9226 9.28974 44.7923 9.41003 44.6379 9.49076C44.4834 9.5715 44.3103 9.6098 44.1362 9.60175C44.0134 9.61453 43.8892 9.60142 43.7717 9.56327C43.6542 9.52511 43.546 9.46276 43.4541 9.38023C43.3621 9.2977 43.2885 9.19682 43.238 9.0841C43.1874 8.97138 43.1611 8.84932 43.1606 8.72579V8.72579ZM45.042 8.47569V8.23099L44.3273 8.27669C43.9242 8.30367 43.7414 8.44078 43.7414 8.69881C43.7414 8.96223 43.9699 9.11553 44.2841 9.11553C44.3762 9.12485 44.4692 9.11555 44.5576 9.08819C44.646 9.06084 44.728 9.01597 44.7987 8.95628C44.8694 8.89658 44.9274 8.82327 44.9692 8.74071C45.011 8.65814 45.0358 8.56802 45.042 8.47569V8.47569Z"
        fill="white"
      />
      <path
        d="M46.3763 8.08884C46.3763 7.16399 46.8518 6.5781 47.5913 6.5781C47.7742 6.56967 47.9557 6.61348 48.1147 6.70441C48.2736 6.79534 48.4034 6.92964 48.4888 7.0916H48.532V5.48407H49.1096V9.55355H48.5561V9.09112H48.5104C48.4184 9.25199 48.2841 9.38461 48.122 9.47463C47.96 9.56464 47.7765 9.60861 47.5913 9.60179C46.8467 9.60182 46.3763 9.01592 46.3763 8.08884ZM46.973 8.08884C46.973 8.70964 47.2656 9.0832 47.755 9.0832C48.2419 9.0832 48.5428 8.70425 48.5428 8.09138C48.5428 7.48137 48.2387 7.09702 47.755 7.09702C47.2688 7.09702 46.973 7.47312 46.973 8.08884H46.973Z"
        fill="white"
      />
      <path
        d="M51.4995 8.08884C51.4811 7.89649 51.5031 7.70242 51.5641 7.51908C51.6251 7.33573 51.7237 7.16716 51.8537 7.02417C51.9837 6.88118 52.1421 6.76693 52.3188 6.68875C52.4955 6.61057 52.6866 6.57019 52.8798 6.57019C53.073 6.57019 53.2641 6.61057 53.4408 6.68875C53.6175 6.76693 53.7759 6.88118 53.9059 7.02417C54.0359 7.16716 54.1345 7.33573 54.1955 7.51908C54.2565 7.70242 54.2785 7.89649 54.2601 8.08884C54.2789 8.28138 54.2571 8.47572 54.1963 8.65936C54.1354 8.843 54.0369 9.01188 53.9069 9.15515C53.7769 9.29842 53.6183 9.41291 53.4415 9.49126C53.2646 9.5696 53.0733 9.61008 52.8798 9.61008C52.6864 9.61008 52.495 9.5696 52.3182 9.49126C52.1413 9.41291 51.9827 9.29842 51.8527 9.15515C51.7228 9.01188 51.6242 8.843 51.5633 8.65936C51.5025 8.47572 51.4808 8.28138 51.4995 8.08884V8.08884ZM53.666 8.08884C53.666 7.45439 53.3809 7.08337 52.8808 7.08337C52.3787 7.08337 52.0962 7.45439 52.0962 8.08884C52.0962 8.72837 52.3787 9.09653 52.8808 9.09653C53.381 9.09653 53.666 8.72582 53.666 8.08884Z"
        fill="white"
      />
      <path
        d="M55.0351 6.62664H55.5912V7.0916H55.6344C55.7076 6.92461 55.8311 6.78461 55.9877 6.69114C56.1442 6.59767 56.3261 6.55537 56.5078 6.57014C56.6502 6.55943 56.7932 6.58091 56.9262 6.63297C57.0592 6.68503 57.1788 6.76635 57.2761 6.8709C57.3734 6.97545 57.4459 7.10055 57.4883 7.23693C57.5306 7.37331 57.5418 7.51748 57.5209 7.65876V9.55351H56.9433V7.80382C56.9433 7.33346 56.7389 7.09954 56.3117 7.09954C56.215 7.09504 56.1184 7.11149 56.0287 7.14778C55.939 7.18407 55.8581 7.23934 55.7918 7.30979C55.7254 7.38024 55.675 7.46421 55.6441 7.55594C55.6132 7.64768 55.6025 7.74501 55.6128 7.84126V9.55354H55.0351V6.62664Z"
        fill="white"
      />
      <path
        d="M60.7849 5.89795V6.63999H61.419V7.12654H60.7849V8.63157C60.7849 8.93816 60.9112 9.07241 61.1987 9.07241C61.2723 9.07218 61.3459 9.06773 61.419 9.05908V9.54023C61.3153 9.55879 61.2102 9.56866 61.1048 9.56975C60.4624 9.56975 60.2066 9.34377 60.2066 8.77946V7.12651H59.7419V6.63996H60.2066V5.89795H60.7849Z"
        fill="white"
      />
      <path
        d="M62.208 5.48407H62.7806V7.09701H62.8263C62.9031 6.92845 63.0299 6.78767 63.1896 6.69384C63.3494 6.60002 63.5341 6.55771 63.7187 6.5727C63.8604 6.56499 64.002 6.58864 64.1335 6.64197C64.265 6.69529 64.3831 6.77698 64.4794 6.8812C64.5756 6.98542 64.6477 7.1096 64.6905 7.24489C64.7332 7.38018 64.7456 7.52324 64.7267 7.66386V9.55356H64.1485V7.80636C64.1485 7.33885 63.9308 7.10208 63.5226 7.10208C63.4234 7.09394 63.3235 7.10759 63.23 7.14207C63.1366 7.17656 63.0518 7.23105 62.9816 7.30173C62.9114 7.3724 62.8575 7.45757 62.8237 7.55126C62.7898 7.64495 62.7768 7.7449 62.7857 7.84412V9.55354H62.208L62.208 5.48407Z"
        fill="white"
      />
      <path
        d="M68.0948 8.76329C68.0163 9.03079 67.8463 9.26218 67.6145 9.41698C67.3826 9.57178 67.1037 9.64012 66.8266 9.61007C66.6337 9.61516 66.4421 9.57822 66.265 9.50183C66.0878 9.42544 65.9295 9.31141 65.8008 9.16767C65.6722 9.02393 65.5764 8.85392 65.52 8.66944C65.4637 8.48495 65.4481 8.29042 65.4745 8.09933C65.4488 7.90765 65.4646 7.7127 65.5208 7.52766C65.5771 7.34262 65.6724 7.17182 65.8003 7.02683C65.9283 6.88183 66.0859 6.76602 66.2625 6.68723C66.4392 6.60845 66.6306 6.56853 66.824 6.57018C67.6384 6.57018 68.1297 7.12658 68.1297 8.04568V8.24724H66.0629V8.27961C66.0539 8.38702 66.0674 8.49513 66.1026 8.59702C66.1378 8.69891 66.1938 8.79232 66.2672 8.87126C66.3406 8.95021 66.4297 9.01295 66.5287 9.05546C66.6278 9.09796 66.7346 9.1193 66.8424 9.11811C66.9806 9.13469 67.1206 9.10981 67.2446 9.04663C67.3686 8.98345 67.471 8.88481 67.5388 8.76327L68.0948 8.76329ZM66.0629 7.82003H67.5413C67.5486 7.7218 67.5352 7.62313 67.5021 7.53036C67.469 7.43759 67.4168 7.35278 67.349 7.28138C67.2811 7.20997 67.1991 7.15354 67.1081 7.11573C67.0172 7.07792 66.9193 7.05955 66.8208 7.06181C66.7209 7.06055 66.6218 7.07931 66.5292 7.11697C66.4367 7.15464 66.3526 7.21044 66.282 7.28111C66.2114 7.35177 66.1556 7.43586 66.118 7.52842C66.0804 7.62098 66.0616 7.72013 66.0629 7.82003H66.0629Z"
        fill="white"
      />
    </svg>
  );
}
