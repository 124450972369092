import React, { Component } from 'react';
import Measure from 'react-measure';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { withAppContext } from 'contexts/AppContext';
import _debounce from 'lodash/debounce';
import _merge from 'lodash/merge';
import PropTypes from 'prop-types';
import Backdrop from 'ui/Backdrop/Backdrop';
import ClickOutsideContainer from 'ui/ClickOutsideContainer/ClickOutsideContainer';
import { v4 as uuidv4 } from 'uuid';

import {
  Click,
  ClickTracker,
  PAYLOAD,
  Search,
  SearchKeystroke,
  TRACK,
  withAnalyticsContext,
} from 'analytics';
import { withClickContext } from 'analytics/context/ClickContext';
import SearchResults from 'components/Search/SearchResults/SearchResults';
import RemoveLineIcon from 'icons/RemoveLineIcon';
import SearchIcon from 'icons/SearchIcon';
import { COLLECTION_VIEWS } from 'pages/Collection/constants';
import { fetchCollections } from 'store/modules/data/Collections/actions';
import {
  clearSearch,
  fetchNearbyEventResults,
  fetchViewedEvents,
  search,
} from 'store/modules/data/Search/actions';
import { selectSearchResults } from 'store/modules/data/Search/selectors';
import {
  selectClosestMetro,
  selectUserMetro,
} from 'store/modules/resources/resource.selectors';
import { isObjectEmpty } from 'utils/objects';

import { getSearchPath, SEARCH_PAGE_TYPES } from '../Search.constants';

import styles from './SearchBox.module.scss';

const SEARCH_BOX_LIMIT_EVENTS = 4;

const KEYS = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ENTER: 'Enter',
};

const propTypes = {
  router: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
  searchResults: PropTypes.shape({
    results: PropTypes.array.isRequired,
    flattenedResults: PropTypes.array.isRequired,
    hasMoreResults: PropTypes.bool.isRequired,
    recommended: PropTypes.object,
  }),
  search: PropTypes.func.isRequired,
  fetchNearbyEventResults: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  fetchViewedEvents: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  clickTracker: PropTypes.instanceOf(ClickTracker),
  onSearchTextChange: PropTypes.func,
  onSearchSessionIDChange: PropTypes.func,
  initialSearchSessionID: PropTypes.string,
  initialKeystrokeSequence: PropTypes.number,
  initialApiSequence: PropTypes.number,
  onResultClick: PropTypes.func,
  isHomepage: PropTypes.bool,
  isFocused: PropTypes.bool,
  metro: PropTypes.object,
  fetchCollections: PropTypes.func.isRequired,
  isSearchHero: PropTypes.bool,
  searchSessionID: PropTypes.string,
  appContext: PropTypes.shape({
    state: PropTypes.shape({
      isMobile: PropTypes.bool.isRequired,
      ipGeoLocation: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  analyticsContext: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }),
  clickContext: PropTypes.object,
};

const defaultProps = {
  initialKeystrokeSequence: 0,
  initialApiSequence: 0,
  onBlur: () => {},
  searchResults: {
    results: [],
    flattenedResults: [],
    hasMoreResults: false,
    recommended: {},
  },
};

@withAppContext
@withClickContext(() => ({
  [TRACK.INTERACTION]: Click.INTERACTIONS.SEARCH_BAR(),
}))
@connect(
  (state, props) => {
    return {
      metro:
        selectUserMetro(state) ||
        selectClosestMetro(state, props.appContext.state.ipGeoLocation),
      searchResults: selectSearchResults(
        state,
        props.searchSessionID,
        SEARCH_BOX_LIMIT_EVENTS
      ),
    };
  },
  {
    search,
    clearSearch,
    fetchNearbyEventResults,
    fetchViewedEvents,
    fetchCollections,
  }
)
@withAnalyticsContext
class SearchBox extends Component {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
      searchText: '',
      showResults: false,
      fetchingResults: false,
      dimensions: {
        bottom: 0,
      },
      searchSessionID: props.initialSearchSessionID,
      keystrokeSequence: props.initialKeystrokeSequence,
      apiSequence: props.initialApiSequence,
    };

    this.exitSearch = this.exitSearch.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.makeSearchCall = this.makeSearchCall.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.hideResults = this.hideResults.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearSearchAndFocus = this.clearSearchAndFocus.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleActiveIndexChange = this.handleActiveIndexChange.bind(this);
    this.delayedSearch = _debounce(this.makeSearchCall, 250);
  }

  shouldShowRecommendedResults() {
    const { metro, searchResults } = this.props;

    // Popular events won't change so no need to fetch from the API again
    if (searchResults.recommended?.popular !== undefined) {
      this.props.fetchViewedEvents();
      this.props.fetchNearbyEventResults(metro);
      this.setState({ showResults: true });
      return;
    }

    // Fetch viewed events, current collection for the metro, and then display results
    Promise.all([
      this.props.fetchViewedEvents(),
      this.props.fetchCollections({
        metro: metro.id,
        with_results: true,
        view: COLLECTION_VIEWS.WEB_DISCOVER,
      }),
    ]).then(() => {
      this.props.fetchNearbyEventResults(metro);
      this.setState({ showResults: true });
    });
  }

  componentDidMount() {
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    if (isMobile) {
      this.shouldShowRecommendedResults();
    } else {
      document.addEventListener('click', this.exitSearch, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.exitSearch, false);
  }

  handleSearchChange(event) {
    event.persist();
    const searchText = event.target.value;
    const newState = { fetchingResults: true };

    let { searchSessionID } = this.state;
    if (!searchSessionID) {
      searchSessionID = uuidv4();
      newState.searchSessionID = searchSessionID;

      if (this.props.onSearchSessionIDChange) {
        this.props.onSearchSessionIDChange(searchSessionID);
      }
    }

    if (searchText) {
      // Approximate tracking for a "new search".
      if (
        !this.state.searchText &&
        __CLIENT__ &&
        typeof window !== 'undefined' &&
        typeof window.fbq === 'function'
      ) {
        window.fbq('track', 'Search');
      }

      newState.searchText = searchText;
    } else {
      this.clearSearchAndFocus();
    }

    const { keystrokeSequence } = this.state;
    this.props.analyticsContext.track(
      new SearchKeystroke({
        searchTerm: searchText,
        searchSessionID,
        payload: { [PAYLOAD.SEQUENCE]: keystrokeSequence },
      })
    );
    newState.keystrokeSequence = keystrokeSequence + 1;

    this.setState(newState);

    if (this.props.onSearchTextChange) {
      this.props.onSearchTextChange(searchText);
    }

    this.delayedSearch(searchText);

    if (searchText.length === 0) {
      this.shouldShowRecommendedResults();
    }
  }

  suppressClick(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    return false;
  }

  handleInputFocus() {
    const {
      appContext: {
        state: { isMobile },
      },
      onFocus,
    } = this.props;

    if (onFocus) {
      onFocus();
    }

    if (isMobile && this.resultsRef) {
      this.resultsRef.addEventListener('scroll', this.handleScroll, false);
    }

    if (this.state.searchText.length === 0) {
      this.shouldShowRecommendedResults();
    }
  }

  handleInputBlur() {
    const {
      appContext: {
        state: { isMobile },
      },
      onBlur,
    } = this.props;

    if (onBlur) {
      onBlur(true);
    }

    if (isMobile && this.resultsRef) {
      this.resultsRef.removeEventListener('scroll', this.handleScroll, false);
    }
  }

  handleScroll() {
    this.searchInput.blur();
  }

  handleKeyPress(event) {
    const {
      activeIndex,
      searchText,
      searchSessionID,
      keystrokeSequence,
      apiSequence,
    } = this.state;
    const { searchResults, router } = this.props;
    const searchSessionData = {
      searchSessionID,
      keystrokeSequence,
      apiSequence,
    };

    const searchResultsLength = searchResults.flattenedResults.length;
    switch (event.key) {
      case KEYS.ARROW_DOWN:
        this.setState({
          activeIndex: Math.min(activeIndex + 1, searchResultsLength),
        });
        event.preventDefault();
        event.stopPropagation();
        break;
      case KEYS.ARROW_UP:
        this.setState({ activeIndex: Math.max(activeIndex - 1, 0) });
        event.preventDefault();
        event.stopPropagation();
        break;
      case KEYS.ENTER:
        let selectedPath;
        if (!searchResults.hasMoreResults) {
          break;
        }
        /* If show more results is shown and the activeIndex is the last one, then see more results link was selected */
        if (activeIndex === -1 || activeIndex === searchResultsLength) {
          selectedPath = getSearchPath(searchText, searchSessionData);
        } else {
          selectedPath = searchResults.flattenedResults[activeIndex].getPath();
        }
        this.hideResults();
        router.push(selectedPath);
        break;
      default:
    }
  }

  handleSearchClick() {
    const { searchText, showResults } = this.state;
    const { clickTracker, analyticsContext, clickContext } = this.props;

    if (analyticsContext) {
      const tracker = clickTracker || new ClickTracker();
      if (searchText) {
        tracker.payload({ [PAYLOAD.SEARCH_TERM]: searchText });
      }
      analyticsContext.track(
        new Click(_merge({}, clickContext, tracker.json()))
      );
    }

    if (searchText && !showResults) {
      this.setState({ showResults: true });
    }
  }

  makeSearchCall(searchText) {
    if (searchText) {
      const { apiSequence, searchSessionID } = this.state;
      this.props.analyticsContext.track(
        new Search({
          searchTerm: searchText,
          searchSessionID,
          payload: { [PAYLOAD.API_SEQUENCE]: apiSequence },
        })
      );
      this.setState({ apiSequence: apiSequence + 1 });

      this.props
        .search(searchText, this.props.metro)
        .then(() => {
          this.setState({
            activeIndex: -1,
          });
        })
        .finally(() => {
          this.setState({
            fetchingResults: false,
          });
        });
    }
  }

  handleActiveIndexChange(index) {
    this.setState({ activeIndex: index });
  }

  clearSearch() {
    this.props.clearSearch();
    this.setState({ searchText: '' });
    this.shouldShowRecommendedResults();
  }

  clearSearchAndFocus() {
    const { keystrokeSequence, searchSessionID } = this.state;
    this.props.analyticsContext.track(
      new SearchKeystroke({
        searchTerm: '',
        searchSessionID,
        payload: { [PAYLOAD.SEQUENCE]: keystrokeSequence },
      })
    );
    this.setState({ keystrokeSequence: keystrokeSequence + 1 });

    this.clearSearch();
    this.searchInput.focus();
  }

  exitSearch() {
    const { searchSessionID, searchText, showResults } = this.state;
    const {
      appContext: {
        state: { isMobile },
      },
      onBlur,
    } = this.props;

    if (!isMobile) {
      this.hideResults();
    }

    if (showResults) {
      const { clickContext } = this.props;

      const tracker = new ClickTracker().interaction(
        Click.INTERACTIONS.CANCEL()
      );
      tracker.payload({
        [PAYLOAD.UUID]: searchSessionID,
        [PAYLOAD.SEARCH_TERM]: searchText,
      });
      tracker.sourcePageType(Click.SOURCE_PAGE_TYPES.SEARCH());
      tracker.targetPageType({
        target_page_type: clickContext.sourcePageType
          ? clickContext.sourcePageType.source_page_type
          : Click.TARGET_PAGE_TYPES.UNKNOWN().target_page_type,
      });
      this.props.analyticsContext.track(new Click(tracker.json()));

      this.clearSearch();
      onBlur(false);
      this.setState({
        searchSessionID: '',
        keystrokeSequence: 0,
        apiSequence: 0,
      });
    }
  }

  hideResults() {
    const { onResultClick, onBlur } = this.props;
    this.setState({ showResults: false, searchText: '', activeIndex: -1 });
    onBlur(false);
    if (onResultClick) {
      onResultClick();
    }
  }

  hasResults() {
    const { fetchingResults, searchText, showResults } = this.state;
    const { searchResults } = this.props;
    if (!isObjectEmpty(searchResults.recommended) && showResults) {
      return true;
    }
    return (
      fetchingResults ||
      (searchText !== '' && searchResults.flattenedResults.length > 0)
    );
  }

  showMoreResults() {
    return this.hasResults() && this.props.searchResults.hasMoreResults;
  }

  handleClickOutside() {
    const {
      appContext: {
        state: { isMobile },
      },
    } = this.props;

    if (!isMobile && this.state.showResults) {
      this.hideResults();
    }
  }

  render() {
    const {
      showResults,
      searchSessionID,
      searchText,
      activeIndex,
      keystrokeSequence,
      apiSequence,
    } = this.state;
    const { bottom } = this.state.dimensions;
    const {
      autoFocus,
      appContext: {
        state: { isMobile },
      },
      isHomepage,
      isFocused,
      isSearchHero,
      searchResults,
    } = this.props;
    const noResults = !this.hasResults();
    const showMoreResults = this.showMoreResults();

    const searchResultsLength = searchResults.flattenedResults.length;
    const showWhiteSearchBar = isHomepage;
    const showDarkSearchBar = !isHomepage;
    const isSearchBarFocused = isFocused || isMobile;
    const searchIconProps = {
      fill: showWhiteSearchBar || isSearchBarFocused ? '#000' : '#fff',
    };

    const isSearchHeroDesktop = isSearchHero && !isMobile;

    const dynamicStyles = {
      maxHeight: isSearchHeroDesktop ? `424px` : `calc(100vh - ${bottom}px)`,
    };
    if (isMobile) {
      dynamicStyles.top = bottom;
    }

    return (
      <ClickOutsideContainer
        onClick={() => this.handleClickOutside()}
        className={styles['search-clickoutside-container']}
      >
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setState({ dimensions: contentRect.bounds });
          }}
        >
          {({ measureRef }) => (
            <div
              ref={measureRef}
              className={classNames(
                styles['search-box'],
                styles['search-rectangle'],
                {
                  [styles['search-results-active']]: showResults,
                  [styles['search-light']]: showWhiteSearchBar,
                  [styles['search-dark']]: showDarkSearchBar,
                  [styles['search-focused']]: isFocused,
                  [styles['search-hero']]: isSearchHero,
                }
              )}
              onClick={this.suppressClick}
              onKeyDown={this.suppressClick}
            >
              <div
                className={classNames(styles.search, {
                  [styles['search-desktop']]: !isMobile,
                })}
              >
                <i className={styles['search-icon']}>
                  <SearchIcon {...searchIconProps} />
                </i>
                <input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={autoFocus}
                  ref={(input) => {
                    this.searchInput = input;
                  }}
                  data-cy="search-input"
                  className={classNames(styles['search-input'])}
                  onClick={this.handleSearchClick}
                  onChange={this.handleSearchChange}
                  value={searchText}
                  placeholder="Search team, artist or venue"
                  onKeyDown={this.handleKeyPress}
                  onFocus={this.handleInputFocus}
                  onBlur={this.handleInputBlur}
                />
                <button
                  className={classNames([
                    styles['remove-icon'],
                    {
                      [styles.visible]: !!searchText,
                    },
                  ])}
                  onClick={this.clearSearchAndFocus}
                >
                  {searchText && (
                    <RemoveLineIcon
                      {...{
                        fill: '#000',
                      }}
                    />
                  )}
                </button>
              </div>

              {(!isSearchHero || isSearchHeroDesktop) && (
                <div
                  data-cy="results"
                  ref={(ref) => {
                    this.resultsRef = ref;
                  }}
                  className={classNames(styles.results, {
                    [styles['results-desktop']]: !isMobile,
                  })}
                  style={dynamicStyles}
                >
                  <SearchResults
                    activeIndex={activeIndex}
                    show={showResults}
                    onResultClick={this.hideResults}
                    searchText={searchText}
                    searchResults={searchResults.results}
                    recommended={searchResults.recommended}
                    searchResultsLength={searchResultsLength}
                    noResults={noResults}
                    showMoreResults={showMoreResults}
                    handleActiveIndexChange={this.handleActiveIndexChange}
                    searchSessionID={searchSessionID}
                    keystrokeSequence={keystrokeSequence}
                    apiSequence={apiSequence}
                    pageType={SEARCH_PAGE_TYPES.TYPEAHEAD}
                    isMobile={isMobile}
                    isSearchBar
                  />
                </div>
              )}

              {!isSearchHero && !isMobile && showResults && (
                <Backdrop
                  data-testid="outside-backdrop"
                  variant="header"
                  onClick={this.hideResults}
                  topPosition={bottom || 60}
                />
              )}
            </div>
          )}
        </Measure>
      </ClickOutsideContainer>
    );
  }
}

export default withRouter(SearchBox);
