import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner/Spinner';
import { showAppSpinnerSelector } from 'store/modules/app/app.selectors';

const ConnectedAppSpinner = ({ showAppSpinner }) => {
  if (!showAppSpinner) return null;
  return <Spinner isFullscreen />;
};

ConnectedAppSpinner.propTypes = {
  showAppSpinner: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    showAppSpinner: showAppSpinnerSelector(state),
  };
};

export default withRouter(connect(mapStateToProps)(ConnectedAppSpinner));
