import colors from 'styles/colors.constants';

type CoverageVariant = 'CONTROL' | 'FOR_EVERY_FAN' | 'VERIFIED_TICKETS';
export type Coverage = {
  title: string;
  message: string;
  shieldColor: string;
  cta?: string;
};

export const COVERAGE: Record<CoverageVariant, Coverage> = {
  CONTROL: {
    title: 'Gametime Best Price Guarantee',
    message: "Best price or we'll send you 110% of the difference.",
    shieldColor: colors.gray200,
  },
  FOR_EVERY_FAN: {
    title: 'Ticket Coverage for Every Fan',
    message: 'Verified Tickets · Best Prices · ',
    shieldColor: colors.gametimeGreen,
    cta: 'Learn More',
  },
  VERIFIED_TICKETS: {
    title: 'Verified Tickets · Best Prices',
    message: 'Ticket Coverage for Every Fan. ',
    shieldColor: colors.gametimeGreen,
    cta: 'Learn More',
  },
};

export const MODAL_DATA = [
  {
    id: 1,
    title: 'Event Cancellation Protection',
    content:
      "If your event cancels and doesn't reschedule, we'll provide a full refund to the original payment method.",
  },
  {
    id: 2,
    title: 'Lowest Price Guarantee',
    content:
      "If you find a lower price elsewhere we'll add 110% of the difference between the price you found and our price in Gametime credit.",
  },
  {
    id: 3,
    title: 'On-Time Ticket Delivery',
    content:
      "We guarantee you'll get your tickets in time for your event and they'll be valid for entry. If you have any issues, contact us and we'll make it right.",
  },
];
